import useSpecificSearchParam from "@utils/hooks/useSpecificSearchParams"
import {
  CourseInstanceTabIdentifier,
  defaultCourseTab,
} from "./courseInstanceSidebar"
import { useParams } from "react-router-dom"
import { Box } from "@mui/material"
import { CourseInstanceOverview } from "./tabs/courseInstanceOverview"
import { CourseInstanceCalendar } from "./tabs/courseInstanceCalendar"
import { CourseInstanceContent } from "./tabs/courseInstanceContent"
import { CourseInstanceStudents } from "./tabs/courseIntanceStudents"

export function CourseInstanceTabNavigator() {
  const { courseInstanceId } = useParams()
  const [tabId] = useSpecificSearchParam("tab")
  const selectedTabId = tabId ?? defaultCourseTab()

  const content = (() => {
    switch (selectedTabId) {
      case CourseInstanceTabIdentifier.Home:
        return <CourseInstanceOverview courseInstanceId={courseInstanceId} />
      case CourseInstanceTabIdentifier.Content:
        return <CourseInstanceContent courseInstanceId={courseInstanceId} />
      case CourseInstanceTabIdentifier.Students:
        return <CourseInstanceStudents courseInstanceId={courseInstanceId} />
      case CourseInstanceTabIdentifier.Classes:
        return <></>
      case CourseInstanceTabIdentifier.Calendar:
        return <CourseInstanceCalendar courseInstanceId={courseInstanceId} />
      default:
        return <CourseInstanceOverview courseInstanceId={courseInstanceId} />
    }
  })()

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        paddingTop: "35px",
        paddingX: "24px",
        gap: "24px",
        alignItems: "center",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          width: "100%",
          gap: "24px",
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            overflow: "hidden",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {content}
        </Box>
      </Box>
    </Box>
  )
}
