import { useAppDispatch } from "@app/hooks"
import { TrackClient } from "@clients/trackClient"
import { saveTrack } from "@features/tracks/trackSlice"
import { TrackDto } from "@masterschool/course-builder-api"
import { useState, useRef, useCallback, useEffect } from "react"

export const useAutoSaveTrack = (trackId: string | undefined) => {
  const [currentTrack, setCurrentTrack] = useState<TrackDto | undefined>(
    undefined,
  )
  const [hasNoChanges, setHasNoChanges] = useState(true)
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!trackId) {
      return
    }
    TrackClient.getTrack(trackId).then((trackResponse) => {
      setCurrentTrack(trackResponse)
    })
  }, [trackId])

  const onTrackChange = async (newTrack: TrackDto) => {
    setCurrentTrack(newTrack)
    if (hasNoChanges) {
      await onSaveTrack(newTrack)
      setHasNoChanges(false)
    }
  }

  const onSaveTrack = useCallback(
    async (updatedTrack: TrackDto) => {
      const updatedDraftResponse = await dispatch(
        saveTrack({
          trackId: updatedTrack.id,
          track: updatedTrack,
        }),
      )
        .unwrap()
        .then((response) => response)
      setCurrentTrack(updatedDraftResponse)
    },
    [dispatch],
  )

  const scheduleNextUpdate = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    timeoutRef.current = setTimeout(async () => {
      try {
        if (currentTrack) {
          await onSaveTrack(currentTrack)
        }
      } finally {
        scheduleNextUpdate()
      }
    }, 5 * 1000)
  }, [currentTrack, onSaveTrack])

  useEffect(() => {
    if (hasNoChanges) {
      return
    }
    scheduleNextUpdate()
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [scheduleNextUpdate, hasNoChanges])

  return { currentTrack, onTrackChange, onSaveTrack }
}
