import { Box, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material"
import { useEffect, useState } from "react"

import { useAppDispatch, useAppSelector } from "@app/hooks"
import { LoadingComponent } from "@cmp/loaders/loadingComponent"
import { StudentProfilePanel } from "./studentProfilePanel"
import { StudentsTableGrid } from "./students-grid/studentsTable.grid"
import { selectStudentsTableData } from "@features/students-tab/studentsTab.selectors"
import {
  fetchStudentsData,
  ProgramStatus,
} from "@features/students-tab/studentsTab.slice"
import appTheme from "../../theme/appTheme"
import usePrevious from "@utils/hooks/usePrevious"

export function StudentsTabView() {
  const dispatch = useAppDispatch()
  const data = useAppSelector(selectStudentsTableData)

  const [studentProfilePanelOpen, setStudentProfilePanelOpen] = useState(false)
  const [selectedStudentId, setSelectedStudentId] = useState<string | null>(
    null,
  )
  const [selectedProgramStatus, setSelectedProgramStatus] =
    useState<ProgramStatus>("active")
  const prevSelectedProgramStatus = usePrevious(selectedProgramStatus)
  useEffect(() => {
    if (data === "idle") {
      dispatch(fetchStudentsData(selectedProgramStatus))
    } else if (selectedProgramStatus !== prevSelectedProgramStatus) {
      dispatch(fetchStudentsData(selectedProgramStatus))
    }
  }, [data, selectedProgramStatus, prevSelectedProgramStatus, dispatch])

  const isLoading = data === "pending"
  const isDataReady = typeof data !== "string"

  const onOpenProfileClicked = (studentId: string) => {
    setSelectedStudentId(studentId)
    setStudentProfilePanelOpen(true)
  }

  return (
    <Box
      sx={{
        maxWidth: "100%",
        overflow: "hidden",
        paddingX: 3,
        paddingTop: "80px",
      }}
    >
      <StudentProfilePanel
        studentId={selectedStudentId}
        open={studentProfilePanelOpen}
        onClose={() => setStudentProfilePanelOpen(false)}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          maxWidth: "100%",
          height: "100%",
          gap: "16px",
          overflowX: "hidden",
        }}
      >
        <TitleSection
          isLoading={isLoading}
          programStatus={selectedProgramStatus}
          onProgramStatusChanged={setSelectedProgramStatus}
        />
        <StudentsTableGrid
          data={isDataReady ? data : []}
          onOpenProfileClicked={onOpenProfileClicked}
        />
      </Box>
    </Box>
  )
}

const TitleSection = (props: {
  isLoading: boolean
  programStatus: ProgramStatus
  onProgramStatusChanged: (newSelection: ProgramStatus) => void
}) => {
  const { isLoading, programStatus, onProgramStatusChanged } = props
  return (
    <Box
      display="flex"
      sx={{
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <Typography variant="h4">Students</Typography>
      {isLoading && <LoadingComponent loadedComponents="data" />}
      <ToggleButtonGroup
        value={programStatus}
        exclusive
        onChange={(e, value) => {
          if (value) onProgramStatusChanged(value)
        }}
        sx={{
          height: "32px",
        }}
      >
        <StudentsTypeToggleButton
          value={"upcoming"}
          isSelected={programStatus === "upcoming"}
          label="Upcoming"
        />
        <StudentsTypeToggleButton
          value={"active"}
          isSelected={programStatus === "active"}
          label="Active"
        />
        <StudentsTypeToggleButton
          value={"ended"}
          label="Completed"
          isSelected={programStatus === "ended"}
        />
      </ToggleButtonGroup>
    </Box>
  )
}

export const StudentsTypeToggleButton = (props: {
  value: ProgramStatus
  isSelected: boolean
  label: string
}) => {
  return (
    <ToggleButton
      value={props.value}
      sx={{
        width: "128px",
        borderColor: appTheme.palette.other.outlineBorder,
        borderRadius: "2px",
        "&.Mui-selected": {
          backgroundColor: appTheme.palette.eTypes.sand50,
        },
      }}
    >
      <Typography
        variant={props.isSelected ? "body2_sb" : "body2"}
        color="text.primary"
      >
        {props.label}
      </Typography>
    </ToggleButton>
  )
}
