import { useAppDispatch, useAppSelector } from "@app/hooks"
import { CourseClassRegistrationClient } from "@clients/courseClassRegistrationClient"
import GenericDialog from "@cmp/genericDialog"
import { selectLoggedInUser } from "@features/login/loginSelectors"
import { registerStudentToCourseUpdated } from "@features/program/programSlice"
import { selectLatestPublishedCoursesByIds } from "@features/coursesMenu/coursesSelectors"
import { FormControl, InputLabel, MenuItem, Select, Stack } from "@mui/material"
import { formatDateAsYearAndLongMonth, subMonths } from "@utils/date"
import { useState } from "react"
import { selectTermsByFilter } from "@features/term/termSelector"
import {
  selectCourseInstanceByIdentifiers,
  selectCourseIdsWithInstances,
  selectCourseInstanceClasses,
} from "@features/courseInstance/courseInstanceSelectors"

function RegisterStudentToCourseClassPopup() {
  const model = useAppSelector(
    (state) => state.program.studentsPage.registerStudentToCourseClassPopup,
  )
  const dispatch = useAppDispatch()
  const [selectedCourseId, setSelectedCourseId] = useState<string | undefined>()
  const [selectedTermId, setSelectedTermId] = useState<string | undefined>()
  const [selectedClassId, setSelectedClassId] = useState<string | undefined>()
  const [isLoading, setIsLoading] = useState(false)

  const user = useAppSelector(selectLoggedInUser)
  const prevMonth = subMonths(new Date(), 1)
  const terms = useAppSelector((state) =>
    selectTermsByFilter(state, prevMonth.toISOString(), 10),
  )
  const courseIds = useAppSelector(selectCourseIdsWithInstances)
  const courses = useAppSelector((state) =>
    selectLatestPublishedCoursesByIds(state, courseIds),
  )
  const courseInstance = useAppSelector((state) =>
    selectCourseInstanceByIdentifiers(
      state,
      selectedCourseId || "",
      selectedTermId || "",
      undefined,
    ),
  )
  const classes = useAppSelector((state) =>
    selectCourseInstanceClasses(state, courseInstance?.id),
  )

  const onClose = () => dispatch(registerStudentToCourseUpdated(undefined))

  const onRegister = () => {
    if (!user || !model || !selectedClassId) return
    setIsLoading(true)

    CourseClassRegistrationClient.registerStudentToClassManually(
      selectedClassId,
      model.studentId,
      new Date(),
      user.clientId,
    )
      .then(() => {
        dispatch(registerStudentToCourseUpdated(undefined))
        setSelectedClassId(undefined)
        setSelectedCourseId(undefined)
        setSelectedTermId(undefined)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const selectedCourse = courses.find((c) => c.id === selectedCourseId)
  const selectedTerm = terms?.find((t) => t.id === selectedTermId)
  const selectedClass = classes.find((c) => c.id === selectedClassId)

  const fullName = model?.firstName + " " + model?.lastName

  if (!model) return null

  return (
    <GenericDialog
      open
      size="xs"
      onClose={onClose}
      title={`Register ${fullName} to a course`}
      content={
        <Stack direction="column" sx={{ pt: "16px", gap: "24px" }}>
          <FormControl fullWidth>
            <InputLabel
              sx={{
                fontSize: "17px",
              }}
            >
              Course
            </InputLabel>
            <Select
              label="Course"
              value={selectedCourse?.id || ""}
              onChange={(e) => {
                const value = e.target.value
                setSelectedCourseId(value)
              }}
            >
              {courses.map((course) => (
                <MenuItem value={course.id} key={course.id}>
                  {course.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel
              sx={{
                fontSize: "17px",
              }}
            >
              Term
            </InputLabel>
            <Select
              label="Term"
              value={selectedTerm?.id || ""}
              onChange={(e) => {
                const value = e.target.value
                setSelectedTermId(value)
              }}
              disabled={!selectedCourseId}
            >
              {terms.map((term) => (
                <MenuItem value={term.id} key={term.id}>
                  {formatDateAsYearAndLongMonth(term.startDate)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel
              sx={{
                fontSize: "17px",
              }}
            >
              Class
            </InputLabel>
            <Select
              label="Class"
              value={selectedClass?.id || ""}
              onChange={(e) => {
                const value = e.target.value
                setSelectedClassId(value)
              }}
              disabled={!selectedTermId || !selectedCourseId}
            >
              {classes.map((courseClass) => (
                <MenuItem value={courseClass.id} key={courseClass.id}>
                  {courseClass.language}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      }
      buttons={[
        {
          type: "primary",
          text: "Register",
          disabled: !user || !model || !selectedClassId,
          loading: isLoading,
          onClick: onRegister,
        },
      ]}
    />
  )
}

export default RegisterStudentToCourseClassPopup
