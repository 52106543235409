import OptionsButton from "@cmp/buttons/optionsButton"
import { TrackDto, TrackDtoStatusEnum } from "@masterschool/course-builder-api"
import appIcons from "@utils/appIcons"
import appTheme from "../../theme/appTheme"
import { TrackVersionHistoryPopup } from "./trackVersionHistoryPopup"
import { useState } from "react"

import CampusPreviewPopup from "../../popups/popups/campusCoursePreviewPopup"

export const TrackOptionsMenu = (props: {
  track: TrackDto
  isEditMode: boolean
  onClickEdit?: () => void
}) => {
  const { track, isEditMode, onClickEdit } = props
  const [showVersionHistory, setShowVersionHistory] = useState(false)
  const [showPreviewOnCampus, setShowPreviewOnCampus] = useState(false)

  const onPreviewInCampusClicked = () => {
    if (!track) return
    setShowPreviewOnCampus(true)
  }

  const notImplemented = () => {}

  const items = [
    {
      text: "Edit",
      onSelect: onClickEdit || notImplemented,
      hidden: isEditMode && !!onClickEdit,
    },
    {
      text: "Preview on campus",
      onSelect: onPreviewInCampusClicked,
      disabled: false,
    },
    {
      text: "Version History",
      onSelect: () => setShowVersionHistory(true),
    },
    {
      text: "Archive",
      onSelect: notImplemented,
      disabled: true,
      hidden: track?.status !== TrackDtoStatusEnum.Archived,
    },
  ]

  return (
    <>
      <OptionsButton
        button={{
          variant: "contained",
          sx: {
            padding: "3px",
            border: `1px solid ${appTheme.palette.other.outlineBorder}`,
          },
          leftIcon: appIcons.dots,
        }}
        items={items}
      />
      {showVersionHistory && (
        <TrackVersionHistoryPopup
          trackId={track.id}
          onClose={() => {
            setShowVersionHistory(false)
          }}
        />
      )}
      {showPreviewOnCampus && (
        <CampusPreviewPopup
          name={track.internalName ?? ""}
          id={track.id ?? ""}
          idType="syllabus"
          version={track.version}
          programId={undefined}
          onClose={() => {
            setShowPreviewOnCampus(false)
          }}
        />
      )}
    </>
  )
}
