import { useAppDispatch } from "@app/hooks"
import { validateCourseForPreview } from "@features/courseEditor/courseEditorSlice"
import { discardDraft } from "@features/coursesMenu/coursesMenuSlice"
import { PopupState, PopupType, popupItemClicked } from "@features/ui/uiSlice"
import {
  CourseDescriptorDto,
  CourseStatus,
} from "@masterschool/course-builder-api"
import { useNavigate } from "react-router-dom"
import { useEditCourseClicked } from "../../../editor/custom-hooks/useEditCourseClicked"
import { parseStringVersion } from "@utils/versionUtils"

function useCourseMajorsMenuActions(course: CourseDescriptorDto) {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const { id: courseId, status, version } = course
  const { major: majorVersion } = parseStringVersion(version)

  const onOpenClicked = () => {
    if (course.status === CourseStatus.Draft) {
      navigate(`/course-edit/${course.id}`)
    } else {
      navigate(`/course/${course.id}?version=${course.version}`)
    }
  }

  const onPreviewInCampusClicked = () => {
    const popupModel: PopupState = {
      course,
      type: PopupType.CampusCoursePreview,
    }
    switch (status) {
      case CourseStatus.Published:
        dispatch(popupItemClicked(popupModel))
        break
      case CourseStatus.Draft:
        dispatch(validateCourseForPreview({ courseId, majorVersion })).then(
          (flattenedValidations) => {
            const isCourseValid =
              Object.keys(flattenedValidations.payload ?? {}).length === 0
            if (isCourseValid) {
              dispatch(popupItemClicked(popupModel))
            }
          },
        )
        break
      case CourseStatus.Archived:
        throw new Error("Course is archived, cannot preview")
    }
  }

  const onEditClicked = useEditCourseClicked()

  const onVersionHistoryClicked = () => {
    dispatch(
      popupItemClicked({
        courseId,
        majorVersion,
        type: PopupType.CourseVersionsHistory,
        context: "courseEditor",
      }),
    )
  }

  const onDiscardDraftVersionClicked = () => {
    dispatch(discardDraft({ courseId, version }))
  }

  return {
    onOpenClicked,
    onPreviewInCampusClicked,
    onEditClicked,
    onDiscardDraftVersionClicked,
    onVersionHistoryClicked,
  }
}

export default useCourseMajorsMenuActions
