import { useAppSelector } from "@app/hooks"
import OptionsButton, { OptionsButtonProps } from "@cmp/buttons/optionsButton"
import {
  CourseDescriptorDto,
  CourseStatus,
  CourseType,
} from "@masterschool/course-builder-api"
import { SxProps, Theme } from "@mui/material"
import appIcons from "@utils/appIcons"
import useCourseMenuActions from "./useCourseMenuActions"
import { selectDependentSyllabuses } from "@features/coursesMenu/coursesSelectors"
import { parseStringVersion } from "@utils/versionUtils"

function CourseDescriptorMenu(props: {
  course: CourseDescriptorDto
  sx?: SxProps<Theme>
  iconSx?: SxProps<Theme>
}) {
  const {
    onOpenClicked,
    onPreviewInCampusClicked,
    onEditClicked,
    onDuplicateClicked,
    onEditCourseDetailsClicked,
    onArchiveClicked,
    onVersionHistoryClicked,
    onDependentSyllabusesClicked,
    onDeleteClicked,
    onDuplicateLegacyToNewClicked,
  } = useCourseMenuActions(props.course)

  const { major } = parseStringVersion(props.course.version)

  const syllabusDependencies = useAppSelector(
    selectDependentSyllabuses(props.course.id),
  )
  const disableArchive = syllabusDependencies.length > 0

  let publishedItems = [
    {
      text: "Open",
      onSelect: onOpenClicked,
    },
    {
      text: "Preview in campus",
      onSelect: onPreviewInCampusClicked,
    },
    {
      text: "Edit",
      onSelect: () =>
        onEditClicked(props.course.id, major, props.course.status),
    },
    {
      text: "Edit course details",
      onSelect: onEditCourseDetailsClicked,
    },
    {
      text: "Duplicate",
      onSelect: onDuplicateClicked,
    },
    {
      text: "Duplicate legacy to new course",
      onSelect: onDuplicateLegacyToNewClicked,
    },
    {
      text: "Archive",
      onSelect: onArchiveClicked,
      disabled: disableArchive,
    },
    {
      text: "View version history",
      onSelect: onVersionHistoryClicked,
    },
    {
      text: "Show dependent syllabuses",
      onSelect: onDependentSyllabusesClicked,
    },
  ]

  let draftItems = [
    {
      text: "Open",
      onSelect: onOpenClicked,
    },
    {
      text: "Preview in campus",
      onSelect: onPreviewInCampusClicked,
    },
    {
      text: "Edit course details",
      onSelect: onEditCourseDetailsClicked,
    },
    {
      text: "Duplicate",
      onSelect: onDuplicateClicked,
    },
    {
      text: "Archive",
      onSelect: onArchiveClicked,
    },
  ]

  let archivedItems = [
    {
      text: "Preview",
      onSelect: onPreviewInCampusClicked,
    },
    {
      text: "Edit course details",
      onSelect: onEditCourseDetailsClicked,
    },
    {
      text: "Delete",
      onSelect: onDeleteClicked,
    },
  ]

  if (props.course.type === CourseType.Legacy) {
    publishedItems = removeEditCourseDetailsOption(publishedItems)
    draftItems = removeEditCourseDetailsOption(draftItems)
    archivedItems = removeEditCourseDetailsOption(archivedItems)
  }

  const items = {
    [CourseStatus.Published]: publishedItems,
    [CourseStatus.Draft]: draftItems,
    [CourseStatus.Archived]: archivedItems,
  }[props.course.status]

  return (
    <OptionsButton
      button={{
        sx: { padding: "2px" },
        leftIcon: appIcons.dots,
        iconSx: props.iconSx,
      }}
      items={items}
      sx={props.sx}
    />
  )
}

function removeEditCourseDetailsOption(items: OptionsButtonProps["items"]) {
  return items.filter((item) => item.text !== "Edit course details")
}

export default CourseDescriptorMenu
