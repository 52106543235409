import { PaletteOptions, alpha } from "@mui/material/styles"

export const outlinedHoverBackground = "#00B45D14"

declare module "@mui/material/styles" {
  interface SimplePaletteColorOptions {
    contrast?: string
    containedHoverBackground?: string
    outlinedHoverBackground?: string
    outlinedRestingBackground?: string
    alert?: {
      content: string
      background: string
    }
    background?: string
    outlinedRestingBorder?: string
  }

  interface PaletteOptions {
    other: TypeOther
    brightGreen: PaletteOptions["primary"]
    sand50: PaletteOptions["primary"]
    lilac25: PaletteOptions["primary"]
    brightBlue: PaletteOptions["primary"]
    eTypes: TypeETypes
    icon: { default: string; disabled: string; black: string }
  }

  interface Palette {
    eTypes: TypeETypes
    brightGreen: Palette["primary"]
    brightBlue: Palette["primary"]
    sand50: Palette["primary"]
    lilac25: Palette["primary"]
    other: TypeOther
    icon: { default: string; disabled: string; black: string }
  }

  interface PaletteColor {
    background: string
    contrast?: string
    containedHoverBackground?: string
    outlinedHoverBackground?: string
    outlinedRestingBackground?: string
    alert?: {
      content: string
      background: string
    }
    outlinedRestingBorder?: string
  }
}

interface TypeOther {
  divider: string
  outlineBorder: string
  standardInputLine: string
  backdropOverlay: string
  ratingActive: string
  snackbarBackground: string
  gray: string
  topbar: string
  celestial: string
  brightYellow: string
  turquoise: string
  failedGrade: string
  tangerine: string
}

interface TypeETypes {
  white: string
  sand15: string
  sand25: string
  sand35: string
  sand50: string
  sand100: string
  lightGrey: string
  grey: string
  black: string
  pink: string
  pink50: string
  lilac: string
  lilac25: string
  burgundy: string
  lightGreen: string
  brightGreen: string
  grassGreen: string
  grassGreen15: string
  evergreen: string
  brightBlue: string
}

const eTypes: TypeETypes = {
  white: "#FFFFFF",
  sand15: "#F9F9F6",
  sand25: "#F5F5F0",
  sand35: "#F2F2EC",
  sand50: "#EAEAE2",
  sand100: "#D4D4C5",
  lightGrey: "#94A5A4",
  grey: "#5F7977",
  black: "#0A0A0A",
  pink: "#FFC8F0",
  pink50: "#FFE3F7",
  lilac: "#C0AFEB",
  lilac25: "#E4DCF7",
  burgundy: "#4E2D41",
  lightGreen: "#D8FFD0",
  brightGreen: "#9EFF8A",
  grassGreen: "#009B50",
  grassGreen15: "#009B5026",
  evergreen: "#193325",
  brightBlue: "#2196F3",
}

export const paletteOptions: PaletteOptions = {
  primary: {
    main: "#222222",
    light: "#4E4E4E",
    dark: "#222222",
    contrast: "#FFFFFF",
    containedHoverBackground:
      "linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), #00B45D",
    outlinedHoverBackground: "rgba(34, 34, 34, 0.08)",
    outlinedRestingBackground: "rgba(77, 117, 101, 0.5)",
    outlinedRestingBorder: "rgba(0, 180, 93, 0.5)",
  },
  secondary: {
    main: "#00B45D",
    dark: "#091B3D",
    light: "#D8FFD0",
    contrast: "#FFFFFF",
    outlinedRestingBorder: "rgba(71, 64, 110, 0.5)",
    outlinedHoverBackground: "rgba(71, 64, 110, 0.08)",
    containedHoverBackground:
      "linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), #47406E",
  },
  brightGreen: {
    main: eTypes.brightGreen,
    light: "#B1FFA1",
    dark: "#6EB260",
    contrast: "#222222",
  },
  sand50: {
    main: eTypes.sand50,
    light: alpha(eTypes.sand50, 0.5),
    dark: alpha(eTypes.sand50, 0.5),
    contrast: "#222222",
  },
  lilac25: {
    main: eTypes.lilac25,
    light: alpha(eTypes.lilac25, 0.5),
    dark: alpha(eTypes.lilac25, 0.5),
    contrast: "#222222",
    contrastText: "#222222",
  },
  brightBlue: {
    main: eTypes.brightBlue,
    light: alpha(eTypes.brightBlue, 0.5),
    dark: alpha(eTypes.lilac25, 0.5),
    contrast: "#222222",
    contrastText: "#222222",
  },
  green: {
    main: eTypes.brightGreen,
    light: eTypes.lightGreen,
  },
  sand: {
    main: eTypes.sand50,
    containedHoverBackground: "#E1E1D9",
  },
  text: {
    primary: "#222222",
    secondary: "#717171",
    disabled: "rgba(0, 0, 0, 0.38)",
  },
  action: {
    active: "rgba(0, 0, 0, 0.54)",
    hover: "rgba(0, 0, 0, 0.04)",
    selected: "rgba(0, 0, 0, 0.08)",
    disabled: "rgba(0, 0, 0, 0.26)",
    disabledBackground: "rgba(0, 0, 0, 0.12)",
    focus: "rgba(0, 0, 0, 0.12)",
  },
  warning: {
    main: "#ED6C02",
    dark: "#C77700",
    light: "#FFB547",
    outlinedRestingBorder: "rgba(237, 108, 2, 0.5)",
    alert: {
      content: "#621B16",
      background: "#FDF0E6",
    },
  },
  info: {
    main: "rgba(69, 135, 187, 1)",
    dark: "#0B79D0",
    light: "#64B6F7",
    outlinedHoverBackground: "#2196F314",
    alert: {
      content: "#0E3C61",
      background: "#E9F4FE",
    },
  },
  error: {
    main: "#F44336",
    dark: "#E31B0C",
    light: "#F88078",
    contrast: "#FFFFFF",
    outlinedRestingBorder: "rgba(244, 67, 54, 0.5)",
    outlinedHoverBackground: "background: rgba(244, 67, 54, 0.08)",
    containedHoverBackground:
      "linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), #F44336",
    alert: {
      content: "#621B16",
      background: "#FEECEB",
    },
  },
  success: {
    main: "#00B45D",
    dark: "#3B873E",
    light: "#7BC67E",
    background: "rgba(0, 180, 93, 0.03)",
    outlinedRestingBackground: "rgba(76, 175, 80, 0.5)",
    outlinedRestingBorder: "rgba(76, 175, 80, 0.5)",
    alert: {
      content: "#1E4620",
      background: "#EDF7ED",
    },
  },
  background: {
    default: "#FFFFFF",
    paper: "#FFFFFF",
  },
  other: {
    divider: "#EEEEEE",
    outlineBorder: "#DDDDDD",
    standardInputLine: "#FFFFFF",
    backdropOverlay: "rgba(0, 0, 0, 0.5)",
    ratingActive: "#FFB400",
    snackbarBackground: "#323232",
    gray: "#757575",
    topbar: "#212121",
    celestial: "#C0EAF2",
    brightYellow: "#EDEDA5",
    turquoise: "#B9ECD0",
    failedGrade: "#FCD0CD",
    tangerine: "#F8E6CB",
  },
  eTypes,
  icon: {
    default: "#717171",
    disabled: "rgba(0, 0, 0, 0.26)",
    black: "#222222",
  },
  grey: {
    "50": "#FAFAFA",
    "100": "#F5F5F5",
    "200": "#EEEEEE",
    "300": "#E0E0E0",
    "400": "#BDBDBD",
    "500": "#9E9E9E",
    "600": "#757575",
    "700": "#616161",
    "800": "#424242",
    "900": "#212121",
    A100: "#D5D5D5",
    A200: "#AAAAAA",
    A400: "#616161",
    A700: "#303030",
  },
}
