import { createSlice } from "@reduxjs/toolkit"
import {
  fetchUserForAccessToken,
  getAccessTokenFromLocalStorage,
} from "./accessTokenObserver"
import PendingDataState from "@utils/pendingDataState"
import { FeatureFlag, FeatureFlagClient } from "@clients/featureFlagClient"
import { createAppAsyncThunk } from "@app/createAppAsyncThunk"
import { selectLoggedInUser } from "./loginSelectors"

export type User = {
  email: string
  firstName: string
  lastName: string
  photoURL: string | undefined
  roles: Role[]
  clientId: string
}

export enum Role {
  ProgramManager = "program_manager",
  MasterschoolEmployee = "ms_employee",
  Instructor = "instructor",
  AccountManager = "account_manager",
  LmsAdmin = "lms_admin",
  SchoolMasterAdmin = "school_master_admin",
  CareerSuccessAdvisor = "career_success_advisor",
  Mentor = "mentor",
  ProfessionalMentor = "professional_mentor",
  SalesTeamLead = "sales_team_lead",
  SalesAdmin = "sales_admin",
  AcademicSupportAdvisor = "academic_support_advisor",
  Tutor = "tutor",
  CourseManager = "course_manager",
  CareerConsultant = "career_consultant",
}
export const ALL_ROLES = Object.values(Role)

export type LoginState =
  | {
      accessToken: string
      user: "not-loaded" | "loading" | "error" | User
      featureFlags: PendingDataState<Omit<FeatureFlag, "source">[]>
    }
  | {
      accessToken: null
      user: "not-logged-in"
      featureFlags: { status: "idle" }
    }

export const getInitialState: () => LoginState = () => {
  const accessToken = getAccessTokenFromLocalStorage()
  if (accessToken) {
    return {
      user: "not-loaded",
      accessToken: accessToken,
      featureFlags: { status: "idle" },
    }
  } else {
    return {
      user: "not-logged-in",
      accessToken: null,
      featureFlags: { status: "idle" },
    }
  }
}

const loginSlice = createSlice({
  name: "login",
  initialState: getInitialState,
  reducers: {
    appLoaded: (state) => {
      return state
    },
    loadedUser: (state, action) => {
      return action.payload
    },
    fetchedAccessToken: (state, action) => {
      state.accessToken = action.payload
      state.user = "not-loaded"
    },
    logoutUser: (state) => {
      return {
        accessToken: null,
        user: "not-logged-in",
        featureFlags: { status: "idle" },
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserForAccessToken.pending, (state) => {
        state.user = "loading"
      })
      .addCase(fetchUserForAccessToken.rejected, (state) => {
        state.user = "error"
      })
      .addCase(fetchUserForAccessToken.fulfilled, (state, action) => {
        state.user = action.payload
      })
      .addCase(fetchLoggedInUserFeatureFlags.pending, (state, action) => {
        if (state.featureFlags.status !== "success") {
          state.featureFlags = { status: "pending" }
        }
      })
      .addCase(fetchLoggedInUserFeatureFlags.fulfilled, (state, action) => {
        state.featureFlags = {
          status: "success",
          data: action.payload || [],
        }
      })
      .addCase(fetchLoggedInUserFeatureFlags.rejected, (state, action) => {
        state.featureFlags = {
          status: "error",
          error: action.error.message,
        }
      })
  },
})

export const fetchLoggedInUserFeatureFlags = createAppAsyncThunk(
  "accountManagement/fetchLoggedInUserFeatureFlags",
  async (payload, thunkAPI) => {
    const state = thunkAPI.getState()
    const user = selectLoggedInUser(state)
    if (!user) {
      return
    }
    return FeatureFlagClient.getLoggedInUserFlags()
  },
)

export const { appLoaded, fetchedAccessToken, logoutUser } = loginSlice.actions
export default loginSlice.reducer
