import { TrackClient } from "@clients/trackClient"
import { VersionItem, VersionsMenu } from "@cmp/version-management/versionsMenu"
import { TrackDto, TrackDtoStatusEnum } from "@masterschool/course-builder-api"
import { CircularProgress, Divider, Stack } from "@mui/material"
import { useEffect, useMemo, useState } from "react"
import { TrackContentView } from "./TrackContentView"
import { RevertButton } from "@cmp/version-management/revertButton"
import { GenericDialog } from "@masterschool/ui"
import appIcons from "@utils/appIcons"

export const TrackVersionHistoryPopup = (props: {
  trackId: string
  onClose: () => void
}) => {
  const { trackId, onClose } = props
  const [versions, setVersions] = useState<TrackDto[]>([])
  const [selectedVersionId, setSelectedVersionId] = useState<
    number | undefined
  >(undefined)

  useEffect(() => {
    TrackClient.listVersions(trackId).then((versions) => {
      setVersions(versions)
      setSelectedVersionId(versions[0].version)
    })
  }, [trackId])

  const lastPublishedVersion = useMemo(
    () => findLastPublishedVersion(versions),
    [versions],
  )

  const selectedVersionDto = versions.find(
    (version) => version.version === selectedVersionId,
  )
  const lastVersionDto = useMemo(
    () => findMostRecentVersion(versions),
    [versions],
  )

  const resetSelectedVersion = () => setSelectedVersionId(versions[0].version)

  return (
    <GenericDialog
      open
      onClose={onClose}
      closeIcon={appIcons.xClose}
      title="Version history"
      size="lg"
      fullHeight
      disableContentPadding
      content={
        <Stack overflow="auto" height={1}>
          <Divider />
          <Stack direction="row" flex={1} overflow="auto">
            <VersionsMenu
              versionsHistory={versions.map((syllabus) =>
                toVersionItem(syllabus),
              )}
              onVersionSelected={(versionNumber) =>
                setSelectedVersionId(versionNumber)
              }
              selectedVersion={selectedVersionId}
              lastPublishedVersion={lastPublishedVersion?.version}
            />
            {selectedVersionDto ? (
              <SelectedVersionPanel
                track={selectedVersionDto}
                isLastVersion={
                  selectedVersionDto?.version === lastVersionDto?.version
                }
                resetSelection={resetSelectedVersion}
                shouldVerifyBeforeRevert={
                  lastVersionDto?.status !== TrackDtoStatusEnum.Published
                }
              />
            ) : (
              <CircularProgress />
            )}
          </Stack>
        </Stack>
      }
    />
  )
}

const SelectedVersionPanel = (props: {
  track: TrackDto
  isLastVersion: boolean
  resetSelection: () => void
  shouldVerifyBeforeRevert: boolean
}) => {
  const { track, isLastVersion, resetSelection, shouldVerifyBeforeRevert } =
    props
  const showRevertButton = !isLastVersion
  const isTrackLoaded = !!track

  return (
    <Stack alignItems="center" flex={1} pt={5} gap={3}>
      <TrackVersionActions
        showRevertButton={false && showRevertButton && isTrackLoaded}
        onRevert={async () => {
          // not implemented yet
          resetSelection()
        }}
        shouldVerifyBeforeRevert={shouldVerifyBeforeRevert}
      />
      <TrackContentView
        track={track}
        onClickEdit={() => {
          return
        }}
        hideHeader
      />
    </Stack>
  )
}

const TrackVersionActions = (props: {
  showRevertButton: boolean
  onRevert: () => Promise<void>
  shouldVerifyBeforeRevert: boolean
}) => {
  const { showRevertButton, onRevert, shouldVerifyBeforeRevert } = props
  return (
    <Stack
      sx={{
        width: "600px",
        alignItems: "flex-end",
      }}
    >
      {showRevertButton && (
        <RevertButton
          doRevert={onRevert}
          shouldVerify={shouldVerifyBeforeRevert}
        />
      )}
    </Stack>
  )
}

const findMostRecentVersion = (versions: TrackDto[]): TrackDto | undefined => {
  const firstVersion = versions[0]
  if (!firstVersion) {
    return undefined
  }
  versions.reduce((acc, version) => {
    if (version.version > acc.version) {
      return version
    }
    return acc
  }, firstVersion)
}

const findLastPublishedVersion = (
  versions: TrackDto[],
): TrackDto | undefined => {
  const publishedVersions = versions.filter(
    (version) => version.status === TrackDtoStatusEnum.Published,
  )
  const publishedVersionSortedFromLatestToOldest = publishedVersions.sort(
    (a, b) => b.version - a.version,
  )
  return publishedVersionSortedFromLatestToOldest[0]
}

const toVersionItem = (track: TrackDto): VersionItem<number> => ({
  id: track.id,
  version: track.version,
  createdAtTimestamp: new Date(track.createdAt).getTime(),
})
