import { useAppDispatch, useAppSelector } from "@app/hooks"
import { getProgramNoSyllabusWarning } from "../program/ui/program+ui"
import { ProgramDisplayData } from "./programsMenu"
import {
  Box,
  IconButton,
  Stack,
  StackProps,
  SvgIcon,
  Typography,
} from "@mui/material"
import appIcons from "@utils/appIcons"
import appTheme from "../../theme/appTheme"
import { domainToDisplayName } from "@utils/domainUtils"
import CustomTooltip from "@cmp/customTooltip"
import { formatDate } from "@masterschool/foundations"
import { ProgramStatusChip } from "../program/components/programStatusChip"
import { fetchClasses } from "@features/program/programSliceThunks"
import { isValidElement, useEffect } from "react"
import { ProgramClassDto } from "@masterschool/course-builder-api"
import { LoadingComponent } from "@cmp/loaders/loadingComponent"
import { Link, useNavigate } from "react-router-dom"
import {
  gridDetailPanelExpandedRowsContentCacheSelector,
  GridRenderCellParams,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid-pro"

export function ExpandCell(
  props: Pick<GridRenderCellParams, "id" | "value"> & {
    canExpand: boolean
  },
) {
  const { id, value: isExpanded, canExpand } = props
  const apiRef = useGridApiContext()
  const contentCache = useGridSelector(
    apiRef,
    gridDetailPanelExpandedRowsContentCacheSelector,
  )
  const hasDetail = isValidElement(contentCache[id])
  return (
    <Stack direction="row" sx={{ height: "100%" }}>
      <IconButton
        disabled={!hasDetail || !canExpand}
        sx={{ borderRadius: "50%", marginY: "25%" }}
      >
        <SvgIcon
          component={appIcons.chevronRight}
          inheritViewBox
          sx={{
            fill: "none",
            stroke: hasDetail
              ? appTheme.palette.icon.black
              : appTheme.palette.action.disabled,
            width: "20px",
            height: "20px",
            transform: isExpanded ? "rotate(90deg)" : "rotate(0deg)",
            transition: "all .1s ease-in-out",
          }}
        />
      </IconButton>
    </Stack>
  )
}

export function DomainCell({
  program,
  navigateOnClick,
}: {
  program: ProgramDisplayData
  navigateOnClick: boolean
}) {
  const navigate = useNavigate()
  const programNoSyllabusWarning = getProgramNoSyllabusWarning(program)

  return (
    <Stack
      direction="row"
      sx={{
        gap: 1,
        minHeight: "52px",
        height: "100%",
        alignItems: "center",
      }}
    >
      <Typography
        onClick={
          navigateOnClick ? () => navigate(`/program/${program.id}`) : undefined
        }
        variant="body2_sb"
        sx={{
          flexGrow: 1,
          maxWidth: "calc(100% - 60px)",
          "&:hover": {
            cursor: navigateOnClick ? "pointer" : undefined,
          },
        }}
      >
        {domainToDisplayName(program.domain)}
      </Typography>
      {programNoSyllabusWarning && (
        <CustomTooltip title={programNoSyllabusWarning} leaveDelay={0}>
          <Box sx={{ height: 20 }}>
            <SvgIcon
              component={appIcons.alertTriangle}
              inheritViewBox
              sx={{
                fill: "none",
                color: "warning.main",
                width: 20,
                height: 20,
              }}
            />
          </Box>
        </CustomTooltip>
      )}
    </Stack>
  )
}

export function StartDateCell({ startDate }: { startDate: Date }) {
  const formattedDate = formatDate(startDate, {
    year: "numeric",
    month: "long",
    day: "numeric",
  })
  return (
    <Stack direction="row" sx={CELLS_TYPOGRAPHY_STYLE}>
      <Typography variant="body2">{formattedDate}</Typography>
    </Stack>
  )
}

export function ScopeCell({ durationInMonths }: { durationInMonths: number }) {
  return (
    <Stack direction="row" sx={CELLS_TYPOGRAPHY_STYLE}>
      <Typography>{`${durationInMonths} months`}</Typography>
    </Stack>
  )
}

export function ClassesCell({ classesIds }: { classesIds: string[] }) {
  return (
    <Stack direction="row" sx={CELLS_TYPOGRAPHY_STYLE}>
      <Typography>{`${classesIds.length} classes`}</Typography>
    </Stack>
  )
}

export function StatusCell({
  startDate,
  durationInMonths,
}: {
  startDate: Date
  durationInMonths: number
}) {
  return (
    <Stack direction="row" sx={CELLS_TYPOGRAPHY_STYLE}>
      <ProgramStatusChip
        startDate={new Date(startDate)}
        durationInMonths={durationInMonths}
      />
    </Stack>
  )
}

export function ExpandedRowContent({
  program,
}: {
  program: ProgramDisplayData
}) {
  const dispatch = useAppDispatch()
  const classes = useAppSelector(
    (state) => state.program.programIdToClasses[program.id],
  )

  useEffect(() => {
    if (!classes) {
      dispatch(fetchClasses({ programId: program.id }))
    }
  }, [classes, dispatch, program.id])

  return (
    <Box sx={{}}>
      {classes && <ClassesTable classes={classes} program={program} />}
      {!classes && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "16px",
          }}
        >
          <LoadingComponent loadedComponents="" />
        </Box>
      )}
    </Box>
  )
}

function ClassesTable({
  program,
  classes,
}: {
  program: ProgramDisplayData
  classes: ProgramClassDto[]
}) {
  const syllabuses = useAppSelector((state) => state.syllabusesMenu.syllabuses)

  return (
    <Box
      sx={{
        width: "100%",
        bgcolor: "eTypes.sand15",
      }}
    >
      {classes.map(({ id, syllabusId, name, students }) => {
        const resolvedSyllabusId = syllabusId ?? program.syllabusId
        const syllabus = syllabuses.find((s) => s.id === resolvedSyllabusId)
        return (
          <Box
            component={Link}
            key={id}
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              textDecoration: "none",
              "&:hover": {
                bgcolor: appTheme.palette.action.hover,
              },
              pl: "72px",
              color: "inherit",
            }}
            to={`/program/${program.id}/${id}`}
          >
            <Typography sx={{ padding: "16px", flex: 3 }}>
              {name || "unnamed class"}
            </Typography>
            <Typography sx={{ padding: "16px", flex: 1 }}>
              {students.length === 1
                ? "1 student"
                : `${students.length} students`}
            </Typography>
            <Typography sx={{ padding: "16px", flex: 7 }}>
              {syllabus?.name || "No syllabus"}
            </Typography>
          </Box>
        )
      })}
    </Box>
  )
}

export const CELLS_TYPOGRAPHY_STYLE: StackProps["sx"] = {
  alignItems: "center",
  height: "100%",
}
