import { useAppDispatch, useAppSelector } from "@app/hooks"
import { SearchComponent } from "@cmp/searchComponent"
import { createProgramPopupOpened } from "@features/program/programSlice"
import { selectProgramsMenuType } from "@features/program/programsMenuSelector"
import {
  programFilterUpdated,
  programSearchTextUpdated,
} from "@features/search/searchSlice"
import {
  SearchAdvanceFilter,
  selectSearchedPrograms,
} from "@features/search/searchSlice"
import { Button, Stack } from "@mui/material"
import { domainToDisplayName } from "@utils/domainUtils"

export const ProgramsSearchComponent = () => {
  const dispatch = useAppDispatch()

  const programsMenuType = useAppSelector(selectProgramsMenuType)
  const shouldShowAddButton = programsMenuType === "legacy"

  const programFilters = useAppSelector(selectSearchedPrograms)

  return (
    <Stack display="flex" flexDirection="row" justifyContent="space-between">
      <SearchComponent
        onSearchTextChange={(text: string) =>
          dispatch(programSearchTextUpdated(text))
        }
        searchText={programFilters.text}
        inputFilters={programFilters.advanceFilters}
        onInputFiltersChange={(advanceFilter: SearchAdvanceFilter[]) => {
          dispatch(programFilterUpdated(advanceFilter))
        }}
        searchPlaceholder="Search for a program"
        filterTitle="Filter programs"
        getOptionLabel={(option) =>
          capitalizeFirstLetters(domainToDisplayName(option))
        }
      />
      {shouldShowAddButton && (
        <Button
          variant="contained"
          style={{
            width: "fit-content",
            flexShrink: 0,
          }}
          size="medium"
          onClick={() => dispatch(createProgramPopupOpened())}
        >
          Add program
        </Button>
      )}
    </Stack>
  )
}

const capitalizeFirstLetters = (str: string) => {
  return str
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ")
}
