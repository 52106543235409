import { GridColDef } from "@mui/x-data-grid-pro"
import { StudentAcademicStatusDTO } from "../../studentAcademicStatus.dto"
import { Chip, Link, Tooltip } from "@mui/material"
import { cohortColor } from "../display.utils"
import { dateStrComparator } from "./column.sort.comparators"
import { ColumnKeys } from "./column.keys"
import { DomainChip } from "@utils/domainChip"
import { domainToDisplayName } from "@utils/domainUtils"
import { ProgramDomain } from "@masterschool/course-builder-api"
import { LanguageChip } from "../../../../programs/program/components/languageChip"

export const REGISTRATION_COLUMNS: GridColDef<StudentAcademicStatusDTO>[] = [
  {
    field: ColumnKeys.PROGRAM,
    headerName: "School",
    width: 150,
    type: "singleSelect",
    valueOptions: schoolOptions,
    valueGetter: (value, studentRow: StudentAcademicStatusDTO) => {
      const domain = studentRow.registrationDetails?.domain
      if (!domain) return null
      return domainToDisplayName(domain)
    },
    renderCell: (params) => {
      const classData = params.row.registrationDetails
      if (!classData) return null
      const domain = classData.domain
      return <DomainChip domain={domain} />
    },
  },
  {
    field: ColumnKeys.COHORT,
    headerName: "Cohort",
    width: 150,
    type: "singleSelect",
    valueOptions: cohortOptions(),
    valueGetter: (value, studentRow: StudentAcademicStatusDTO) => {
      const cohort = studentRow.registrationDetails?.cohort
      if (!cohort) return null
      return new Date(cohort).toLocaleString("en-US", {
        month: "long",
        year: "2-digit",
      })
    },
    getSortComparator(sortDirection) {
      return (v1, v2) => dateStrComparator(v1, v2, sortDirection)
    },
    renderCell: (params) => {
      const cohort = params.row.registrationDetails?.cohort
      return (
        <Chip
          label={params.value}
          size="small"
          sx={{
            backgroundColor: cohort ? cohortColor(cohort) : undefined,
          }}
        />
      )
    },
  },
  {
    field: ColumnKeys.REGISTERED_CLASS,
    headerName: "Class",
    width: 200,
    valueGetter: (value, studentRow: StudentAcademicStatusDTO) => {
      return studentRow.registrationDetails?.className
    },
    renderCell: (params) => {
      const classData = params.row.registrationDetails
      if (!classData) return null
      const programId = classData.programId
      const classId = classData.classId
      const href = `/program/${programId}/${classId}`
      return (
        <Tooltip title={classData.className}>
          <Link href={href} target="_blank" variant="body1">
            {classData.className}
          </Link>
        </Tooltip>
      )
    },
  },
  {
    field: ColumnKeys.LANGUAGE,
    headerName: "Language",
    width: 160,
    valueGetter: (value, studentRow: StudentAcademicStatusDTO) => {
      return studentRow.registrationDetails?.language
    },
    renderCell: (params) => {
      const language = params.row.registrationDetails?.language
      if (!language) return null
      return <LanguageChip language={language} />
    },
  },
]

export function schoolOptions() {
  return Object.values(ProgramDomain)
    .filter((domain) => !domain.includes("TRAINING"))
    .map((domain) => domainToDisplayName(domain))
}

function cohortOptions() {
  const last14Months = Array.from({ length: 14 }, (_, i) => {
    const date = new Date()
    date.setMonth(date.getMonth() - i)
    return date
  })
  return last14Months.map((date) =>
    date.toLocaleString("en-US", { month: "long", year: "2-digit" }),
  )
}
