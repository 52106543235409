import { GeneralNavigationSideBar } from "@cmp/sidebar/generalNavigationSidebar"
import { Typography } from "@mui/material"
import useSpecificSearchParam from "@utils/hooks/useSpecificSearchParams"
import { useEffect } from "react"
import appTheme from "../theme/appTheme"

export enum CourseInstanceTabIdentifier {
  Home = "home",
  Content = "content",
  Students = "students",
  Classes = "classes",
  Calendar = "calendar",
}

const courseTabs = [
  //CourseInstanceTabIdentifier.Home,
  CourseInstanceTabIdentifier.Content,
  CourseInstanceTabIdentifier.Students,
  //CourseInstanceTabIdentifier.Classes,
  CourseInstanceTabIdentifier.Calendar,
]
export function defaultCourseTab() {
  return CourseInstanceTabIdentifier.Students
}

export function CourseInstanceSidebar() {
  const [tab, setTab] = useSpecificSearchParam("tab")

  const tabsIdentifiers = courseTabs

  const selectedTab =
    tab && tabsIdentifiers.includes(tab as CourseInstanceTabIdentifier)
      ? tab
      : defaultCourseTab()

  const tabs = tabsIdentifiers.map((tab) => (
    <Typography
      key={tab}
      variant={tab === selectedTab ? "body2_sb" : "body2"}
      sx={{
        display: "block",
        borderRadius: "8px",
        bgcolor: tab === selectedTab ? appTheme.palette.eTypes.sand50 : "none",
        cursor: "pointer",
        padding: "8px 16px",
        textTransform: "capitalize",
      }}
      onClick={() => setTab(tab)}
    >
      {tab}
    </Typography>
  ))

  useEffect(() => {
    if (tab && !tabsIdentifiers.includes(tab as CourseInstanceTabIdentifier)) {
      setTab(defaultCourseTab())
    }
  }, [tab, tabsIdentifiers, setTab])

  return (
    <GeneralNavigationSideBar
      tabs={tabs}
      sx={{
        width: "216px",
        maxWidth: "216px",
        minWidth: "216px",
        minHeight: "100%",
        padding: "16px 24px",
        bgcolor: appTheme.palette.primary.contrast,
      }}
      listSx={{
        pt: "16px",
      }}
    />
  )
}
