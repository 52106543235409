import { ProgramDto } from "@masterschool/course-builder-api"
import { TrackContentView } from "../../../main/tracks/TrackContentView"
import { useAppSelector } from "@app/hooks"
import { selectTrackById } from "@features/tracks/trackSelectors"
import { Typography } from "@mui/material"

export const ProgramTrackVersionPage = (props: { program: ProgramDto }) => {
  const { program } = props
  const track = useAppSelector((state) =>
    selectTrackById(state, program.syllabusId || ""),
  )
  if (!track) {
    return <Typography variant="body2">Track not found</Typography>
  }
  return (
    <TrackContentView
      track={track}
      hideHeader
      showLinkToTrack
      onClickEdit={() => {}}
    />
  )
}
