import { Box, Stack } from "@mui/material"
import { useParams } from "react-router-dom"
import { CourseInstanceHeader } from "./courseHeader"
import { LoadingComponent } from "@cmp/loaders/loadingComponent"
import { CourseInstanceSidebar } from "./courseInstanceSidebar"
import { CourseInstanceTabNavigator } from "./courseInstanceTabNavigator"
import appTheme from "../theme/appTheme"
import { useAppSelector } from "@app/hooks"
import { selectCourseInstance } from "@features/courseInstance/courseInstanceSelectors"

export const CourseInstanceMainPage = () => {
  const { courseInstanceId } = useParams()
  const courseInstance = useAppSelector((state) =>
    selectCourseInstance(state, courseInstanceId),
  )

  if (!courseInstance) {
    return <LoadingComponent loadedComponents={""} />
  }
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        backgroundColor: appTheme.palette.primary.contrast,
      }}
    >
      <CourseInstanceHeader courseInstance={courseInstance} />
      <Stack
        direction="row"
        sx={{
          width: "100%",
          height: "100%",
        }}
      >
        <CourseInstanceSidebar />
        <CourseInstanceTabNavigator />
      </Stack>
    </Box>
  )
}
