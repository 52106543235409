import { Chip } from "@mui/material"
import appTheme from "../theme/appTheme"
import AppIcon from "./appIcon"
import { IconType } from "./iconType"

export const IconChip = (props: {
  icon: IconType
  title: string
  size: number
}) => {
  return (
    <Chip
      size="small"
      icon={
        <AppIcon
          icon={props.icon}
          props={{
            sx: { width: props.size, height: props.size },
          }}
        />
      }
      label={props.title}
      sx={{
        backgroundColor: appTheme.palette.eTypes.sand25,
      }}
    />
  )
}
