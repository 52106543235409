import { DataGridPro, GridApiPro } from "@mui/x-data-grid-pro"
import { Box } from "@mui/material"
import { useAppDispatch } from "@app/hooks"
import appTheme from "../../theme/appTheme"
import { getLegacyProgramsMenuColumns } from "./programsMenuColumns"
import { ExpandedRowContent } from "./programsMenuCells"
import { selectedProgramsUpdated } from "@features/program/programsMenuSlice"
import { ProgramDisplayData } from "./programsMenu"

export function LegacyProgramsTable({
  data,
  gridApiRef,
}: {
  data: ProgramDisplayData[]
  gridApiRef: React.MutableRefObject<GridApiPro>
}) {
  const dispatch = useAppDispatch()

  return (
    <Box
      sx={{
        width: "100%",
        maxHeight: "100%",
        bgcolor: appTheme.palette.eTypes.sand15,
      }}
    >
      <DataGridPro
        apiRef={gridApiRef}
        rows={data}
        columns={getLegacyProgramsMenuColumns()}
        disableColumnMenu
        checkboxSelection
        disableRowSelectionOnClick
        disableColumnReorder
        hideFooter
        getRowId={(row) => row.id}
        onRowSelectionModelChange={(newSelection) => {
          const stringIds = newSelection.filter(
            (id) => typeof id === "string",
          ) as string[]
          dispatch(selectedProgramsUpdated(stringIds))
        }}
        getDetailPanelContent={(params) => (
          <ExpandedRowContent program={params.row} />
        )}
        getDetailPanelHeight={() => "auto"}
        getRowHeight={() => "auto"}
        initialState={{
          sorting: {
            sortModel: [{ field: "startDate", sort: "desc" }],
          },
        }}
        sx={{
          border: "none",
          "& .MuiDataGrid-row": {
            backgroundColor: "white",
          },
          "& .MuiDataGrid-row:hover": {
            backgroundColor: appTheme.palette.action.hover,
          },
          "& .MuiDataGrid-cell": {
            p: "0px !important",
          },
          "& .MuiDataGrid-cell:focus": {
            outline: "none",
          },
          "& .MuiDataGrid-cell:focus-within": {
            outline: "none",
          },
          "& .MuiDataGrid-checkboxInput": {
            p: "0px !important",
          },
          "& .MuiSvgIcon-root": {
            width: "16px !important",
            height: "16px !important",
          },
          "& .expand-cell": {
            width: "32px !important",
          },
          "& .domain-header": {
            width: "216px !important",
          },
          "& .expand-header": {
            width: "6px !important",
          },
          "& .MuiDataGrid-columnHeader:focus": {
            outline: "none !important",
          },
          "& .MuiDataGrid-columnHeader": {
            padding: "0px",
          },
          "& .MuiDataGrid-columnHeader:focus-within": {
            outline: "none !important",
          },
          "& .MuiDataGrid-columnSeparator": {
            display: "none",
          },
        }}
      />
    </Box>
  )
}
