import { CourseDescriptorDto } from "@masterschool/course-builder-api"
import { GridColDef, GridComparatorFn } from "@mui/x-data-grid-pro"
import {
  MiscCell,
  CourseIdCell,
  NameCell,
  SchoolCell,
  StatusCell,
} from "./coursesCells"
import { uuidToShortId } from "../utils"
import { schoolOptions } from "../../../tab-views/students-tab/students-grid/columns/registration.details.columns"
import { domainToDisplayName } from "@utils/domainUtils"

export const getNameColumn: () => GridColDef<CourseDescriptorDto> = () => {
  return {
    field: "name",
    headerName: "Name",
    flex: 1,
    renderCell: (params) => {
      return <NameCell course={params.row} />
    },
    sortComparator: nameComparator,
  }
}

export const getCourseIdColumn: () => GridColDef<CourseDescriptorDto> = () => {
  return {
    field: "id",
    headerName: "Course ID",
    flex: 1,
    renderCell: (params) => {
      return <CourseIdCell courseId={params.row.id} />
    },
    sortComparator: idComparator,
  }
}

export const getSchoolColumn: () => GridColDef<CourseDescriptorDto> = () => {
  return {
    field: "domain",
    headerName: "School",
    flex: 1,
    type: "singleSelect",
    valueOptions: schoolOptions,
    valueGetter: (_, row) => domainToDisplayName(row.domains[0]),
    renderCell: (params) => {
      return <SchoolCell course={params.row} />
    },
  }
}

const getStatusColumn: () => GridColDef<CourseDescriptorDto> = () => {
  return {
    field: "status",
    headerName: "Status",
    flex: 1,
    sortable: false,
    renderCell: (params) => {
      return <StatusCell status={params.row.status} />
    },
  }
}

const getMiscColumn: () => GridColDef<CourseDescriptorDto> = () => {
  return {
    field: "course-misc",
    headerClassName: "course-misc-header",
    headerName: "",
    sortable: false,
    renderCell: (params) => {
      return <MiscCell course={params.row} />
    },
  }
}

const idComparator: GridComparatorFn<string> = (courseId1, courseId2) => {
  const shortId1 = uuidToShortId(courseId1)
  const shortId2 = uuidToShortId(courseId2)
  return shortId1.localeCompare(shortId2)
}

const nameComparator: GridComparatorFn<string> = (name1, name2) => {
  return name1.localeCompare(name2)
}

export const coursesColumns = [
  getNameColumn(),
  getCourseIdColumn(),
  getSchoolColumn(),
  getStatusColumn(),
  getMiscColumn(),
]
