import { createAppAsyncThunk } from "@app/createAppAsyncThunk"
import { TermClient, TermDto } from "@clients/termClient"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import PendingDataState from "@utils/pendingDataState"
import { addMonths } from "date-fns"

export type TermState = {
  terms: PendingDataState<TermDto[]>
}

const getInitialState = (): TermState => ({
  terms: { status: "idle" },
})
export const termSlice = createSlice({
  name: "term",
  initialState: getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      fetchTerms.fulfilled,
      (state, action: PayloadAction<TermDto[]>) => {
        state.terms = {
          status: "success",
          data: action.payload,
        }
      },
    )
    builder.addCase(fetchTerms.pending, (state) => {
      state.terms = { status: "pending" }
    })
    builder.addCase(fetchTerms.rejected, (state) => {
      state.terms = { status: "error" }
    })
  },
})

export const fetchTerms = createAppAsyncThunk("term/fetch", async () => {
  const justBeforeWeStartUsingTerms = new Date("2024-11-01")
  const twoYearsFromNow = addMonths(new Date(), 24)
  return TermClient.listTerms({
    startDate: justBeforeWeStartUsingTerms,
    endDate: twoYearsFromNow,
  })
})

export default termSlice.reducer
