import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { createAppAsyncThunk } from "@app/createAppAsyncThunk"
import { StudentAcademicStatusDTO } from "../../tab-views/students-tab/studentAcademicStatus.dto"
import { GridFilterModel } from "@mui/x-data-grid-pro"
import { CampusClient } from "@clients/campusClient"
import PendingDataState from "@utils/pendingDataState"

export interface StudentsTabState {
  data: PendingDataState<StudentAcademicStatusDTO[]>
  savedFilters: { name: string; model: GridFilterModel }[]
}

export const STUDENTS_TAB_FILTERS_KEY = "studentsTab.savedFilters"

function getInitialSavedFilters() {
  const savedFilters = localStorage.getItem(STUDENTS_TAB_FILTERS_KEY)
  return savedFilters ? JSON.parse(savedFilters) : []
}

function getInitialState(): StudentsTabState {
  return {
    data: { status: "idle" },
    savedFilters: getInitialSavedFilters(),
  }
}

export const studentsTabSlice = createSlice({
  name: "studentsTab",
  initialState: getInitialState,
  reducers: {
    saveFilter: (
      state,
      action: PayloadAction<{ name: string; model: GridFilterModel }>,
    ) => {
      state.savedFilters.push(action.payload)
    },
    removeFilter: (state, action: PayloadAction<{ name: string }>) => {
      state.savedFilters = state.savedFilters.filter(
        (filter) => filter.name !== action.payload.name,
      )
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchStudentsData.pending, (state, action) => {
        state.data.status = "pending"
      })
      .addCase(fetchStudentsData.fulfilled, (state, action) => {
        state.data = { data: action.payload, status: "success" }
      })
      .addCase(fetchStudentsData.rejected, (state, action) => {
        state.data = { status: "error" }
      })
  },
})

export type ProgramStatus = "active" | "ended" | "upcoming"

export const fetchStudentsData = createAppAsyncThunk(
  "studentsTable/fetchStudentsData",
  async (params: ProgramStatus) => {
    return CampusClient.get<StudentAcademicStatusDTO[]>(
      "student-academic-status-dashboard?status=" + params,
    )
  },
)

export default studentsTabSlice.reducer

export const { saveFilter, removeFilter } = studentsTabSlice.actions
