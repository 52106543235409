import { ProgramDto } from "@masterschool/course-builder-api"
import { Box } from "@mui/material"
import useSpecificSearchParam from "@utils/hooks/useSpecificSearchParams"
import OnboardStudentsDialogPresenter from "../main/onboardStudentsDialogPresenter"
import LegacyProgramMain from "../main/legacyProgramMainPage"
import { SlackChannelsPage } from "../slack/slackChannelsPage"
import StudentsPage from "../students/studentsPage"
import SyllabusPage from "../syllabus/syllabusPage"
import {
  defaultProgramTab,
  ProgramTabIdentifier,
} from "@cmp/sidebar/programSidebar"
import { ProgramProjectsView } from "../projects/programProjectsView"
import { ProgramClassesView } from "../classes/programClassesView"
import { useAppSelector } from "@app/hooks"
import { selectClass } from "@features/program/programSliceSelectors"
import { useParams } from "react-router-dom"
import { ProgramStaffView } from "../staff/programStaffView"
import ProgramOverview from "../main/programOverview"
import { isLegacyProgram } from "../program.helper"
import { ProgramTrackVersionPage } from "../trackVersion/programTrackVersionPage"
import { ProgramCalendarView } from "../calendar/programCalendarView"

function ProgramView(props: { program: ProgramDto }) {
  const { classId } = useParams()
  const programClass = useAppSelector(selectClass(classId))
  const [tabId] = useSpecificSearchParam("tab")
  const isLegacy = isLegacyProgram(props.program)
  const selectedTabId = tabId ?? defaultProgramTab(isLegacy)

  const content = (() => {
    switch (selectedTabId) {
      case ProgramTabIdentifier.ProgramOverview:
        return <ProgramOverview program={props.program} />
      case ProgramTabIdentifier.LegacyProgramHome:
        return <LegacyProgramMain program={props.program} />
      case ProgramTabIdentifier.Syllabus:
        return (
          <SyllabusPage
            syllabusId={programClass?.syllabusId ?? props.program.syllabusId}
            programId={props.program.id}
            classId={classId}
          />
        )
      case ProgramTabIdentifier.TrackVersion:
        return <ProgramTrackVersionPage program={props.program} />
      case ProgramTabIdentifier.Students:
        return <StudentsPage program={props.program} />
      case ProgramTabIdentifier.Slack:
        return <SlackChannelsPage programId={props.program.id} />
      case ProgramTabIdentifier.Projects:
        return <ProgramProjectsView program={props.program} />
      case ProgramTabIdentifier.Classes:
        return <ProgramClassesView program={props.program} />
      case ProgramTabIdentifier.Staff:
        return <ProgramStaffView programId={props.program.id} />
      case ProgramTabIdentifier.ProgramCalendar:
        return <ProgramCalendarView program={props.program} />
      default:
        return <LegacyProgramMain program={props.program} />
    }
  })()

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        paddingTop: "35px",
        paddingX: "24px",
        gap: "24px",
        alignItems: "center",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          maxWidth: "1240px",
          width: "100%",
          gap: "24px",
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            overflow: "hidden",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {content}
        </Box>
      </Box>
      <OnboardStudentsDialogPresenter />
    </Box>
  )
}

export default ProgramView
