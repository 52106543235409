import { useAppDispatch, useAppSelector } from "@app/hooks"
import { TabIdentifierEnumeration } from "@features/coursesMenu/coursesMenuSlice"
import { selectLatestVersionsPublishedCourses } from "@features/coursesMenu/coursesSelectors"
import { selectCourseFilters } from "@features/search/searchSlice"
import { selectEditableSyllabus } from "@features/syllabus/syllabusSelectors"
import {
  editUnitCourses,
  editUnitCoursesPopupClosed,
} from "@features/syllabusEditor/syllabusEditorSlice"
import {
  CourseType,
  UnitCourseDescriptor,
} from "@masterschool/course-builder-api"
import { createSelector } from "@reduxjs/toolkit"
import { filterCourses } from "../../../../main/main-page-helpers"
import { CourseSelectionPopup } from "../../../popups/courseSelectionPopup"

const UnitCourseSelectionPopup = () => {
  const dispatch = useAppDispatch()
  const availableCoursesDescriptors: UnitCourseDescriptor[] = useAppSelector(
    selectAvailableCourses,
  ).map((course) => ({
    courseId: course.id,
    version: course.version,
  }))

  const unitId = useAppSelector(
    (state) => state.syllabusEditor.coursesSelectionPopup?.unitId,
  )
  const unit = useAppSelector((state) =>
    selectEditableSyllabus(state)?.units.find((u) => u.id === unitId),
  )

  if (!unitId || !unit) {
    return null
  }

  return (
    <CourseSelectionPopup
      onClose={() => dispatch(editUnitCoursesPopupClosed())}
      onSubmit={(selectedCourses: UnitCourseDescriptor[]) => {
        dispatch(editUnitCourses(selectedCourses))
      }}
      initialSelectedCourses={unit.courseDescriptors}
      initialAvailableCoursesDescriptors={availableCoursesDescriptors}
    />
  )
}

const selectAvailableCourses = createSelector(
  [
    selectLatestVersionsPublishedCourses,
    selectEditableSyllabus,
    selectCourseFilters,
    (state) => state.syllabusEditor.coursesSelectionPopup,
  ],
  (publishedCourses, syllabus, filters, popup) => {
    if (!publishedCourses || !syllabus) {
      return []
    }

    const filteredCourse = filterCourses(
      publishedCourses,
      [],
      filters.text,
      filters.advanceFilters,
      TabIdentifierEnumeration.All,
      [],
      CourseType.Legacy,
    )

    const coursesInSyllabus = syllabus.units
      .filter((unit) => unit.id !== popup?.unitId)
      .map((unit) =>
        unit.courseDescriptors.map(
          (courseDescriptor) => courseDescriptor.courseId,
        ),
      )
      .flat()

    return filteredCourse.filter(
      (course) => !coursesInSyllabus.includes(course.id),
    )
  },
)

export default UnitCourseSelectionPopup
