import { ToggleButton, ToggleButtonGroup, Typography } from "@mui/material"
import appTheme from "../../theme/appTheme"
import { useAppDispatch, useAppSelector } from "@app/hooks"
import { selectedProgramType } from "@features/program/programsMenuSlice"
import { selectProgramsMenuType } from "@features/program/programsMenuSelector"
import { selectShowTrackFeature } from "@features/login/featureFlagSelectors"

export type ProgramModelType = "legacy" | "next-gen"

export const ProgramTypeToggle = () => {
  const courseType = useAppSelector(selectProgramsMenuType)
  const dispatch = useAppDispatch()
  const showTrackFeature = useAppSelector(selectShowTrackFeature)
  if (!showTrackFeature) {
    return null
  }

  const onTypeChange = (
    _event: React.MouseEvent<HTMLElement>,
    type: ProgramModelType | null,
  ) => {
    if (!type) return
    dispatch(selectedProgramType(type))
  }

  return (
    <ToggleButtonGroup
      value={courseType}
      exclusive
      onChange={onTypeChange}
      sx={{
        height: "32px",
      }}
    >
      <ProgramTypeToggleButton
        value={"next-gen"}
        isSelected={courseType === "next-gen"}
      />
      <ProgramTypeToggleButton
        value={"legacy"}
        isSelected={courseType === "legacy"}
      />
    </ToggleButtonGroup>
  )
}

export const ProgramTypeToggleButton = (props: {
  value: ProgramModelType
  isSelected: boolean
}) => {
  return (
    <ToggleButton
      value={props.value}
      sx={{
        width: "128px",
        borderColor: appTheme.palette.other.outlineBorder,
        borderRadius: "2px",
        "&.Mui-selected": {
          backgroundColor: appTheme.palette.eTypes.sand50,
        },
      }}
    >
      <Typography
        variant={props.isSelected ? "body2_sb" : "body2"}
        color="text.primary"
      >
        {programTypeLabel(props.value)}
      </Typography>
    </ToggleButton>
  )
}

const programTypeLabel = (programType: ProgramModelType) => {
  switch (programType) {
    case "legacy":
      return "Legacy"
    case "next-gen":
      return "Next-gen"
  }
}
