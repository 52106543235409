import {
  CalendarHubClient,
  CourseClassCalendarDto,
  CustomCalendarResponse,
  MsOfferingCalendarDto,
  MsPrivateCalendarDto,
  ProgramManagementCalendar,
} from "@clients/calendarHubClient"
import { MsEventProps } from "@masterschool/ui-calendar/dist/src/types"
import { useCallback, useEffect, useMemo, useState } from "react"
import { mapToMsCalendarProps } from "./calendarMappers"

export const useGetCalendarsForProgramOwner = () => {
  const [isFetching, setIsFetching] = useState(false)
  const [calendars, setCalendars] = useState<ProgramManagementCalendar>({
    programSchoolCalendars: [],
    programStaffCalendars: [],
    studentCalendars: [],
    allMasterschoolStaffCalendars: [],
  })
  useEffect(() => {
    setIsFetching(true)
    CalendarHubClient.getProgramCalendars().then((res) => {
      setCalendars(res)
      setIsFetching(false)
    })
  }, [])
  return {
    calendars,
    isFetching,
  }
}

export const useGetCalendarReferencedByEvents = (msEvents: MsEventProps[]) => {
  const participantCalendarIds = useMemo(
    () => msEvents.flatMap((e) => e.participantCalendarIds || []).msUnique(),
    [msEvents],
  )
  const [calendars, setCalendars] = useState<CustomCalendarResponse>({
    personalCalendars: [],
    schoolCalendars: [],
  })
  useEffect(() => {
    CalendarHubClient.getCalendarsById(participantCalendarIds).then((res) => {
      setCalendars(res)
    })
  }, [participantCalendarIds])

  const fullCalendarList = calendars
    ? [...calendars.personalCalendars, ...calendars.schoolCalendars]
    : []
  return fullCalendarList
}

export const useGetCalendarById = (
  msEvents: MsEventProps[],
  calendars: (
    | MsPrivateCalendarDto
    | MsOfferingCalendarDto
    | CourseClassCalendarDto
  )[],
) => {
  const calendarsReferencedByEvents = useGetCalendarReferencedByEvents(msEvents)
  const getCalendarById = useCallback(
    (calendarId: string) => {
      const calendar =
        calendars.find((c) => c.id === calendarId) ||
        calendarsReferencedByEvents.find((c) => c.id === calendarId)
      return calendar ? mapToMsCalendarProps(calendar) : undefined
    },
    [calendarsReferencedByEvents, calendars],
  )

  return getCalendarById
}
