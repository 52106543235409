import { useMemo } from "react"
import { useAppSelector } from "@app/hooks"
import Stack from "@mui/material/Stack"
import MsCalendar from "@masterschool/ui-calendar"
import {
  CalendarFilters,
  CalendarGroupings,
  DoubleNestedCalendarGroupingSubgroup,
  MsCalendarProps,
} from "@masterschool/ui-calendar/dist/src/types"
import { Typography } from "@mui/material"
import { useEventActions } from "../../../main/calendar/useEventActions"
import appTheme from "../../../theme/appTheme"
import { FetchEventSnackbar } from "../../../main/calendar/FetchEventSnackbar"
import { mapToMsCalendarProps } from "../../../main/calendar/calendarMappers"
import { selectLoggedInUser } from "@features/login/loginSelectors"

import {
  CourseClassCalendarDto,
  MsOfferingCalendarDto,
  MsPrivateCalendarDto,
} from "@clients/calendarHubClient"

import {
  CourseDescriptorDto,
  ProgramDto,
} from "@masterschool/course-builder-api"
import { useState } from "react"

import { useGetCalendarById } from "../../../main/calendar/useGetCalendar"
import {
  DateRange,
  defaultDateRange,
  formatDateAsYearAndLongMonth,
} from "@utils/date"
import { TermDto } from "@clients/termClient"
import { useGetCourseClassCalendars } from "./programCalendarHooks"

export const ProgramCalendarView = ({ program }: { program: ProgramDto }) => {
  const [dateRange] = useState<DateRange>(defaultDateRange)
  const {
    staffCalendars,
    courseClassCalendars,
    programSchoolCalendars,
    courseDescriptors,
    terms,
  } = useGetCourseClassCalendars(program.id)

  const flatCalendarsForMenu = useMemo(
    () => [...programSchoolCalendars, ...courseClassCalendars],
    [programSchoolCalendars, courseClassCalendars],
  )

  const user = useAppSelector(selectLoggedInUser)

  const calendarIds = useMemo(
    () => flatCalendarsForMenu.msCompact().map((c) => c.id),
    [flatCalendarsForMenu],
  )

  const { msEvents, onChange, onDelete, addCalendarIdsToFetch, loading } =
    useEventActions(calendarIds, dateRange)

  const getCalendarById = useGetCalendarById(msEvents, flatCalendarsForMenu)

  if (!user) {
    return null
  }

  return (
    <Stack
      sx={{
        width: "100%",
        height: "100%",
        bgColor: appTheme.palette.primary.contrast,
      }}
    >
      <Typography variant="h6" pl="24px" pb="8px">
        Calendar
      </Typography>
      {loading && <FetchEventSnackbar />}
      <Stack
        flex={1}
        overflow="auto"
        sx={{
          width: "100%",
          height: "100%",
        }}
      >
        <MsCalendar
          events={msEvents}
          calendars={buildCalendarGroupings(
            staffCalendars,
            programSchoolCalendars,
            courseClassCalendars,
            courseDescriptors,
            terms,
          )}
          customCalendarFilters={getCourseClassCalendarFilters()}
          getCalendar={getCalendarById}
          onEventChange={onChange}
          userId={user.clientId}
          onDeleteEvent={onDelete}
          canJoinLiveSessions={false}
          onSelectedCalendarChange={(calendarIds) => {
            const calendarIdsExcludeDraft = calendarIds.filter(
              (c) => !c.includes("draft"),
            )
            addCalendarIdsToFetch(calendarIdsExcludeDraft)
          }}
          supportCreateEventTypes={["live-session", "regular"]}
        />
      </Stack>
    </Stack>
  )
}

const buildCalendarGroupings = (
  staffCalendars: MsPrivateCalendarDto[],
  programSchoolCalendars: MsOfferingCalendarDto[],
  classCalendars: CourseClassCalendarDto[],
  courseDescriptors: CourseDescriptorDto[],
  terms: TermDto[],
): CalendarGroupings => {
  return [
    {
      title: "Program",
      type: "single",
      groupingsType: "classes",
      groupingsAction: "all",
      calendars: programSchoolCalendars.map((c) => mapToMsCalendarProps(c)),
    },
    {
      title: "Classes",
      type: "double-nested",
      groupingsType: "classes",
      groupingsAction: "view",
      subGroups: groupClassCalendarsByCourse(
        classCalendars,
        courseDescriptors,
        terms,
      ),
    },
    {
      title: "Staff",
      type: "single",
      groupingsType: "staff",
      groupingsAction: "invite",
      calendars: staffCalendars.map((c) => mapToMsCalendarProps(c)),
    },
  ]
}

function groupClassCalendarsByCourse(
  classCalendars: CourseClassCalendarDto[],
  courseDescriptors: CourseDescriptorDto[],
  terms: TermDto[],
): DoubleNestedCalendarGroupingSubgroup[] {
  const courseInstanceToClassCalendars = classCalendars.reduce(
    (acc, calendar) => {
      const courseInstanceId = calendar.courseClassDetails.courseInstanceId
      if (!acc[courseInstanceId]) {
        acc[courseInstanceId] = {
          calendars: [],
          course: courseDescriptors.find(
            (cd) => cd.id === calendar.courseClassDetails.courseId,
          ),
          term: terms.find((t) => t.id === calendar.courseClassDetails.termId),
        }
      }
      acc[courseInstanceId].calendars.push(calendar)
      return acc
    },
    {} as Record<
      string,
      {
        calendars: CourseClassCalendarDto[]
        course: CourseDescriptorDto | undefined
        term: TermDto | undefined
      }
    >,
  )

  return Object.keys(courseInstanceToClassCalendars).msCompactMap(
    (courseInstanceId) => {
      const { calendars, course, term } =
        courseInstanceToClassCalendars[courseInstanceId]
      return {
        title: course?.name ?? "Unknown course",
        calendars: calendars.map((c) => ({
          ...mapToMsCalendarProps(c),
          tags: getCourseClassCalendarTags(c, course, term),
        })),
      }
    },
  )
}

const getCourseClassCalendarTags = (
  calendar: CourseClassCalendarDto,
  course: CourseDescriptorDto | undefined,
  term: TermDto | undefined,
) => {
  const tags: MsCalendarProps["tags"] = {
    language: [calendar.courseClassDetails.language],
  }

  if (course) {
    tags["course"] = [course.name]
  }
  if (term) {
    tags["term"] = [formatDateAsYearAndLongMonth(term.startDate)]
  }

  return tags
}

function getCourseClassCalendarFilters(): CalendarFilters {
  return {
    filters: [
      {
        field: "term",
        isMultiSelection: true,
        isRequired: false,
        label: "Term",
        operators: ["equals"],
        type: "string",
      },
      {
        field: "course",
        isMultiSelection: true,
        isRequired: false,
        label: "Course",
        operators: ["equals"],
        type: "string",
      },
      {
        field: "language",
        isMultiSelection: true,
        isRequired: false,
        label: "Language",
        operators: ["equals"],
        type: "string",
      },
    ],
    relations: "OR",
  }
}
