import { RootState } from "@app/store"
import { selectAllTracks } from "@features/tracks/trackSelectors"
import { createSelector } from "@reduxjs/toolkit"
import { NextGenProgramData } from "../../programs/menu/nextGenProgramsMenuTable"
import { mapProgramScope } from "../../programs/menu/programsMenu"
import { isLegacyProgram } from "../../programs/program/program.helper"

export const selectIsSnackbarOpen = (state: RootState) => {
  return (
    state.programsMenu.presentedPopup === undefined &&
    state.programsMenu.selectedProgramIds.length > 0
  )
}

export const selectProgramsMenuType = (state: RootState) => {
  return state.programsMenu.type
}

export const selectStudentRegistrations = createSelector(
  [(state: RootState) => state.programsMenu.studentRegistrations],
  (data) => {
    if (data.status !== "success") {
      return data.status
    }

    return data.data
  },
)

const selectPrograms = (state: RootState) => {
  return state.program.programs
}

export const selectNextGenProgramsData = createSelector(
  [selectPrograms, selectAllTracks, selectStudentRegistrations],
  (programs, tracks, studentsPerProgram): NextGenProgramData[] => {
    const nextGenPrograms = programs.filter((p) => !isLegacyProgram(p))
    const programsWithScope = mapProgramScope(nextGenPrograms)
    return programsWithScope.map((p) => {
      const isStudentDataLoaded = typeof studentsPerProgram !== "string"
      let studentsCount = 0
      if (isStudentDataLoaded) {
        studentsCount = studentsPerProgram[p.id] || 0
      }
      return {
        ...p,
        track: tracks.find((t) => t.id === p.syllabusId),
        studentsCount,
      }
    })
  },
)
