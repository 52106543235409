import { GridColDef } from "@mui/x-data-grid-pro"
import { ProgramDisplayData } from "./programsMenu"
import { Stack, SvgIcon } from "@mui/material"
import { useAppDispatch } from "@app/hooks"
import OptionsButton2 from "@cmp/buttons/optionsButton2"
import appIcons from "@utils/appIcons"
import { fetchClasses } from "@features/program/programSliceThunks"
import {
  deleteProgramPopupOpened,
  editProgramPopupOpened,
} from "@features/program/programSlice"
import { CELLS_TYPOGRAPHY_STYLE } from "./programsMenuCells"

export const getActionsColumn: () => GridColDef<ProgramDisplayData> = () => {
  return {
    field: "actions",
    headerName: "",
    type: "actions",
    width: 48,
    sortable: false,
    renderCell: (params) => (
      <LegacyProgramActionsCell programId={params.row.id} />
    ),
  }
}

function LegacyProgramActionsCell({ programId }: { programId: string }) {
  const dispatch = useAppDispatch()
  return (
    <Stack direction="row" sx={CELLS_TYPOGRAPHY_STYLE}>
      <OptionsButton2
        buttonModel={{
          type: "icon",
          props: {
            sx: {
              marginLeft: "auto",
              display: "flex",
            },
            size: "small",
            children: (
              <SvgIcon
                component={appIcons.dotsHorizontal}
                inheritViewBox
                sx={{ color: "primary.main" }}
              />
            ),
          },
        }}
        items={[
          {
            type: "menu-item",
            props: {
              key: "edit-program",
              children: "Edit program",
              onClick: () => {
                dispatch(
                  fetchClasses({
                    programId,
                  }),
                ).then(() => {
                  dispatch(
                    editProgramPopupOpened({
                      programId,
                    }),
                  )
                })
              },
            },
          },
          {
            type: "menu-item",
            props: {
              key: "delete-program",
              children: "Delete program",
              onClick: () => {
                dispatch(
                  fetchClasses({
                    programId,
                  }),
                ).then(() => {
                  dispatch(
                    deleteProgramPopupOpened({
                      programId,
                    }),
                  )
                })
              },
            },
          },
        ]}
      />
    </Stack>
  )
}
