import { CampusClient } from "./campusClient"

export enum CourseRegistrationType {
  Program = "Program",
  Manual = "Manual",
}

export enum CourseRegistrationEndStatus {
  Completed = "Completed",
  Failed = "Failed",
  Removed = "Removed",
}

export type CourseRegistrationEndReason =
  | { status: CourseRegistrationEndStatus.Completed }
  | { status: CourseRegistrationEndStatus.Failed }
  | { status: CourseRegistrationEndStatus.Removed; removedBy: string }

export class CourseClassRegistrationClient {
  static async registerStudentToClassManually(
    courseClassId: string,
    studentId: string,
    startDate: Date,
    userClientId: string,
  ): Promise<void> {
    return CampusClient.post<void>(
      `courseRegistration/register-student-to-class`,
      {
        courseClassId,
        userClientId: studentId,
        startDate,
        insertionType: CourseRegistrationType.Manual,
        insertManually: {
          type: CourseRegistrationType.Manual,
          userId: userClientId,
        },
      },
    )
  }

  static async unregisterStudentFromClass(
    courseClassId: string,
    studentId: string,
    endDate: Date,
    endReason: CourseRegistrationEndReason,
  ) {
    return CampusClient.delete<void>(
      `courseRegistration/unregister-student-from-class`,
      {
        courseClassId,
        userClientId: studentId,
        endDate,
        ...endReasonToRequest(endReason),
      },
    )
  }
}

function endReasonToRequest(endReason: CourseRegistrationEndReason) {
  switch (endReason.status) {
    case CourseRegistrationEndStatus.Completed:
      return {
        endStatus: CourseRegistrationEndStatus.Completed,
        removedEndReason: endReason,
      }
    case CourseRegistrationEndStatus.Failed:
      return {
        endStatus: CourseRegistrationEndStatus.Failed,
        completedEndReason: endReason,
      }
    case CourseRegistrationEndStatus.Removed:
      return {
        endStatus: CourseRegistrationEndStatus.Removed,
        failedEndReason: endReason,
      }
  }
}
