import { Button, Stack } from "@mui/material"

import { selectLatestVersionsPublishedCourses } from "@features/coursesMenu/coursesSelectors"
import { useAppSelector } from "@app/hooks"
import DraggableList from "@cmp/draggableList"
import { useEditTrack } from "./useEditTrack"
import { AddButton } from "./AddButton"
import { TermEditCard } from "./termEditCard"
import { TrackPageBodyWrapper } from "../trackPage"
import { TrackViewHeader } from "../trackViewHeader"

import { PublishTrackVersionPopup } from "./publishVersionPopup"
import { useState } from "react"
import { CourseType } from "@masterschool/course-builder-api"

export const TrackContentEditor = (props: {
  trackId: string
  onCloseEditMode: () => void
}) => {
  const { trackId, onCloseEditMode } = props

  const {
    currentTrack,
    onAddTerm,
    onRemoveTerm,
    onReorderTerm,
    onChangeTermCourses,
    canPublish,
    onPublish,
  } = useEditTrack(trackId)

  const coursesUsedByTrack = currentTrack?.terms.flatMap((term) =>
    term.courseDescriptors.map((cd) => cd.courseId),
  )
  const allCourses = useAppSelector(selectLatestVersionsPublishedCourses)
  const allCoursesOfTypeTrack = allCourses?.filter(
    (course) => course.type === CourseType.Track,
  )
  const coursesNotUsedInTrack = (allCoursesOfTypeTrack || []).filter(
    (course) => !coursesUsedByTrack?.includes(course.id),
  )

  if (!currentTrack) {
    return null
  }

  return (
    <>
      <TrackViewHeader
        track={currentTrack}
        isEditMode={true}
        onCloseEditMode={onCloseEditMode}
      >
        <HeaderActions
          onPublish={onPublish}
          canPublish={canPublish}
          onCloseEditMode={onCloseEditMode}
        />
      </TrackViewHeader>
      <TrackPageBodyWrapper>
        <Stack direction="column" width="600px" gap={2}>
          <DraggableList
            items={currentTrack.terms.map((term, index) => ({
              element: (
                <TermEditCard
                  term={term}
                  termNumber={index + 1}
                  onRemoveTerm={() => onRemoveTerm(term.id)}
                  courseCandidates={coursesNotUsedInTrack}
                  onChangeTermCourses={(courseDescriptors) =>
                    onChangeTermCourses(term.id, courseDescriptors)
                  }
                  key={term.id}
                />
              ),
              id: term.id,
            }))}
            onDragEnd={(result) => {
              const draggedElementId = result.active?.id
              const dragOverElementId = result.over?.id
              if (dragOverElementId) {
                const fromIndex = currentTrack.terms.findIndex(
                  (term) => term.id === draggedElementId,
                )
                const toIndex = currentTrack.terms.findIndex(
                  (term) => term.id === dragOverElementId,
                )

                onReorderTerm(fromIndex, toIndex)
              }
            }}
            id={"track-content-editor"}
            onDragStart={(_) => {}}
            onDragCancel={(_) => {}}
            onDragOver={(_) => {}}
          />

          <AddButton onClick={onAddTerm} sx={{ alignSelf: "flex-start" }}>
            {"Add Term"}
          </AddButton>
          <Stack sx={{ width: "100%", p: 4 }} />
        </Stack>
      </TrackPageBodyWrapper>
    </>
  )
}

const HeaderActions = (props: {
  canPublish: boolean
  onPublish: () => Promise<void>
  onCloseEditMode: () => void
}) => {
  const { canPublish, onPublish, onCloseEditMode } = props
  const [showPublishConfirmationPopup, setShowPublishConfirmationPopup] =
    useState(false)

  return (
    <>
      <Stack direction="row" gap={2}>
        <Button
          onClick={() => setShowPublishConfirmationPopup(true)}
          size="small"
          variant="contained"
          color="sand50"
          disabled={!canPublish}
        >
          Publish
        </Button>
      </Stack>
      {showPublishConfirmationPopup && (
        <PublishTrackVersionPopup
          onClose={() => {
            setShowPublishConfirmationPopup(false)
          }}
          onSubmitted={async () => {
            await onPublish()
            onCloseEditMode()
          }}
        />
      )}
    </>
  )
}
