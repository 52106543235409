import { RootState } from "@app/store"
import { TermDto } from "@clients/termClient"
import { createSelector } from "@reduxjs/toolkit"
import { ComparableDate } from "@utils/date"

export const selectTerms = (state: RootState): TermDto[] => {
  if (state.term.terms.status !== "success") {
    return []
  }
  return state.term.terms.data
}

export const selectCurrentTerm = createSelector([selectTerms], (terms) => {
  const now = new ComparableDate(new Date())
  return terms.find(
    (term) =>
      now.afterOrEqual(new Date(term.startDate)) &&
      now.beforeOrEqual(new Date(term.endDate)),
  )
})

export const selectTermsByFilter = createSelector(
  [
    (_, startDateString: string | undefined, _1) => startDateString,
    (_, _1, limit: number) => limit,
    selectTerms,
  ],
  (startDateString, limit, terms) => {
    const startDate = startDateString ? new Date(startDateString) : new Date()
    return terms
      .filter((term) =>
        new ComparableDate(new Date(term.startDate)).afterOrEqual(
          new Date(startDate),
        ),
      )
      .slice(0, limit)
  },
)
