export const flagForLanguage = (language: string) => {
  switch (language?.toLowerCase()) {
    case "english":
      return "🇬🇧"
    case "spanish":
      return "🇪🇸"
    case "french":
      return "🇫🇷"
    case "german":
      return "🇩🇪"
    case "italian":
      return "🇮🇹"
    case "russian":
      return "🇷🇺"
    case "ja":
      return "🇯🇵"
    case "herbrew":
      return "🇮🇱"
    default:
      return ""
  }
}

export const languageShortName = (language: string) => {
  switch (language) {
    case "English":
      return "EN"
    case "German":
      return "DE"
    default:
      return language
  }
}
