import {
  DataGridPro,
  GridApiPro,
  GridInitialState,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid-pro"
import { Box } from "@mui/material"
import appTheme from "../../theme/appTheme"
import { ProgramDisplayData } from "./programsMenu"
import { useNavigate } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "@app/hooks"
import { TrackDto } from "@masterschool/course-builder-api"
import { getNextGenProgramsMenuColumns } from "./nextGenColumns"
import {
  selectNextGenProgramsData,
  selectStudentRegistrations,
} from "@features/program/programsMenuSelector"
import { useCallback, useEffect, useLayoutEffect, useState } from "react"
import { LoadingComponent } from "@cmp/loaders/loadingComponent"
import { fetchStudentRegistrations } from "@features/program/programsMenuSlice"

export type NextGenProgramData = ProgramDisplayData & {
  track: TrackDto | undefined
  studentsCount: number
}

const STORAGE_KEY = "nextGenProgramsTableGridState"

export function NextGenProgramsTable({
  gridApiRef,
}: {
  gridApiRef: React.MutableRefObject<GridApiPro>
}) {
  const navigate = useNavigate()
  const programs = useAppSelector(selectNextGenProgramsData)
  const [initialState, setInitialState] = useState<GridInitialState>()

  const saveSnapshot = useCallback(() => {
    if (gridApiRef?.current?.exportState && localStorage) {
      const currentState = gridApiRef.current.exportState()
      localStorage.setItem(STORAGE_KEY, JSON.stringify(currentState))
    }
  }, [gridApiRef])

  useLayoutEffect(() => {
    const stateFromLocalStorage = localStorage?.getItem(STORAGE_KEY)
    setInitialState(
      stateFromLocalStorage ? JSON.parse(stateFromLocalStorage) : {},
    )

    window.addEventListener("beforeunload", saveSnapshot)

    return () => {
      window.removeEventListener("beforeunload", saveSnapshot)
      saveSnapshot()
    }
  }, [saveSnapshot])

  const registrationData = useAppSelector(selectStudentRegistrations)
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (registrationData === "idle") {
      dispatch(fetchStudentRegistrations())
    }
  }, [registrationData, dispatch])

  if (!initialState) {
    return <LoadingComponent loadedComponents="data" />
  }

  return (
    <Box
      sx={{
        width: "100%",
        maxHeight: "100%",
        bgcolor: appTheme.palette.eTypes.sand15,
      }}
    >
      <DataGridPro
        apiRef={gridApiRef}
        rows={programs}
        columns={getNextGenProgramsMenuColumns()}
        disableColumnMenu
        slots={{ toolbar: ProgramsTableToolbar }}
        onRowClick={(params) => navigate(`/program/${params.id}`)}
        disableColumnReorder
        hideFooter
        getRowId={(row) => row.id}
        getRowHeight={() => "auto"}
        initialState={initialState}
        sx={{
          border: "none",
          "& .MuiDataGrid-row": {
            backgroundColor: "white",
          },
          "& .MuiDataGrid-row:hover": {
            backgroundColor: appTheme.palette.action.hover,
            cursor: "pointer",
          },
          "& .MuiDataGrid-cell:focus": {
            outline: "none",
          },
          "& .MuiDataGrid-cell:focus-within": {
            outline: "none",
          },
          "& .MuiDataGrid-columnHeader:focus": {
            outline: "none !important",
          },
          "& .MuiDataGrid-columnHeader:focus-within": {
            outline: "none !important",
          },
          "& .MuiDataGrid-columnSeparator": {
            display: "none",
          },
        }}
      />
    </Box>
  )
}

export const ProgramsTableToolbar = () => {
  return (
    <GridToolbarContainer
      sx={{
        display: "flex",
        alignItems: "center",
        paddingBottom: "8px",
        backgroundColor: "white",
      }}
    >
      <GridToolbarQuickFilter fullWidth sx={{ flexGrow: 1 }} />
      <GridToolbarFilterButton />
    </GridToolbarContainer>
  )
}
