import { createAppAsyncThunk } from "@app/createAppAsyncThunk"
import {
  CourseDto,
  CreateSyllabusRequestTypeEnum,
  SyllabusDto,
  SyllabusDtoStatusEnum,
  UnitCourseDescriptor,
  UnitDto,
} from "@masterschool/course-builder-api"
import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import {
  SortOption,
  SortOrder,
  SortType,
} from "../../main/sort/coursesSortHelpers"
import {
  Syllabus,
  selectLastSyllabusVersion,
  selectSyllabus,
} from "../syllabus/syllabusSelectors"
import { CourseClient } from "@clients/courseClient"
import { SyllabusClient } from "@clients/syllabusClient"
import { createNewDraftVersion } from "@features/syllabusEditor/syllabusEditorSlice"

export enum SyllabusIdentifierEnumeration {
  All = "All",
  Active = "Active",
  Draft = "Draft",
  Archived = "Archived",
  Published = "Published",
}

export interface SyllabusesState {
  syllabuses: SyllabusDto[]
  loadingState: "idle" | "loading" | "failed"
  sortOption: SortOption
  tab: SyllabusIdentifierEnumeration
  courses: {
    [courseId: string]: {
      [version: string]: CourseDto
    }
  }
  renamePopup:
    | {
        syllabusId: string
        name: string | undefined
      }
    | undefined
}

const initialState: SyllabusesState = {
  syllabuses: [],
  loadingState: "idle",
  sortOption: {
    type: SortType.Chronological,
    order: SortOrder.Descending,
  },
  tab: SyllabusIdentifierEnumeration.Active,
  courses: {},
  renamePopup: undefined,
}

export const syllabusesMenuSlice = createSlice({
  name: "syllabusesMenu",
  initialState: initialState,
  reducers: {
    editSyllabusName: (
      state,
      action: PayloadAction<{ id: string; newName: string }>,
    ) => {
      const syllabusToEdit = state.syllabuses.find(
        (syllabus) => syllabus.id === action.payload.id,
      )

      if (syllabusToEdit) {
        syllabusToEdit.name = action.payload.newName
      }
    },
    sortAlphabeticallyClicked: (state, action: PayloadAction<SortOrder>) => {
      state.sortOption.type = SortType.Alphabetic
      state.sortOption.order = action.payload
    },
    sortChronologicallyClicked: (state, action: PayloadAction<SortOrder>) => {
      state.sortOption.type = SortType.Chronological
      state.sortOption.order = action.payload
    },
    tabSelected: (
      state,
      action: PayloadAction<SyllabusIdentifierEnumeration>,
    ) => {
      state.tab = action.payload
    },
    renameClicked: (state, action: PayloadAction<{ syllabusId: string }>) => {
      const syllabus = state.syllabuses.find(
        (syllabus) => syllabus.id === action.payload.syllabusId,
      )
      state.renamePopup = {
        syllabusId: action.payload.syllabusId,
        name: syllabus?.name,
      }
    },
    renamePopupClosed: (state) => {
      state.renamePopup = undefined
    },
    renamePopupNameChanged: (
      state,
      action: PayloadAction<{ newName: string | undefined }>,
    ) => {
      if (state.renamePopup) {
        state.renamePopup.name = action.payload.newName
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSyllabuses.fulfilled, (state, action) => {
        state.syllabuses = action.payload
        state.loadingState = "idle"
      })
      .addCase(fetchSyllabuses.pending, (state) => {
        state.loadingState = "loading"
      })
      .addCase(fetchSyllabuses.rejected, (state) => {
        state.loadingState = "failed"
      })
      .addCase(create.fulfilled, (state, action) => {
        state.syllabuses.push(action.payload)
      })
      .addCase(duplicate.fulfilled, (state, action) => {
        const newSyllabus = action.payload

        if (!newSyllabus) return

        state.syllabuses.push(newSyllabus)
        state.tab = SyllabusIdentifierEnumeration.Draft
      })
      .addCase(renameAccepted.fulfilled, (state, action) => {
        state.renamePopup = undefined
        const newSyllabus = action.payload
        if (!newSyllabus) return
        const syllabusToEdit = state.syllabuses.find(
          (syllabus) => syllabus.id === newSyllabus.id,
        )

        if (syllabusToEdit) {
          syllabusToEdit.name = newSyllabus.name
        }
      })
      .addCase(fetchCourseByIdAndVersion.fulfilled, (state, action) => {
        if (!action.payload) return
        if (state.courses[action.payload.id]) {
          state.courses[action.payload.id][action.payload.version] =
            action.payload
        } else {
          state.courses[action.payload.id] = {
            [action.payload.version]: action.payload,
          }
        }
      })
  },
})

export const fetchSyllabuses = createAppAsyncThunk(
  "syllabusesMenu/fetchSyllabuses",
  async () => {
    return SyllabusClient.listSyllabuses()
  },
)

export const create = createAppAsyncThunk<SyllabusDto, void, undefined>(
  "syllabusesMenu/create",
  async (_, thunkApi) => {
    return SyllabusClient.createSyllabus({
      name: "",
      externalName: "",
      isArchived: false,
      domain: "",
      units: [],
    })
  },
)

export const duplicate = createAppAsyncThunk(
  "syllabusesMenu/duplicate",
  async (syllabusId: string, thunkAPI) => {
    const syllabus = selectSyllabus(syllabusId)(thunkAPI.getState())

    if (!syllabus) return

    const duplicatedSyllabus = {
      ...syllabus,
      name: syllabus.name + " (Copy)",
      type: CreateSyllabusRequestTypeEnum.Legacy,
    }

    return SyllabusClient.createSyllabus(duplicatedSyllabus)
  },
)

export const renameAccepted = createAppAsyncThunk(
  "syllabusesMenu/renameAccepted",
  async (_, thunkAPI) => {
    const popupState = thunkAPI.getState().syllabusesMenu.renamePopup

    if (!popupState || !popupState.name) {
      return
    }

    const syllabus = thunkAPI
      .getState()
      .syllabusesMenu.syllabuses.find(
        (syllabus) => syllabus.id === popupState.syllabusId,
      )

    if (!syllabus) {
      return
    }

    const updatedSyllabus = {
      ...syllabus,
      name: popupState.name,
    }

    return SyllabusClient.updateSyllabus(popupState.syllabusId, updatedSyllabus)
  },
)

export const fetchUnitCourses = createAppAsyncThunk(
  "syllabusesMenu/fetchCourseByIdAndVersion",
  async (unit: UnitDto, thunkAPI) => {
    const fetchedCourses = thunkAPI.getState().syllabusesMenu.courses
    const coursesToFetch = unit.courseDescriptors.filter(
      (descriptor) =>
        !fetchedCourses[descriptor.courseId] ||
        !fetchedCourses[descriptor.courseId][descriptor.version],
    )

    coursesToFetch.forEach((descriptor) => {
      thunkAPI.dispatch(fetchCourseByIdAndVersion(descriptor))
    })
  },
)

export const fetchCourseByIdAndVersion = createAppAsyncThunk(
  "syllabusesMenu/fetchCourseByIdAndVersion",
  async (descriptor: UnitCourseDescriptor, thunkAPI) => {
    return CourseClient.getCourseByVersion(
      descriptor.courseId,
      descriptor.version,
    )
  },
)

export const revertSyllabusToVersion = createAppAsyncThunk(
  "syllabusesMenu/revertSyllabusToVersion",
  async (targetVersion: Syllabus, thunkAPI) => {
    const syllabusId = targetVersion.id
    const syllabusLastVersion = selectLastSyllabusVersion(syllabusId)(
      thunkAPI.getState(),
    )

    if (!syllabusLastVersion) {
      console.error("Syllabus last version not found for id " + syllabusId)
      return "error"
    }
    const hasUnpublishedChanges =
      syllabusLastVersion.status !== SyllabusDtoStatusEnum.Published

    let versionIdToUpdate = undefined
    if (hasUnpublishedChanges) {
      versionIdToUpdate = syllabusLastVersion.version
    } else {
      const newDraftVersionNumber = await thunkAPI
        .dispatch(createNewDraftVersion(syllabusId))
        .unwrap()
        .then((newDraft: SyllabusDto) => newDraft.version)

      versionIdToUpdate = newDraftVersionNumber
    }

    await SyllabusClient.updateSyllabus(syllabusId, {
      ...targetVersion,
      version: versionIdToUpdate,
      status: SyllabusDtoStatusEnum.Draft,
    }).then(() => thunkAPI.dispatch(fetchSyllabuses()))
  },
)

export const {
  editSyllabusName,
  sortAlphabeticallyClicked,
  sortChronologicallyClicked,
  tabSelected,
  renameClicked,
  renamePopupClosed,
  renamePopupNameChanged,
} = syllabusesMenuSlice.actions

export default syllabusesMenuSlice.reducer
