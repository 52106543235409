import { useAppSelector } from "@app/hooks"
import OverflowTooltip from "@cmp/overflowTooltip"
import { Link as MuiLink, Stack, Typography } from "@mui/material"
import { Link } from "react-router-dom"
import WidgetCard from "./widgetCard"
import { selectTrackById } from "@features/tracks/trackSelectors"
import { ProgramDto } from "@masterschool/course-builder-api"
import appIcons from "@utils/appIcons"
import AppIcon from "@utils/appIcon"

function TracksWidget(props: { program: ProgramDto }) {
  const program = props.program
  const track = useAppSelector((state) =>
    selectTrackById(state, program.syllabusId || ""),
  )
  const title = `Track version`
  const linkToTrackVersionTab = `/program/${program.id}?tab=track+version`

  return (
    <WidgetCard title={title} navigateTo={linkToTrackVersionTab}>
      <Stack width={1} overflow="hidden">
        <MuiLink component={Link} to={linkToTrackVersionTab} underline="hover">
          <OverflowTooltip>
            <Stack direction="row" spacing={2} alignItems="center">
              <AppIcon
                icon={appIcons.gitBranch01}
                props={{ sx: { width: "15px", height: "15px" } }}
              />
              <Typography variant="body2">
                {track?.externalName || "N/A"}
              </Typography>
            </Stack>
          </OverflowTooltip>
        </MuiLink>
      </Stack>
    </WidgetCard>
  )
}

export default TracksWidget
