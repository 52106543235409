import { useGridApiRef } from "@mui/x-data-grid-pro"
import { Box, Typography } from "@mui/material"
import { useAppSelector } from "@app/hooks"
import { ProgramDto } from "@masterschool/course-builder-api"
import CreateProgramPopup from "./createProgramPopup"
import DeleteProgramPopup from "./deleteProgramPopup"
import EditProgramPopup from "./editProgramPopup"
import { ProgramsSearchComponent } from "../program/components/programSearchComponent"
import { selectMainPagePrograms } from "@features/search/searchSlice"
import MultipleSelectedProgramsSnackbar from "./multipleSelectionSnackbar"
import MultiProgramPopups from "./multiProgramPopups"
import { isLegacyProgram } from "../program/program.helper"
import { ProgramTypeToggle } from "./programTypeToggle"
import { selectProgramsMenuType } from "@features/program/programsMenuSelector"
import { LegacyProgramsTable } from "./legacyProgramsMenuTable"
import { NextGenProgramsTable } from "./nextGenProgramsMenuTable"

export type ProgramDisplayData = ProgramDto & {
  scope: ProgramScopeData
}

type ProgramScopeData = {
  type: string
  language: string
}

function ProgramsMenu() {
  const allPrograms = useAppSelector(selectMainPagePrograms)
  const programsTypeToShow = useAppSelector(selectProgramsMenuType)
  const visiblePrograms = allPrograms.filter(
    (program) => (programsTypeToShow === "legacy") === isLegacyProgram(program),
  )
  const programsWithScope = mapProgramScope(visiblePrograms)
  const gridApiRef = useGridApiRef()

  const unSelectRows = () => {
    if (gridApiRef.current) gridApiRef.current.setRowSelectionModel([])
  }
  const isShowingLegacy = programsTypeToShow === "legacy"

  return (
    <Box
      sx={{
        width: "100%",
        overflow: "scroll",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingX: "24px",
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: "1240px",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          mt: "80px",
          mb: "64px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography variant="h4">Programs</Typography>
          <ProgramTypeToggle />
        </Box>
        {isShowingLegacy && <ProgramsSearchComponent />}
        {isShowingLegacy ? (
          <LegacyProgramsTable
            data={programsWithScope}
            gridApiRef={gridApiRef}
          />
        ) : (
          <NextGenProgramsTable gridApiRef={gridApiRef} />
        )}
      </Box>
      <CreateProgramPopup />
      <DeleteProgramPopup />
      <EditProgramPopup />
      <MultipleSelectedProgramsSnackbar />
      <MultiProgramPopups onClose={unSelectRows} />
    </Box>
  )
}

export function mapProgramScope(programs: ProgramDto[]): ProgramDisplayData[] {
  return programs.map((program) => ({
    ...program,
    scope: {
      type: program.type,
      language: program.language,
    },
  }))
}

export default ProgramsMenu
