import DragIcon from "@cmp/dragIcon"
import {
  CourseDescriptorDto,
  TermDto,
  UnitCourseDescriptor,
} from "@masterschool/course-builder-api"
import {
  Accordion,
  Stack,
  AccordionSummary,
  Divider,
  AccordionDetails,
} from "@mui/material"
import { useState } from "react"
import { CourseSelectionPopup } from "../../../editor/popups/courseSelectionPopup"
import { TermOptionsMenu } from "./termOptionsMenu"
import { AddButton } from "./AddButton"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import {
  LiveSessionHours,
  SelfLearningHours,
  useGetTermHours,
} from "../contentHours"
import { TermCoursesList } from "./TermCoursesList"

export const TermEditCard = (props: {
  term: TermDto
  termNumber: number
  onRemoveTerm: () => void
  onChangeTermCourses: (courseDescriptors: UnitCourseDescriptor[]) => void
  courseCandidates: CourseDescriptorDto[]
}) => {
  const { term, termNumber, onRemoveTerm, onChangeTermCourses } = props
  const [showCoursesPopup, setShowCoursesPopup] = useState(false)

  const courseCandidates = props.courseCandidates.map((course) => ({
    courseId: course.id,
    version: course.version,
  }))

  return (
    <>
      <TermAccordion
        term={term}
        termNumber={termNumber}
        onRemoveTerm={onRemoveTerm}
        onChangeTermCourses={onChangeTermCourses}
        onClickAddCourse={() => setShowCoursesPopup(true)}
      />
      {showCoursesPopup && (
        <CourseSelectionPopup
          onClose={() => setShowCoursesPopup(false)}
          onSubmit={(selectedCourses) => {
            onChangeTermCourses(selectedCourses)
            setShowCoursesPopup(false)
          }}
          initialSelectedCourses={term.courseDescriptors}
          initialAvailableCoursesDescriptors={courseCandidates}
        />
      )}
    </>
  )
}

const TermAccordion = (props: {
  term: TermDto
  termNumber: number
  onRemoveTerm: () => void
  onChangeTermCourses: (courseDescriptors: UnitCourseDescriptor[]) => void
  onClickAddCourse: () => void
}) => {
  const { term, termNumber, onRemoveTerm, onClickAddCourse } = props
  const [showOptionsButton, setShowOptionsButton] = useState(false)

  return (
    <Accordion
      defaultExpanded
      onMouseEnter={() => setShowOptionsButton(true)}
      onMouseLeave={() => setShowOptionsButton(false)}
    >
      <Stack direction="row" alignItems="center" pl={2}>
        {/* dragablity is managed by TrackContentEditor */}
        <InactiveDragIcon />
        <TermAccordionSummary
          term={term}
          termNumber={termNumber}
          onRemoveTerm={onRemoveTerm}
          showOptionsButton={showOptionsButton}
        />
      </Stack>
      <Divider />
      <AccordionDetails>
        <Stack gap={2} alignItems="flex-start">
          <TermCoursesList courses={term.courseDescriptors} />
          <AddButton onClick={onClickAddCourse} sx={{ p: 0.5 }}>
            {"Add Course"}
          </AddButton>
        </Stack>
      </AccordionDetails>
    </Accordion>
  )
}

const TermAccordionSummary = (props: {
  term: TermDto
  termNumber: number
  onRemoveTerm: () => void
  showOptionsButton: boolean
}) => {
  const { term, termNumber, showOptionsButton, onRemoveTerm } = props
  const { liveSessionHours, selfLearningHours } = useGetTermHours(term)

  return (
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      sx={{
        width: "100%",
        height: "56px",
        flexDirection: "row-reverse",
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        width="100%"
        justifyContent="space-between"
      >
        {`Term ${termNumber}`}
        <Stack direction="row" gap={1}>
          <Stack direction="row" gap={1}>
            <LiveSessionHours hours={liveSessionHours} />
            <SelfLearningHours hours={selfLearningHours} />
          </Stack>
          {showOptionsButton && (
            <TermOptionsMenu onClickRemove={onRemoveTerm} />
          )}
        </Stack>
      </Stack>
    </AccordionSummary>
  )
}

const InactiveDragIcon = () => {
  return <DragIcon onMouseEnter={() => {}} onMouseLeave={() => {}} size={20} />
}
