import { Stack, TextField, Typography } from "@mui/material"
import TitleTextField from "../components/titleTextField"
import UrlTextField from "../components/urlTextField"
import {
  CourseSyllabusLiveEvent,
  Topic,
} from "@masterschool/course-builder-api"
import useSelectedLiveEvent from "./custom-hooks/useSelectedLiveEvent"
import { selectPublishValidationsForItem } from "../../features/courseEditor/courseValidationsSelectors"
import useEditLiveEvent from "./custom-hooks/useEditLiveEvent"
import { useAppSelector } from "@app/hooks"
import TimeEstimationTextField from "../components/timeEstimationTextField"

export function LiveEventEditor(props: { topic: Topic }) {
  const { topic } = props
  const liveEvent = useSelectedLiveEvent()
  const editLiveEvent = useEditLiveEvent()
  const errors = useAppSelector(selectPublishValidationsForItem(liveEvent?.id))

  if (!liveEvent) {
    return undefined
  }

  return (
    <Stack
      sx={{
        width: "100%",
        height: "100%",
        bgcolor: "white",
        alignItems: "center",
        gap: "32px",
        padding: "24px",
      }}
    >
      <StudentFacingInfo
        liveEvent={liveEvent}
        topic={topic}
        errors={errors || []}
        editLiveEvent={editLiveEvent}
      />
      <InstructorFacingInfo
        liveEvent={liveEvent}
        topic={topic}
        errors={errors || []}
        editLiveEvent={editLiveEvent}
      />
      <Settings
        liveEvent={liveEvent}
        topic={topic}
        editLiveEvent={editLiveEvent}
      />
    </Stack>
  )
}

function StudentFacingInfo({
  liveEvent,
  topic,
  errors,
  editLiveEvent,
}: {
  liveEvent: CourseSyllabusLiveEvent
  topic: Topic
  errors: string[]
  editLiveEvent: ReturnType<typeof useEditLiveEvent>
}) {
  const isPresentationUrlInvalid =
    errors.includes("INVALID_PRESENTATION_URL") && !!liveEvent?.presentationUrl
  const isMaterialsUrlInvalid =
    errors.includes("INVALID_MATERIALS_URL") && !!liveEvent?.materialsUrl

  return (
    <Stack
      sx={{
        width: "100%",
        alignItems: "center",
        gap: "16px",
      }}
    >
      <Typography variant="body2_sb" sx={{ alignSelf: "left", width: "100%" }}>
        Student facing
      </Typography>
      <TitleTextField
        elementId={liveEvent.id}
        label="Session name"
        value={liveEvent.title}
        debounce
        onChange={(e) => {
          editLiveEvent(liveEvent, "title", e.target.value, topic)
        }}
      />
      <TextField
        value={liveEvent.description}
        onChange={(event) => {
          editLiveEvent(liveEvent, "description", event.target.value, topic)
        }}
        rows={3}
        multiline
        fullWidth
        label="Description"
        size="small"
        InputProps={{ sx: { fontSize: "14px", padding: "16px" } }}
        InputLabelProps={{ sx: { fontSize: "14px" } }}
      />
      <UrlTextField
        elementId={liveEvent.id}
        value={liveEvent.presentationUrl}
        debounce
        invalidUrl={isPresentationUrlInvalid}
        label="Presentation URL (optional)"
        onChange={(e) => {
          const newValue = e.target.value
          editLiveEvent(liveEvent, "presentationUrl", newValue, topic)
        }}
      />

      <UrlTextField
        elementId={liveEvent.id}
        value={liveEvent.materialsUrl}
        debounce
        missingUrl={false}
        invalidUrl={isMaterialsUrlInvalid}
        label="Materials URL (optional)"
        onChange={(e) => {
          const newValue = e.target.value
          editLiveEvent(liveEvent, "materialsUrl", newValue, topic)
        }}
      />
    </Stack>
  )
}

function InstructorFacingInfo({
  liveEvent,
  topic,
  errors,
  editLiveEvent,
}: {
  liveEvent: CourseSyllabusLiveEvent
  topic: Topic
  errors: string[]
  editLiveEvent: ReturnType<typeof useEditLiveEvent>
}) {
  const isLessonPlanUrlMissing =
    errors.includes("MISSING_LESSON_PLAN_URL") && !liveEvent?.lessonPlanUrl
  const isLessonPlanUrlInvalid =
    errors.includes("INVALID_LESSON_PLAN_URL") && !!liveEvent?.lessonPlanUrl

  return (
    <Stack
      sx={{
        width: "100%",
        alignItems: "center",
        gap: "16px",
      }}
    >
      <Typography variant="body2_sb" sx={{ alignSelf: "left", width: "100%" }}>
        Instructor facing
      </Typography>
      <UrlTextField
        elementId={liveEvent.id}
        value={liveEvent.lessonPlanUrl}
        debounce
        missingUrl={isLessonPlanUrlMissing}
        invalidUrl={isLessonPlanUrlInvalid}
        label="Lesson plan URL"
        onChange={(e) => {
          const newValue = e.target.value
          editLiveEvent(liveEvent, "lessonPlanUrl", newValue, topic)
        }}
      />
    </Stack>
  )
}

function Settings({
  liveEvent,
  topic,
  editLiveEvent,
}: {
  liveEvent: CourseSyllabusLiveEvent
  topic: Topic
  editLiveEvent: ReturnType<typeof useEditLiveEvent>
}) {
  return (
    <Stack
      sx={{
        width: "100%",
        alignItems: "center",
        gap: "16px",
      }}
    >
      <Typography variant="body2_sb" sx={{ alignSelf: "left", width: "100%" }}>
        Settings
      </Typography>
      <TimeEstimationTextField
        elementId={liveEvent.id}
        value={liveEvent.estimatedDuration}
        debounce
        onChange={(e) => {
          editLiveEvent(
            liveEvent,
            "estimatedDuration",
            parseInt(e.target.value),
            topic,
          )
        }}
      />
    </Stack>
  )
}
