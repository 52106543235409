import { useAppSelector } from "@app/hooks"
import FullScreenLoadingComponent from "@cmp/loaders/fullScreenLoadingComponent"
import { Box, Stack } from "@mui/material"
import { useParams, useSearchParams } from "react-router-dom"
import { selectTrackById } from "@features/tracks/trackSelectors"
import { TrackContentView } from "./TrackContentView"
import { TrackContentEditor } from "./track-editor/trackContentEditor"
export function TrackPage() {
  const { trackId } = useParams()
  const [searchParams, setURLSearchParams] = useSearchParams()
  const editModeFromParams = searchParams.get("edit")

  const track = useAppSelector((state) => selectTrackById(state, trackId || ""))

  const onClickEdit = () => {
    setURLSearchParams({ edit: "true" })
  }
  const onCloseEditMode = () => {
    setURLSearchParams({})
  }

  if (!trackId) {
    return null
  }
  if (!track) {
    return <FullScreenLoadingComponent loadedComponents="" />
  }

  if (editModeFromParams)
    return (
      <TrackPageWrapper>
        <TrackContentEditor
          trackId={track.id}
          onCloseEditMode={onCloseEditMode}
        />
      </TrackPageWrapper>
    )
  else
    return (
      <TrackPageWrapper>
        <TrackContentView track={track} onClickEdit={onClickEdit} />
      </TrackPageWrapper>
    )
}

const TrackPageWrapper = (prop: { children: React.ReactNode }) => {
  return (
    <Stack
      sx={{
        width: "100%",
        height: "100%",
        backgroundColor: "primary.contrast",
        overflow: "hidden",
        overflowY: "scroll",
      }}
    >
      {prop.children}
    </Stack>
  )
}

export const TrackPageBodyWrapper = (prop: { children: React.ReactNode }) => {
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        height: "100%",
        justifyContent: "center",
        marginLeft: "auto",
        marginRight: "auto",
        overflow: "auto",
        pt: 6,
      }}
    >
      {prop.children}
    </Box>
  )
}
