import {
  ChangeProgramClassRequestChangeTypeEnum,
  ProgramClassDto,
  ProgramDomain,
  ProgramDto,
  ProgramType,
  UserDto,
  UserRegistrationStatusResponseResultEnum,
} from "@masterschool/course-builder-api"
import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import {
  addProgramStaffMemberPopupAccepted,
  changeStudentClass,
  changeStudentProgram,
  createLegacyProgram,
  deleteProgramConfirmed,
  editProgramStaffMemberPopupAccepted,
  fetchClasses,
  fetchProgramStaff,
  fetchPrograms,
  preprocessMultipleStudentsRegistration,
  preprocessSingleStudentRegistration,
  registerMultipleStudents,
  registerStudent,
  unregisterStudent,
} from "./programSliceThunks"
import PendingDataState from "@utils/pendingDataState"
import { ProgramStaffMember } from "@clients/programStaffClient"

export type Class = ProgramClassDto

type SingleStudentOnboardPopup = {
  classId: string | undefined
  isClassEditable: boolean
  form: Omit<UserDto, "id" | "roles">
  state: "idle" | "pending" | "rejected"
  errorMessage?: string
}

export type MultipleStudentsOnboardPopup = {
  classId: string | undefined
  isClassEditable: boolean
  students: Omit<UserDto, "id" | "roles">[]
  statuses: {
    [email: string]: "ready" | "registered" | "registeredElsewhere" | "invalid"
  }
  registrationState: {
    [email: string]: "pending" | "rejected" | "registered"
  }
  state:
    | "idle"
    | "preprocessing"
    | "registering"
    | "invalid"
    | "pending"
    | "rejected"
}

type OffboardStudentPopup = {
  programId: string
  classId: string
  studentId: string
  state: "idle" | "pending" | "rejected"
  errorMessage?: string
}

type ProgramTransferStudentForm = {
  selectedProgramId: string | undefined
  selectedClassId: string | undefined
  reason: ChangeProgramClassRequestChangeTypeEnum | undefined
  summary: string
}

type ProgramTransferStudentPopup = {
  studentId: string
  studentName: string
  programId: string
  form: ProgramTransferStudentForm
  loadingState: "idle" | "loading" | "rejected"
}

export type ProgramDetailsFormModel = {
  domain: ProgramDomain | undefined
  type: ProgramType | undefined
  language: string | undefined
  startDate: string | undefined
  durationInMonths: number | undefined
}

type CreateProgramPopup = {
  form: Omit<ProgramDetailsFormModel, "domain"> & {
    domains: ProgramDomain[] | undefined
  }
  state: "idle" | "pending" | "rejected"
  errorMessage?: string
}

type EditProgramPopup = {
  programId: string
}

type ClassTransferStudentForm = Omit<
  ProgramTransferStudentForm,
  "selectedProgramId"
>

type ClassTransferStudentPopup = {
  studentId: string
  studentName: string
  programId: string
  currentClassId: string
  form: ClassTransferStudentForm
  loadingState: "idle" | "loading" | "rejected"
}

type EditRegistrationLanguagePopup = {
  studentId: string
  studentName: string
  classId: string
  programId: string
}

type EditStudentForm = Pick<UserDto, "firstName" | "lastName" | "email">

type EditStudentDetailsPopup = {
  studentId: string
  form: EditStudentForm
}

type DeleteProgramPopup = {
  programId: string
  deleteRequestState: PendingDataState<boolean>
}

type EditStudentMentorPopup = {
  studentId: string
  studentName: string
  programId: string
  mentorId: string | undefined
}

type EditStudentCareerSuccessAdvisorPopup = {
  studentId: string
  studentName: string
  programId: string
  initialCareerSuccessAdvisorClientId: string | undefined
}

type PickPublishedSyllabusPopup = {
  isOpen: boolean
  withConfirmationPopup: boolean
  programId: string | undefined
  classIds: string[] | undefined
}

type ProgramStartSyllabusActionConfirmationPopup = {
  isOpen: boolean
  action: "replace" | "revert-to-default"
  decision: "confirm" | "cancel" | undefined
}

type SyllabusActionConfirmationPopup = {
  isOpen: boolean
  isLoading: boolean
  action:
    | {
        type: "connect"
        syllabusName: string
        item: "program" | "class" | "classes"
      }
    | {
        type: "disconnect"
        syllabusName: string
        classesCount: number
      }
    | {
        type: "revert"
        defaultSyllabusName: string
      }
  decision: "confirm" | "cancel" | undefined
}

type SuggestConnectClassesAfterDisconnectPopup = {
  isOpen: boolean
  programId: string | undefined
  classIds: string[]
}

type ManageProgramHomepagePopup = {
  homepageUrl: string | undefined
  classIds: string[]
}

type EditDatacampCredentialsPopup = {
  studentId: string
  studentName: string
}

type RegisterStudentToCourseClassPopup = {
  studentId: string
  firstName: string
  lastName: string
}

export interface ProgramsState {
  programs: ProgramDto[]
  loadingState: "idle" | "loading" | "failed"
  programIdToClasses: {
    [programId: string]: Class[]
  }
  createProgramPopup: CreateProgramPopup | undefined
  deleteProgramPopup: DeleteProgramPopup | undefined
  editProgramPopup: EditProgramPopup | undefined
  studentsPage: {
    search: string
    singleStudentOnboardPopup: SingleStudentOnboardPopup | undefined
    multipleStudentsOnboardPopup: MultipleStudentsOnboardPopup | undefined
    offboardStudentPopup: OffboardStudentPopup | undefined
    programTransferStudentPopup: ProgramTransferStudentPopup | undefined
    classTransferStudentPopup: ClassTransferStudentPopup | undefined
    editRegistrationLanguagePopup: EditRegistrationLanguagePopup | undefined
    editStudentMentorPopup: EditStudentMentorPopup | undefined
    editStudentDetailsPopup?: EditStudentDetailsPopup | undefined
    editStudentCareerSuccessAdvisorPopup:
      | EditStudentCareerSuccessAdvisorPopup
      | undefined
    editDatacampCredentialsPopup: EditDatacampCredentialsPopup | undefined
    registerStudentToCourseClassPopup:
      | RegisterStudentToCourseClassPopup
      | undefined
  }
  staffPage: {
    staff: ProgramStaffMember[]
    openAddStaffMemberPopup: boolean
    editStaffMemberPopup: ProgramStaffMember | undefined
    removeStaffFromProgram:
      | { userClientId: string; programId: string }
      | undefined
  }
  syllabusesPage: {
    pickPublishedSyllabusPopup: PickPublishedSyllabusPopup
    programStartSyllabusActionConfirmationPopup: ProgramStartSyllabusActionConfirmationPopup
    syllabusActionConfirmationPopup: SyllabusActionConfirmationPopup
    suggestConnectClassesAfterDisconnectPopup: SuggestConnectClassesAfterDisconnectPopup
  }
  manageHomepageUrlPopup: ManageProgramHomepagePopup | undefined
}

const initialState: ProgramsState = {
  programs: [],
  loadingState: "idle",
  programIdToClasses: {},
  createProgramPopup: undefined,
  deleteProgramPopup: undefined,
  editProgramPopup: undefined,
  studentsPage: {
    search: "",
    singleStudentOnboardPopup: undefined,
    multipleStudentsOnboardPopup: undefined,
    offboardStudentPopup: undefined,
    programTransferStudentPopup: undefined,
    classTransferStudentPopup: undefined,
    editRegistrationLanguagePopup: undefined,
    editStudentMentorPopup: undefined,
    editStudentCareerSuccessAdvisorPopup: undefined,
    editDatacampCredentialsPopup: undefined,
    registerStudentToCourseClassPopup: undefined,
  },
  manageHomepageUrlPopup: undefined,
  staffPage: {
    staff: [],
    openAddStaffMemberPopup: false,
    editStaffMemberPopup: undefined,
    removeStaffFromProgram: undefined,
  },
  syllabusesPage: {
    pickPublishedSyllabusPopup: {
      isOpen: false,
      withConfirmationPopup: true,
      programId: undefined,
      classIds: undefined,
    },
    programStartSyllabusActionConfirmationPopup: {
      isOpen: false,
      action: "replace",
      decision: undefined,
    },
    syllabusActionConfirmationPopup: {
      isOpen: false,
      isLoading: false,
      action: {
        type: "connect",
        syllabusName: "",
        item: "program",
      },
      decision: undefined,
    },
    suggestConnectClassesAfterDisconnectPopup: {
      classIds: [],
      isOpen: false,
      programId: undefined,
    },
  },
}

export const programSlice = createSlice({
  name: "program",
  initialState: initialState,
  reducers: {
    studentsSearchChanged: (state, action) => {
      state.studentsPage.search = action.payload
    },
    onboardStudentClassSelected: (
      state,
      action: PayloadAction<{
        classId: string | undefined
      }>,
    ) => {
      if (state.studentsPage.singleStudentOnboardPopup) {
        state.studentsPage.singleStudentOnboardPopup.classId =
          action.payload.classId
      }
      if (state.studentsPage.multipleStudentsOnboardPopup) {
        state.studentsPage.multipleStudentsOnboardPopup.classId =
          action.payload.classId
      }
    },
    onboardSingleStudentOpened: (
      state,
      action: PayloadAction<{
        classId?: string
      }>,
    ) => {
      state.studentsPage.singleStudentOnboardPopup = {
        classId: action.payload.classId,
        isClassEditable: action.payload.classId === undefined,
        form: {
          firstName: "",
          lastName: "",
          email: "",
        },
        state: "idle",
      }
    },
    onboardMultipleStudentsOpened: (
      state,
      action: PayloadAction<{
        classId?: string
      }>,
    ) => {
      state.studentsPage.multipleStudentsOnboardPopup = {
        classId: action.payload.classId,
        isClassEditable: action.payload.classId === undefined,
        students: [],
        state: "idle",
        statuses: {},
        registrationState: {},
      }
    },
    onboardPopupClosed: (state) => {
      state.studentsPage.singleStudentOnboardPopup = undefined
      state.studentsPage.multipleStudentsOnboardPopup = undefined
    },
    offboardStudentPopupOpened: (
      state,
      action: PayloadAction<{
        programId: string
        classId: string | undefined
        studentId: string
      }>,
    ) => {
      const classId =
        action.payload.classId ??
        state.programIdToClasses[action.payload.programId].find((c) =>
          c.students.some((s) => s.id === action.payload.studentId),
        )?.id
      if (!classId) {
        return
      }
      state.studentsPage.offboardStudentPopup = {
        programId: action.payload.programId,
        classId: classId,
        studentId: action.payload.studentId,
        state: "idle",
      }
    },
    offboardStudentPopupClosed: (state) => {
      state.studentsPage.offboardStudentPopup = undefined
    },
    backToIdleRequested: (state) => {
      if (!state.studentsPage.multipleStudentsOnboardPopup) {
        return
      }
      state.studentsPage.multipleStudentsOnboardPopup.state = "idle"
      state.studentsPage.multipleStudentsOnboardPopup.students = []
      state.studentsPage.multipleStudentsOnboardPopup.statuses = {}
    },
    onboardSingleStudentFormUpdated: <
      K extends keyof Omit<UserDto, "id" | "roles">,
    >(
      state: ProgramsState,
      action: PayloadAction<{ key: K; value: UserDto[K] }>,
    ) => {
      if (!state.studentsPage.singleStudentOnboardPopup) {
        return
      }
      state.studentsPage.singleStudentOnboardPopup.form[action.payload.key] =
        action.payload.value
    },
    onboardMultipleStudentsUpdated(state, action: PayloadAction<UserDto[]>) {
      if (!state.studentsPage.multipleStudentsOnboardPopup) {
        return
      }
      state.studentsPage.multipleStudentsOnboardPopup.students = action.payload
    },
    programTransferStudentPopupOpened: (
      state,
      action: PayloadAction<
        Pick<
          ProgramTransferStudentPopup,
          "studentId" | "programId" | "studentName"
        >
      >,
    ) => {
      state.studentsPage.programTransferStudentPopup = {
        ...action.payload,
        loadingState: "idle",
        form: {
          selectedProgramId: undefined,
          selectedClassId: undefined,
          reason: undefined,
          summary: "",
        },
      }
    },
    programTransferStudentPopupClosed: (state) => {
      state.studentsPage.programTransferStudentPopup = undefined
    },
    programTransferStudentUpdated: <K extends keyof ProgramTransferStudentForm>(
      state: ProgramsState,
      action: PayloadAction<{
        key: K
        value: ProgramTransferStudentForm[K]
      }>,
    ) => {
      if (!state.studentsPage.programTransferStudentPopup) {
        return
      }
      state.studentsPage.programTransferStudentPopup.form[action.payload.key] =
        action.payload.value
    },
    createProgramPopupOpened(state) {
      state.createProgramPopup = {
        form: {
          domains: undefined,
          type: undefined,
          language: undefined,
          startDate: undefined,
          durationInMonths: undefined,
        },
        state: "idle",
      }
    },
    createProgramPopupClosed(state) {
      state.createProgramPopup = undefined
    },
    createProgramPopupFormUpdated(
      state: ProgramsState,
      action: { payload: Partial<ProgramDetailsFormModel> },
    ) {
      if (!state.createProgramPopup) {
        return
      }
      const updatedProperties = action.payload
      const updatedForm = {
        ...state.createProgramPopup.form,
        ...updatedProperties,
      }
      state.createProgramPopup.form = updatedForm
    },
    editProgramPopupOpened(
      state,
      action: PayloadAction<{ programId: string }>,
    ) {
      state.editProgramPopup = {
        programId: action.payload.programId,
      }
    },
    editProgramPopupClosed(state) {
      state.editProgramPopup = undefined
    },
    deleteProgramPopupOpened(
      state,
      action: PayloadAction<{ programId: string }>,
    ) {
      state.deleteProgramPopup = {
        programId: action.payload.programId,
        deleteRequestState: { status: "idle" },
      }
    },
    deleteProgramPopupClosed(state) {
      state.deleteProgramPopup = undefined
    },
    classTransferStudentPopupOpened: (
      state,
      action: PayloadAction<
        Pick<
          ClassTransferStudentPopup,
          "studentId" | "programId" | "studentName" | "currentClassId"
        >
      >,
    ) => {
      state.studentsPage.classTransferStudentPopup = {
        ...action.payload,
        loadingState: "idle",
        form: {
          selectedClassId: undefined,
          reason: undefined,
          summary: "",
        },
      }
    },
    classTransferStudentPopupClosed: (state) => {
      state.studentsPage.classTransferStudentPopup = undefined
    },
    classTransferStudentUpdated: <K extends keyof ClassTransferStudentForm>(
      state: ProgramsState,
      action: PayloadAction<{
        key: K
        value: ClassTransferStudentForm[K]
      }>,
    ) => {
      if (!state.studentsPage.classTransferStudentPopup) {
        return
      }
      state.studentsPage.classTransferStudentPopup.form[action.payload.key] =
        action.payload.value
    },
    editRegistrationLanguagePopupOpened: (
      state,
      action: PayloadAction<{
        studentId: string
        studentName: string
        classId: string
        programId: string
      }>,
    ) => {
      state.studentsPage.editRegistrationLanguagePopup = action.payload
    },
    editRegistrationLanguagePopupClosed: (state) => {
      state.studentsPage.editRegistrationLanguagePopup = undefined
    },
    editStudentDetailsPopupOpened: (
      state,
      action: PayloadAction<{
        studentId: string
        firstName: string
        lastName: string
        email: string
      }>,
    ) => {
      state.studentsPage.editStudentDetailsPopup = {
        studentId: action.payload.studentId,
        form: {
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
          email: action.payload.email,
        },
      }
    },
    editStudentDetailsPopupClosed: (state) => {
      state.studentsPage.editStudentDetailsPopup = undefined
    },
    editStudentMentorPopupOpened: (
      state,
      action: PayloadAction<{
        studentId: string
        studentName: string
        programId: string
        mentorId: string | undefined
      }>,
    ) => {
      state.studentsPage.editStudentMentorPopup = action.payload
    },
    editStudentMentorPopupClosed: (state) => {
      state.studentsPage.editStudentMentorPopup = undefined
    },
    editStudentCareerSuccessAdvisorPopupOpened: (
      state,
      action: PayloadAction<{
        studentId: string
        studentName: string
        programId: string
        initialCareerSuccessAdvisorClientId: string | undefined
      }>,
    ) => {
      state.studentsPage.editStudentCareerSuccessAdvisorPopup = action.payload
    },
    editStudentCareerSuccessAdvisorPopupClosed: (state) => {
      state.studentsPage.editStudentCareerSuccessAdvisorPopup = undefined
    },
    addStaffMemberPopupOpened: (state) => {
      state.staffPage.openAddStaffMemberPopup = true
    },
    addStaffMemberPopupClosed: (state) => {
      state.staffPage.openAddStaffMemberPopup = false
    },
    editStaffMemberPopupOpened: (
      state,
      action: PayloadAction<ProgramStaffMember>,
    ) => {
      state.staffPage.editStaffMemberPopup = action.payload
    },
    editStaffMemberPopupClosed: (state) => {
      state.staffPage.editStaffMemberPopup = undefined
    },
    removeStaffFromProgramPopupOpened: (
      state,
      action: PayloadAction<{ userClientId: string; programId: string }>,
    ) => {
      state.staffPage.removeStaffFromProgram = action.payload
    },
    removeStaffFromProgramPopupClosed: (state) => {
      state.staffPage.removeStaffFromProgram = undefined
    },
    pickPublishedSyllabusPopupOpened: (
      state,
      action: PayloadAction<{
        withConfirmationPopup?: boolean
        programId: string
        classIds?: string[]
      }>,
    ) => {
      const { withConfirmationPopup, programId, classIds } = action.payload
      state.syllabusesPage.pickPublishedSyllabusPopup.isOpen = true
      state.syllabusesPage.pickPublishedSyllabusPopup.programId = programId
      state.syllabusesPage.pickPublishedSyllabusPopup.classIds = classIds
      state.syllabusesPage.pickPublishedSyllabusPopup.withConfirmationPopup =
        withConfirmationPopup ??
        state.syllabusesPage.pickPublishedSyllabusPopup.withConfirmationPopup
    },
    pickPublishedSyllabusPopupClosed: (state) => {
      state.syllabusesPage.pickPublishedSyllabusPopup.isOpen = false
      state.syllabusesPage.pickPublishedSyllabusPopup.withConfirmationPopup =
        true
      state.syllabusesPage.pickPublishedSyllabusPopup.programId = undefined
      state.syllabusesPage.pickPublishedSyllabusPopup.classIds = undefined
    },
    programStartSyllabusActionConfirmationPopupUpdate: (
      state,
      action: PayloadAction<
        Partial<ProgramStartSyllabusActionConfirmationPopup>
      >,
    ) => {
      state.syllabusesPage.programStartSyllabusActionConfirmationPopup = {
        ...state.syllabusesPage.programStartSyllabusActionConfirmationPopup,
        ...action.payload,
      }
    },
    syllabusActionConfirmationPopupUpdate: (
      state,
      action: PayloadAction<Partial<SyllabusActionConfirmationPopup>>,
    ) => {
      state.syllabusesPage.syllabusActionConfirmationPopup = {
        ...state.syllabusesPage.syllabusActionConfirmationPopup,
        ...action.payload,
      }
    },
    suggestConnectClassesAfterDisconnectPopupUpdate: (
      state,
      action: PayloadAction<Partial<SuggestConnectClassesAfterDisconnectPopup>>,
    ) => {
      state.syllabusesPage.suggestConnectClassesAfterDisconnectPopup = {
        ...state.syllabusesPage.suggestConnectClassesAfterDisconnectPopup,
        ...action.payload,
      }
    },
    openManageHomepageUrlPopup: (
      state,
      action: PayloadAction<ManageProgramHomepagePopup>,
    ) => {
      state.manageHomepageUrlPopup = action.payload
    },
    closeManageHomepageUrlPopup: (state) => {
      state.manageHomepageUrlPopup = undefined
    },
    openEditDatacampCredentialsPopup: (
      state,
      action: PayloadAction<EditDatacampCredentialsPopup>,
    ) => {
      state.studentsPage.editDatacampCredentialsPopup = action.payload
    },
    closeEditDatacampCredentialsPopup: (state) => {
      state.studentsPage.editDatacampCredentialsPopup = undefined
    },
    registerStudentToCoursePopupUpdated: (
      state,
      action: PayloadAction<RegisterStudentToCourseClassPopup | undefined>,
    ) => {
      state.studentsPage.registerStudentToCourseClassPopup = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPrograms.pending, (state) => {
      state.loadingState = "loading"
    })
    builder.addCase(fetchPrograms.fulfilled, (state, action) => {
      state.programs = action.payload
      state.loadingState = "idle"
    })
    builder.addCase(fetchPrograms.rejected, (state) => {
      state.loadingState = "failed"
    })
    builder.addCase(fetchClasses.fulfilled, (state, action) => {
      state.programIdToClasses[action.meta.arg.programId] = action.payload
    })
    builder.addCase(registerStudent.pending, (state, action) => {
      if (state.studentsPage.singleStudentOnboardPopup) {
        state.studentsPage.singleStudentOnboardPopup.state = "pending"
      }

      if (state.studentsPage.multipleStudentsOnboardPopup) {
        state.studentsPage.multipleStudentsOnboardPopup.registrationState[
          action.meta.arg.student.email
        ] = "pending"
      }
    })
    builder.addCase(registerStudent.rejected, (state, action) => {
      if (state.studentsPage.singleStudentOnboardPopup) {
        state.studentsPage.singleStudentOnboardPopup.state = "rejected"
        if (typeof action.error.message === "string") {
          state.studentsPage.singleStudentOnboardPopup.errorMessage =
            action.error.message
        }
      }

      if (state.studentsPage.multipleStudentsOnboardPopup) {
        state.studentsPage.multipleStudentsOnboardPopup.registrationState[
          action.meta.arg.student.email
        ] = "rejected"
      }
    })
    builder.addCase(registerStudent.fulfilled, (state, action) => {
      if (state.studentsPage.singleStudentOnboardPopup) {
        state.studentsPage.singleStudentOnboardPopup = undefined
      }

      if (state.studentsPage.multipleStudentsOnboardPopup) {
        state.studentsPage.multipleStudentsOnboardPopup.registrationState[
          action.meta.arg.student.email
        ] = "registered"

        const allDone = Object.values(
          state.studentsPage.multipleStudentsOnboardPopup.registrationState,
        ).every((status) => status !== "pending")
        if (allDone) {
          state.studentsPage.multipleStudentsOnboardPopup = undefined
        }
      }
    })
    builder.addCase(
      preprocessMultipleStudentsRegistration.pending,
      (state, action) => {
        if (!state.studentsPage.multipleStudentsOnboardPopup) {
          return
        }
        state.studentsPage.multipleStudentsOnboardPopup.state = "preprocessing"
        state.studentsPage.multipleStudentsOnboardPopup.students =
          action.meta.arg.students
      },
    )
    builder.addCase(
      preprocessSingleStudentRegistration.fulfilled,
      (state, action) => {
        if (!state.studentsPage.multipleStudentsOnboardPopup) {
          return
        }
        switch (action.payload.result) {
          case UserRegistrationStatusResponseResultEnum.NotExist:
          case UserRegistrationStatusResponseResultEnum.NotRegistered: {
            state.studentsPage.multipleStudentsOnboardPopup.statuses[
              action.meta.arg.student.email
            ] = "ready"
            break
          }
          case UserRegistrationStatusResponseResultEnum.Registered: {
            state.studentsPage.multipleStudentsOnboardPopup.statuses[
              action.meta.arg.student.email
            ] =
              action.payload.classId === action.meta.arg.classId
                ? "registered"
                : "registeredElsewhere"
            break
          }
        }
      },
    )
    builder.addCase(registerMultipleStudents.pending, (state) => {
      if (state.studentsPage.multipleStudentsOnboardPopup) {
        state.studentsPage.multipleStudentsOnboardPopup.state = "registering"
      }
    })
    builder.addCase(unregisterStudent.pending, (state) => {
      if (state.studentsPage.offboardStudentPopup) {
        state.studentsPage.offboardStudentPopup.state = "pending"
      }
    })
    builder.addCase(unregisterStudent.rejected, (state, action) => {
      if (state.studentsPage.offboardStudentPopup) {
        state.studentsPage.offboardStudentPopup.state = "rejected"
      }
    })
    builder.addCase(unregisterStudent.fulfilled, (state, action) => {
      if (state.studentsPage.offboardStudentPopup) {
        state.studentsPage.offboardStudentPopup = undefined

        const { programId, classId, studentId } = action.meta.arg

        const programClass = state.programIdToClasses[programId].find(
          (c) => c.id === classId,
        )
        if (!programClass) {
          return
        }

        state.programIdToClasses[programId] = state.programIdToClasses[
          programId
        ]?.map((c) =>
          c.id === classId ? classWithoutStudentFromClass(c, studentId) : c,
        )
      }
    })
    builder.addCase(changeStudentProgram.pending, (state, action) => {
      if (state.studentsPage.programTransferStudentPopup) {
        state.studentsPage.programTransferStudentPopup.loadingState = "loading"
      }
    })
    builder.addCase(changeStudentProgram.rejected, (state, action) => {
      if (state.studentsPage.programTransferStudentPopup) {
        state.studentsPage.programTransferStudentPopup.loadingState = "rejected"
      }
    })
    builder.addCase(changeStudentProgram.fulfilled, (state, action) => {
      if (state.studentsPage.programTransferStudentPopup) {
        state.studentsPage.programTransferStudentPopup = undefined
      }
    })
    builder.addCase(createLegacyProgram.pending, (state) => {
      if (state.createProgramPopup) {
        state.createProgramPopup.state = "pending"
      }
    })
    builder.addCase(createLegacyProgram.rejected, (state, action) => {
      if (state.createProgramPopup) {
        state.createProgramPopup.state = "rejected"
        if (typeof action.error.message === "string") {
          state.createProgramPopup.errorMessage = action.error.message
        }
      }
    })
    builder.addCase(createLegacyProgram.fulfilled, (state) => {
      state.createProgramPopup = undefined
    })
    builder.addCase(changeStudentClass.pending, (state, action) => {
      if (state.studentsPage.classTransferStudentPopup) {
        state.studentsPage.classTransferStudentPopup.loadingState = "loading"
      }
    })
    builder.addCase(changeStudentClass.rejected, (state, action) => {
      if (state.studentsPage.classTransferStudentPopup) {
        state.studentsPage.classTransferStudentPopup.loadingState = "rejected"
      }
    })
    builder.addCase(changeStudentClass.fulfilled, (state, action) => {
      if (state.studentsPage.classTransferStudentPopup) {
        state.studentsPage.classTransferStudentPopup = undefined
      }
    })
    builder.addCase(deleteProgramConfirmed.pending, (state, action) => {
      if (state.deleteProgramPopup) {
        state.deleteProgramPopup.deleteRequestState = { status: "pending" }
      }
    })
    builder.addCase(deleteProgramConfirmed.rejected, (state, action) => {
      if (state.deleteProgramPopup) {
        state.deleteProgramPopup.deleteRequestState = {
          status: "error",
          error: action.error.message,
        }
      }
    })
    builder.addCase(deleteProgramConfirmed.fulfilled, (state) => {
      state.deleteProgramPopup = undefined
    })
    builder.addCase(fetchProgramStaff.fulfilled, (state, action) => {
      state.staffPage.staff = action.payload
    })
    builder.addCase(addProgramStaffMemberPopupAccepted.fulfilled, (state) => {
      state.staffPage.openAddStaffMemberPopup = false
    })
    builder.addCase(editProgramStaffMemberPopupAccepted.fulfilled, (state) => {
      state.staffPage.editStaffMemberPopup = undefined
    })
    builder.addCase(removeStaffFromProgramPopupClosed, (state) => {
      state.staffPage.removeStaffFromProgram = undefined
    })
  },
})

export const {
  studentsSearchChanged,
  onboardSingleStudentOpened,
  onboardStudentClassSelected,
  onboardPopupClosed,
  backToIdleRequested,
  onboardSingleStudentFormUpdated,
  onboardMultipleStudentsOpened,
  offboardStudentPopupOpened,
  offboardStudentPopupClosed,
  createProgramPopupOpened,
  createProgramPopupClosed,
  createProgramPopupFormUpdated,
  editProgramPopupOpened,
  editProgramPopupClosed,
  deleteProgramPopupOpened,
  deleteProgramPopupClosed,
  programTransferStudentPopupOpened,
  programTransferStudentPopupClosed,
  programTransferStudentUpdated,
  classTransferStudentPopupOpened,
  classTransferStudentPopupClosed,
  editRegistrationLanguagePopupOpened,
  editRegistrationLanguagePopupClosed,
  classTransferStudentUpdated,
  editStudentDetailsPopupOpened,
  editStudentDetailsPopupClosed,
  editStudentMentorPopupOpened,
  editStudentMentorPopupClosed,
  editStudentCareerSuccessAdvisorPopupOpened,
  editStudentCareerSuccessAdvisorPopupClosed,
  addStaffMemberPopupOpened,
  addStaffMemberPopupClosed,
  editStaffMemberPopupOpened,
  editStaffMemberPopupClosed,
  openManageHomepageUrlPopup,
  closeManageHomepageUrlPopup,
  removeStaffFromProgramPopupOpened,
  removeStaffFromProgramPopupClosed,
  pickPublishedSyllabusPopupClosed,
  pickPublishedSyllabusPopupOpened,
  programStartSyllabusActionConfirmationPopupUpdate,
  syllabusActionConfirmationPopupUpdate,
  suggestConnectClassesAfterDisconnectPopupUpdate,
  openEditDatacampCredentialsPopup,
  closeEditDatacampCredentialsPopup,
  registerStudentToCoursePopupUpdated: registerStudentToCourseUpdated,
} = programSlice.actions

function classWithoutStudentFromClass(
  programClass: Class,
  studentId: string,
): Class {
  return {
    ...programClass,
    students: programClass.students.filter((s) => s.id !== studentId),
  }
}

export default programSlice.reducer
