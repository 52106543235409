import { Chip } from "@mui/material"

export function ProgramStatusChip(props: {
  startDate: Date
  durationInMonths: number
}) {
  const { startDate, durationInMonths } = props
  const label = statusForProgram(startDate, durationInMonths)
  const color = statusToColor(label)
  return <Chip label={label} size="small" variant="pastel" color={color} />
}

export function statusForProgram(startDate: Date, durationInMonths: number) {
  const endDate = new Date(startDate)
  endDate.setMonth(endDate.getMonth() + durationInMonths)
  const now = new Date()
  if (now < startDate) {
    return "Upcoming"
  }
  if (now > endDate) {
    return "Ended"
  }
  return "Active"
}

function statusToColor(status: "Upcoming" | "Ended" | "Active") {
  switch (status) {
    case "Upcoming":
      return "info"
    case "Active":
      return "success"
    case "Ended":
      return "default"
  }
}
