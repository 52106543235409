import { CourseLanguage } from "@clients/courseInstanceClient"
import { flagForLanguage, languageShortName } from "./language"
import { TermDto } from "@clients/termClient"
import { TermDto as TrackTermDto } from "@masterschool/course-builder-api"

export const courseClassDisplayName = (
  className: string,
  language: CourseLanguage | string | undefined,
): string => {
  const languageSuffix = language
    ? ` ${languageShortName(language)} ${flagForLanguage(language)}`
    : ""
  const nameWithLanguage = `${className} ${languageSuffix}`

  return nameWithLanguage
}

export function calculateCourseInstanceIdentifiers(
  trackTerms: TrackTermDto[],
  terms: TermDto[],
): { courseId: string; termId: string; majorVersion: number | undefined }[] {
  const courseInstanceIdentifiers: {
    courseId: string
    termId: string
    majorVersion: number | undefined
  }[] = []

  const minimumTermLength = Math.min(terms.length, trackTerms.length)
  for (let i = 0; i < minimumTermLength; i++) {
    const term = terms[i]
    const trackTerm = trackTerms[i]
    for (const courseDescriptor of trackTerm.courseDescriptors) {
      courseInstanceIdentifiers.push({
        courseId: courseDescriptor.courseId,
        termId: term.id,
        majorVersion: undefined,
      })
    }
  }
  return courseInstanceIdentifiers
}
