import { useAppSelector } from "@app/hooks"
import { CourseInstanceClient } from "@clients/courseInstanceClient"
import { selectLatestPublishedCoursesByIds } from "@features/coursesMenu/coursesSelectors"
import { CourseDescriptorDto } from "@masterschool/course-builder-api"
import { Box, Typography } from "@mui/material"
import { DataGridPro } from "@mui/x-data-grid-pro"
import { useState, useEffect } from "react"

import { ProgramsTableToolbar } from "../programs/menu/nextGenProgramsMenuTable"
import appTheme from "../theme/appTheme"
import { useNavigate } from "react-router-dom"
import {
  getCourseIdColumn,
  getNameColumn,
  getSchoolColumn,
} from "../main/courses-table/course-table/coursesColumns"

export const CourseInstanceManagerMainPage = () => {
  const [coursesIds, setCoursesIds] = useState<string[]>([])

  useEffect(() => {
    CourseInstanceClient.listCoursesHavingInstances().then((coursesIds) =>
      setCoursesIds(coursesIds),
    )
  }, [])

  const courseDescriptors = useAppSelector((state) =>
    selectLatestPublishedCoursesByIds(state, coursesIds),
  )

  return (
    <Box
      width="100%"
      display="flex"
      justifyContent="center"
      px="40px"
      height="100%"
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: "1240px",
          overflow: "scroll",
        }}
      >
        <Typography variant="h4" sx={{ mt: "80px" }}>
          {"Courses"}
        </Typography>
        <Typography variant="body2" sx={{ pt: "8px", pb: "16px" }}>
          {"Review and manage courses associated with at least one program"}
        </Typography>
        <CourseManagerTable courses={courseDescriptors} />
      </Box>
    </Box>
  )
}

const CourseManagerTable = (props: { courses: CourseDescriptorDto[] }) => {
  const { courses } = props
  const navigate = useNavigate()
  const onClickCourse = (courseId: string) => {
    navigate(`/course-manager/course/${courseId}`)
  }
  return (
    <Box
      sx={{
        width: "100%",
        maxHeight: "100%",
        height: "100%",
        pb: "24px",
      }}
    >
      <DataGridPro
        rows={courses}
        columns={getCourseManagerTableColumns()}
        disableColumnMenu
        slots={{ toolbar: ProgramsTableToolbar }}
        onRowClick={(params) => onClickCourse(params.row.id)}
        disableColumnReorder
        hideFooter
        rowHeight={64}
        getRowId={(row) => row.id}
        sx={{
          border: "none",
          "& .MuiDataGrid-row": {
            backgroundColor: "white",
            "&:hover": {
              cursor: "pointer",
            },
          },
          "& .MuiDataGrid-row:hover": {
            backgroundColor: appTheme.palette.action.hover,
          },
          "& .MuiDataGrid-cell": {
            p: "0px 0px 0px 16px !important",
          },
          "& .MuiDataGrid-cell:focus": {
            outline: "none",
          },
          "& .MuiDataGrid-cell:focus-within": {
            outline: "none",
          },
          "& .MuiDataGrid-columnHeader:focus": {
            outline: "none !important",
          },
          "& .MuiDataGrid-columnHeader": {
            p: "0px 0px 0px 16px !important",
          },
          "& .MuiDataGrid-columnHeader:focus-within": {
            outline: "none !important",
          },
          "& .MuiDataGrid-footerContainer": {
            display: "none",
          },
          "& .MuiDataGrid-columnSeparator": {
            display: "none",
          },
        }}
      />
    </Box>
  )
}

const getCourseManagerTableColumns = () => {
  return [getNameColumn(), getCourseIdColumn(), getSchoolColumn()]
}
