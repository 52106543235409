import { Box, CircularProgress, Typography } from "@mui/material"
import { DataGridPro, GridColDef } from "@mui/x-data-grid-pro"
import appTheme from "../../theme/appTheme"
import { useEffect, useMemo, useState } from "react"
import { CampusUserDto, UsersClient } from "@clients/usersClient"
import { ProgramsTableToolbar } from "../../programs/menu/nextGenProgramsMenuTable"
import { CourseClassDto } from "@clients/courseInstanceClient"
import { courseClassDisplayName } from "@utils/courseInstanceUtils"
import { CourseInstanceStudentsActionMenu } from "./menuActions"
import { useAppSelector } from "@app/hooks"
import { selectLoggedInUser } from "@features/login/loginSelectors"
import { selectCourseInstanceClasses } from "@features/courseInstance/courseInstanceSelectors"

export const CourseInstanceStudents = ({
  courseInstanceId,
}: {
  courseInstanceId: string | undefined
}) => {
  const user = useAppSelector(selectLoggedInUser)
  const courseClasses = useAppSelector((state) =>
    selectCourseInstanceClasses(state, courseInstanceId),
  )
  const registeredStudentsUserClientIds = useMemo(
    () =>
      courseClasses
        .map((c) => c.registeredStudentIds)
        .flat()
        .msCompact(),
    [courseClasses],
  )
  const { studentUsers, loading } = useGetStudents(
    registeredStudentsUserClientIds,
  )
  const enrichedStudentUsers = useMemo(
    () => enrichStudentUser(studentUsers, courseClasses, user?.clientId || ""),
    [studentUsers, courseClasses, user?.clientId],
  )

  if (loading || !user) {
    return <CircularProgress color={"secondary"} />
  }

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        px: "40px",
        py: "32px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Typography
        variant="h5"
        pb="24px"
      >{`Students (${studentUsers.length})`}</Typography>
      <StudentsTable students={enrichedStudentUsers} />
    </Box>
  )
}

type CourseStudentEnriched = CampusUserDto & {
  classDisplayName: string
  courseClassId: string
  courseInstanceId: string
  operatingUserId: string
}

const enrichStudentUser = (
  students: CampusUserDto[],
  courseClasses: CourseClassDto[],
  operatingUserId: string,
): CourseStudentEnriched[] => {
  return courseClasses.flatMap((courseClass) => {
    const classDisplayName = courseClassDisplayName(
      courseClass.name,
      courseClass.language,
    )
    const registeredStudentIds = courseClass.registeredStudentIds || []
    return students
      .filter((student) => registeredStudentIds.includes(student.clientId))
      .map((student) => ({
        ...student,
        classDisplayName,
        courseClassId: courseClass.id,
        courseInstanceId: courseClass.courseInstanceId,
        operatingUserId,
      }))
  })
}

const StudentsTable = ({ students }: { students: CourseStudentEnriched[] }) => {
  const studentIDs = useMemo(() => students.map((s) => s.clientId), [students])
  const duplicateStudentsIds = useMemo(
    () => findDuplicates(studentIDs),
    [studentIDs],
  )

  const getStudentsColumns = () => {
    return [
      getStudentNameColumn(),
      getStudentEmailColumn(),
      getStudentClassColumn(),
      getStudentActionsColumn(),
    ]
  }

  return (
    <Box
      sx={{
        width: "100%",
        maxHeight: "100%",
        overflow: "hidden",
        pb: "32px",
        bgcolor: appTheme.palette.eTypes.sand15,
      }}
    >
      <DataGridPro
        rows={students}
        columns={getStudentsColumns()}
        disableColumnMenu
        slots={{ toolbar: ProgramsTableToolbar }}
        disableColumnReorder
        hideFooter
        rowHeight={78}
        getRowId={(row) => `${row.clientId}-${row.courseClassId}`}
        initialState={{
          sorting: {
            sortModel: [
              {
                field: "email",
                sort: "asc",
              },
            ],
          },
        }}
        getRowClassName={(params) =>
          duplicateStudentsIds.includes(params.row.clientId)
            ? "student-with-multiple-classes"
            : ""
        }
        sx={{
          border: "none",
          "& .MuiDataGrid-row": {
            backgroundColor: "white",
            "&:hover": {
              cursor: "pointer",
            },
          },
          "& .student-with-multiple-classes": {
            backgroundColor: appTheme.palette.error.alert?.background,
            "&:hover": {
              backgroundColor: appTheme.palette.error.containedHoverBackground,
            },
          },
          "& .MuiDataGrid-row:hover": {
            backgroundColor: appTheme.palette.action.hover,
          },
          "& .MuiDataGrid-cell": {
            p: "0px 0px 0px 16px !important",
          },
          "& .MuiDataGrid-cell:focus": {
            outline: "none",
          },
          "& .MuiDataGrid-cell:focus-within": {
            outline: "none",
          },
          "& .MuiDataGrid-columnHeader:focus": {
            outline: "none !important",
          },
          "& .MuiDataGrid-columnHeader": {
            p: "0px 0px 0px 16px !important",
          },
          "& .MuiDataGrid-columnHeader:focus-within": {
            outline: "none !important",
          },
          "& .MuiDataGrid-footerContainer": {
            display: "none",
          },
          "& .MuiDataGrid-columnSeparator": {
            display: "none",
          },
        }}
      />
    </Box>
  )
}

const getStudentNameColumn: () => GridColDef<CourseStudentEnriched> = () => {
  return {
    field: "name",
    headerName: "Name",
    flex: 1,
    renderCell: (params) => {
      const fullName = `${params.row.firstName} ${params.row.lastName}`
      return (
        <Box alignContent="center" height="100%">
          <Typography>{fullName}</Typography>
        </Box>
      )
    },
    sortComparator: (name1: string, name2: string) =>
      name1?.localeCompare(name2),
  }
}
const getStudentEmailColumn: () => GridColDef<CourseStudentEnriched> = () => {
  return {
    field: "email",
    headerName: "Email",
    flex: 1,
    renderCell: (params) => {
      return (
        <Box alignContent="center" height="100%">
          <Typography>{params.row.email}</Typography>
        </Box>
      )
    },
    sortComparator: (name1: string, name2: string) =>
      name1?.localeCompare(name2),
  }
}

const getStudentClassColumn: () => GridColDef<CourseStudentEnriched> = () => {
  return {
    field: "class",
    headerName: "Class",
    flex: 1,
    renderCell: (params) => {
      return (
        <Box alignContent="center" height="100%">
          <Typography>{params.row.classDisplayName}</Typography>
        </Box>
      )
    },
    sortComparator: (name1: string, name2: string) =>
      name1.localeCompare(name2),
  }
}

const getStudentActionsColumn: () => GridColDef<CourseStudentEnriched> = () => {
  return {
    field: "actions",
    headerName: "",
    type: "actions",
    width: 48,
    sortable: false,
    renderCell: (params) => (
      <CourseInstanceStudentsActionMenu
        courseInstanceId={params.row.courseInstanceId}
        courseClassId={params.row.courseClassId}
        studentId={params.row.clientId}
        operatingUserClientId={params.row.operatingUserId}
      />
    ),
  }
}

const useGetStudents = (userClientIds: string[]) => {
  const [studentUsers, setStudentUsers] = useState<CampusUserDto[]>([])
  const [loading, setLoading] = useState(true)
  useEffect(() => {
    setLoading(true)
    if (userClientIds.length === 0) {
      setLoading(false)
      return
    }

    UsersClient.getUsersByClientId(userClientIds).then((users) => {
      setLoading(false)
      setStudentUsers(users)
    })
  }, [userClientIds])

  return { studentUsers, loading }
}

function findDuplicates(list: string[]) {
  const countPerItem = list.reduce((countsMap, currentItem) => {
    countsMap[currentItem] = (countsMap[currentItem] || 0) + 1
    return countsMap
  }, {} as Record<string, number>)

  return Object.entries(countPerItem)
    .filter(([_, count]) => count > 1)
    .map(([value]) => value)
}
