import { RootState } from "@app/store"
import { TrackDto } from "@masterschool/course-builder-api"
import { createSelector } from "@reduxjs/toolkit"

const selectTrackState = (state: RootState) => state.track

export const selectAllTracks = createSelector(
  [selectTrackState],
  (trackState) => {
    return Object.values(trackState.trackRepresentativeById)
  },
)

export const selectTrackById = createSelector(
  [(_state, id: string) => id, (state, _id: string) => selectTrackState(state)],
  (id, trackState): TrackDto | undefined => {
    return trackState.trackRepresentativeById[id]
  },
)

export const selectTrackLoadingStates = (state: RootState) =>
  state.track.loadingState
