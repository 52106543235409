import { createAppAsyncThunk } from "@app/createAppAsyncThunk"
import {
  CourseClassDto,
  CourseInstanceClient,
  CourseInstanceDto,
} from "@clients/courseInstanceClient"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import PendingDataState from "@utils/pendingDataState"

export type CourseInstanceState = {
  instances: PendingDataState<CourseInstanceDto[]>
  courseClasses: PendingDataState<CourseClassDto[]>
}

const getInitialState = (): CourseInstanceState => ({
  instances: { status: "idle" },
  courseClasses: { status: "idle" },
})
export const courseInstancesSlice = createSlice({
  name: "courseInstances",
  initialState: getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(
        fetchCourseInstances.fulfilled,
        (state, action: PayloadAction<CourseInstanceDto[]>) => {
          state.instances = {
            status: "success",
            data: action.payload,
          }
        },
      )
      .addCase(fetchCourseInstances.pending, (state) => {
        state.instances = { status: "pending" }
      })
      .addCase(fetchCourseInstances.rejected, (state) => {
        state.instances = { status: "error" }
      })
      .addCase(
        fetchCourseClasses.fulfilled,
        (state, action: PayloadAction<CourseClassDto[]>) => {
          state.courseClasses = {
            status: "success",
            data: action.payload,
          }
        },
      )
      .addCase(fetchCourseClasses.pending, (state) => {
        state.courseClasses = { status: "pending" }
      })
      .addCase(fetchCourseClasses.rejected, (state) => {
        state.courseClasses = { status: "error" }
      })
  },
})

export const fetchCourseInstances = createAppAsyncThunk(
  "courseInstances/fetch",
  async () => CourseInstanceClient.listAllCourseInstances(),
)

export const fetchCourseClasses = createAppAsyncThunk(
  "courseInstances/fetchClasses",
  async () => CourseInstanceClient.listAllCourseClasses(),
)

export default courseInstancesSlice.reducer
