import { useAppDispatch, useAppSelector } from "@app/hooks"
import { store } from "@app/store"
import { ProgramManualUnlockClient } from "@clients/ProramManualUnlockClient"
import { UsersClient } from "@clients/usersClient"
import OptionsButton2, { ItemModel } from "@cmp/buttons/optionsButton2"
import {
  classTransferStudentPopupOpened,
  editStudentDetailsPopupOpened,
  editStudentMentorPopupOpened,
  offboardStudentPopupOpened,
  programTransferStudentPopupOpened,
  editStudentCareerSuccessAdvisorPopupOpened,
  openEditDatacampCredentialsPopup,
  editRegistrationLanguagePopupOpened,
  registerStudentToCourseUpdated,
} from "@features/program/programSlice"
import { selectStudent } from "@features/program/programSliceSelectors"
import { showErrorSnackbar, showSuccessSnackbar } from "@features/ui/uiSlice"
import { SvgIcon } from "@mui/material"
import appIcons from "@utils/appIcons"

export const StudentActionsMenu = (props: {
  studentId: string
  studentName: string
  studentCurrentClass: string
  programId: string | undefined
  programType: "legacy" | "next-gen"
  classId: string | undefined
}) => {
  const {
    studentId,
    programId,
    classId,
    studentName,
    studentCurrentClass,
    programType,
  } = props
  const dispatch = useAppDispatch()
  const student = useAppSelector(selectStudent(programId, classId, studentId))
  if (!student) {
    return
  }
  const generateLinkForStudent = async () => {
    if (!student.email) {
      return
    }
    await UsersClient.generateImpersonationLink(student.email)
      .then((link) => {
        navigator.clipboard.writeText(link)
        store.dispatch(
          showSuccessSnackbar("Link copied. Open the URL in incognito mode."),
        )
      })
      .catch(() => {
        store.dispatch(
          showErrorSnackbar(
            "Failed to copy impersonation link, try again later.",
          ),
        )
        return undefined
      })
  }

  const legacyOnlyItems = [
    "transfer class",
    "assign CSA to student",
    "unlock next sprint",
  ]
  const nextGenOnlyItems = ["register to a course", "unlock next week"]
  const mentorRole = programType === "legacy" ? "mentor" : "ASM"

  const items: ItemModel[] = [
    {
      type: "menu-item",
      props: {
        key: "editStudentDetails",
        children: "Edit student",
        onClick: () => {
          if (!studentId) {
            return
          }
          dispatch(
            editStudentDetailsPopupOpened({
              studentId,
              firstName: student.firstName,
              lastName: student.lastName,
              email: student.email,
            }),
          )
        },
      },
    },
    {
      type: "menu-item",
      props: {
        key: "impersonate",
        children: "Copy impersonation link",
        onClick: generateLinkForStudent,
      },
    },
    {
      type: "menu-item",
      props: {
        key: "transfer program",
        children: "Transfer to a different program",
        onClick: () => {
          if (!studentId || !programId) {
            return
          }
          dispatch(
            programTransferStudentPopupOpened({
              studentId,
              programId,
              studentName: studentName,
            }),
          )
        },
      },
    },
    {
      type: "menu-item",
      props: {
        key: "transfer class",
        children: "Transfer to a different class",
        onClick: () => {
          if (!studentId || !programId) {
            return
          }
          dispatch(
            classTransferStudentPopupOpened({
              studentId,
              programId,
              studentName: studentName,
              currentClassId: studentCurrentClass,
            }),
          )
        },
      },
    },
    {
      type: "menu-item",
      props: {
        key: "change language",
        children: "Change registration language",
        onClick: () => {
          if (!studentId || !student.classId || !programId) {
            return
          }
          dispatch(
            editRegistrationLanguagePopupOpened({
              studentId,
              classId: student.classId,
              programId,
              studentName,
            }),
          )
        },
      },
    },
    {
      type: "menu-item",
      props: {
        key: "assign mentor to student",
        children: student.mentor
          ? `Change ${mentorRole}`
          : `Assign ${mentorRole}`,
        onClick: () => {
          if (!studentId || !programId) {
            return
          }
          dispatch(
            editStudentMentorPopupOpened({
              studentId,
              studentName: studentName,
              programId: programId,
              mentorId: student.mentor?.id,
            }),
          )
        },
      },
    },
    {
      type: "menu-item",
      props: {
        key: "assign CSA to student",
        children: student.careerSuccessAdvisor ? "Change CSA" : "Assign CSA",
        onClick: () => {
          if (!studentId || !programId) {
            return
          }
          dispatch(
            editStudentCareerSuccessAdvisorPopupOpened({
              studentId,
              studentName: studentName,
              programId: programId,
              initialCareerSuccessAdvisorClientId:
                student.careerSuccessAdvisor?.userClientId,
            }),
          )
        },
      },
    },
    {
      type: "menu-item",
      props: {
        key: "offboard",
        children: "Offboard student",
        onClick: () => {
          if (!programId) {
            return
          }
          dispatch(
            offboardStudentPopupOpened({
              programId: programId,
              studentId: studentId,
              classId: classId,
            }),
          )
        },
      },
    },
    {
      type: "menu-item",
      props: {
        key: "edit datacamp email",
        children: "Edit Datacamp email",
        onClick: () => {
          if (!studentId) {
            return
          }
          dispatch(
            openEditDatacampCredentialsPopup({
              studentId,
              studentName: `${student.firstName} ${student.lastName}`,
            }),
          )
        },
      },
    },
    {
      type: "menu-item",
      props: {
        key: "register to a course",
        children: "Register to a course",
        onClick: () => {
          dispatch(
            registerStudentToCourseUpdated({
              studentId,
              firstName: student.firstName,
              lastName: student.lastName,
            }),
          )
        },
      },
    },
    {
      type: "menu-item",
      props: {
        key: "unlock next week",
        children: "Unlock Next Week",
        onClick: () => {
          ProgramManualUnlockClient.unlockNextWeekForAllRunningCourses(
            studentId,
          )
        },
      },
    },
    {
      type: "menu-item",
      props: {
        key: "unlock next sprint",
        children: "Unlock Next Sprint",
        onClick: () => {
          ProgramManualUnlockClient.unlockNextSprint(studentId)
        },
      },
    },
  ]
    .filter(
      (item) =>
        programType === "legacy" || !legacyOnlyItems.includes(item.props.key),
    )
    .filter(
      (item) =>
        programType === "next-gen" ||
        !nextGenOnlyItems.includes(item.props.key),
    ) as ItemModel[]

  return (
    <OptionsButton2
      buttonModel={{
        type: "icon",
        props: {
          sx: {
            marginLeft: "auto",
            display: "flex",
          },
          size: "small",
          children: (
            <SvgIcon
              component={appIcons.dotsHorizontal}
              inheritViewBox
              sx={{ color: "primary.main" }}
            />
          ),
        },
      }}
      items={items}
    />
  )
}
