import { useAppSelector } from "@app/hooks"
import { CourseInstanceDto } from "@clients/courseInstanceClient"
import BackButton from "@cmp/buttons/backButton"
import { selectCourseDisplayName } from "@features/coursesMenu/coursesSelectors"
import { Stack, Box, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"
import appTheme from "../theme/appTheme"
import { formatDateAsYearAndLongMonth } from "@utils/date"
import AppIcon from "@utils/appIcon"
import appIcons from "@utils/appIcons"
import { useMemo } from "react"
import { selectTerms } from "@features/term/termSelector"
import { selectCourseInstanceByIdentifiers } from "@features/courseInstance/courseInstanceSelectors"

export function CourseInstanceHeader(props: {
  courseInstance: CourseInstanceDto
}) {
  const { courseInstance } = props

  const courseDisplayName = useAppSelector(
    selectCourseDisplayName(courseInstance.courseId),
  )

  const navigate = useNavigate()

  return (
    <Stack
      direction="row"
      sx={{
        py: "20px",
        width: "100%",
        borderBottom: `1px solid ${appTheme.palette.other.outlineBorder}`,
        bgcolor: appTheme.palette.primary.contrast,
        position: "relative",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box sx={{ position: "absolute", left: 24 }}>
        <BackButton
          text={"Courses"}
          onClick={() => navigate(`/course-manager`)}
        />
      </Box>
      <Stack direction="row" gap={1} alignItems="center">
        <Typography variant="h6">{courseDisplayName}</Typography>
      </Stack>
      <Stack sx={{ position: "absolute", right: 24 }}>
        <TermNavigator
          courseId={courseInstance.courseId}
          currentTermId={courseInstance.termId}
        />
      </Stack>
    </Stack>
  )
}

const TermNavigator = ({
  courseId,
  currentTermId,
}: {
  courseId: string
  currentTermId: string
}) => {
  const terms = useAppSelector(selectTerms)
  const navigate = useNavigate()

  const currentTermIndex = useMemo(() => {
    if (terms.length === 0) return -1

    return terms.findIndex((term) => term.id === currentTermId)
  }, [terms, currentTermId])

  const currentTerm = terms[currentTermIndex]
  const previousTermId = terms[currentTermIndex - 1]?.id || undefined
  const nextTermId = terms[currentTermIndex + 1]?.id || undefined

  const nextTermCourseInstance = useAppSelector((state) =>
    selectCourseInstanceByIdentifiers(state, courseId, nextTermId, undefined),
  )
  const previousTermCourseInstance = useAppSelector((state) =>
    selectCourseInstanceByIdentifiers(
      state,
      courseId,
      previousTermId,
      undefined,
    ),
  )

  if (terms.length === 0 || currentTermIndex === -1) return null

  return (
    <Stack direction="row" gap="32px">
      {previousTermCourseInstance && (
        <AppIcon
          icon={appIcons.chevronRight}
          props={{
            sx: {
              transform: "scaleX(-1)",
              cursor: "pointer",
            },
            onClick: () => {
              navigate(
                `/course-manager/course-instance/${previousTermCourseInstance.id}`,
              )
            },
          }}
        />
      )}
      <Typography variant="body1_sb">
        {currentTerm?.startDate
          ? formatDateAsYearAndLongMonth(currentTerm.startDate)
          : ""}
      </Typography>
      {nextTermCourseInstance && (
        <AppIcon
          icon={appIcons.chevronRight}
          props={{
            onClick: () => {
              navigate(
                `/course-manager/course-instance/${nextTermCourseInstance.id}`,
              )
            },
            sx: { cursor: "pointer" },
          }}
        />
      )}
    </Stack>
  )
}
