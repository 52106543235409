import { useAppSelector } from "@app/hooks"
import { selectClassesForProgram } from "@features/program/programSliceSelectors"
import { ProgramDto } from "@masterschool/course-builder-api"
import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material"
import appIcons from "@utils/appIcons"
import { Link, useNavigate, useParams } from "react-router-dom"
import appTheme from "../../../theme/appTheme"
import { legacyProgramDisplayName, programDisplayName } from "../ui/program+ui"
import useSpecificSearchParam from "@utils/hooks/useSpecificSearchParams"
import BackButton from "@cmp/buttons/backButton"
import { selectTrackById } from "@features/tracks/trackSelectors"
import { ProgramStatusChip } from "./programStatusChip"

export function ProgramHeader(props: { program: ProgramDto }) {
  const { program } = props
  const trackId = program.syllabusId
  const track = useAppSelector((state) => selectTrackById(state, trackId || ""))
  const displayName = programDisplayName(track, program.startDate)
  const numberOfTerms = (track?.terms || []).length
  const navigate = useNavigate()
  return (
    <Stack
      direction="row"
      sx={{
        py: "20px",
        width: "100%",
        borderBottom: `1px solid ${appTheme.palette.other.outlineBorder}`,
        bgcolor: appTheme.palette.primary.contrast,
        position: "relative",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box sx={{ position: "absolute", left: 24 }}>
        <BackButton text={"Programs"} onClick={() => navigate(`/programs`)} />
      </Box>
      <Stack direction="row" gap={1} alignItems="center">
        <Typography variant="h6">{displayName}</Typography>
        <ProgramStatusChip
          startDate={new Date(program.startDate)}
          durationInMonths={numberOfTerms}
        />
      </Stack>
    </Stack>
  )
}

export function LegacyProgramHeader(props: { program: ProgramDto }) {
  const { program } = props
  const { classId } = useParams()
  const classes = useAppSelector(selectClassesForProgram(program?.id))
  const selectedClass = classes.find((c) => c.id === classId)
  const navigate = useNavigate()
  const [tab] = useSpecificSearchParam("tab")

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: "20px 24px",
        borderBottom: `1px solid ${appTheme.palette.other.divider}`,
        paddingRight: "60px",
        bgcolor: appTheme.palette.primary.contrast,
      }}
    >
      <Box width="33%">
        <Button
          component={Link}
          to="/programs"
          variant="text"
          size="small"
          startIcon={
            <SvgIcon
              component={appIcons.arrowLeft}
              inheritViewBox
              sx={{
                stroke: appTheme.palette.icon.black,
              }}
            />
          }
        >
          Back
        </Button>
      </Box>
      <Box
        width="34%"
        justifyContent="center"
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "8px",
        }}
      >
        <Typography variant="body1" color={appTheme.palette.text.secondary}>
          {`${legacyProgramDisplayName(program)}`}
        </Typography>
        <Typography variant="body1">/</Typography>
        <FormControl variant="standard">
          <Select
            sx={{
              ".MuiSelect-select": {
                minHeight: "0px",
                maxWidth: "200px",
              },
              "&:before": {
                content: "none",
                borderBottom: "none",
              },
              "&:after": {
                content: "none",
                borderBottom: "none",
              },
              "&:hover:not(.Mui-disabled):before": {
                content: "none",
                borderBottom: "none",
              },
            }}
            value={
              selectedClass && classes.find((c) => c.id === selectedClass.id)
                ? selectedClass.id
                : ""
            }
            onChange={(e) => {
              let target = e.target.value
                ? `/program/${program.id}/${e.target.value}`
                : `/program/${program.id}`
              if (tab) {
                target += `?tab=${tab}`
              }
              navigate(target)
            }}
            renderValue={(value) => (
              <span
                style={{
                  width: "360px",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                {classes.find((c) => c.id === value)?.name || "All classes"}
              </span>
            )}
            displayEmpty
          >
            <MenuItem
              value=""
              sx={{
                bgcolor: appTheme.palette.primary.contrast,
                width: "360px",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
            >
              All classes
            </MenuItem>
            {classes.map((c) => (
              <MenuItem
                key={c.id}
                value={c.id}
                sx={{
                  display: "block",
                  width: "360px",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                {c.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box
        width="33%"
        justifyContent="end"
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "8px",
        }}
      ></Box>
    </Box>
  )
}
