export enum ColumnKeys {
  NAME = "name",
  EMAIL = "email",
  LAST_CSAT_SCORE = "lastCSATScore",
  LAST_NPS_SCORE = "lastNPSScore",
  LAST_LEARNING_ACTIVITY = "lastLearningActivity",
  LAST_LIVE_SESSION = "lastLiveSession",
  LAST_INTERVIEW_SCORE = "lastInterviewScore",
  PROGRAM = "program",
  COHORT = "cohort",
  REGISTERED_CLASS = "registeredClass",
  LANGUAGE = "language",
  MENTOR = "mentor",
  ACTIONS = "actions",
}
