import { RootState } from "@app/store"
import {
  CourseDescriptorDto,
  CourseStatus,
} from "@masterschool/course-builder-api"
import { createSelector } from "@reduxjs/toolkit"
import { selectLastVersionSyllabuses } from "@features/syllabus/syllabusSelectors"

import { parseStringVersion } from "@utils/versionUtils"

export const selectLatestCourseVersion = (courseId?: string) =>
  createSelector([selectCourses], (courses) => {
    if (!courseId || !courses) {
      return undefined
    }

    return getLatestCourseVersion(courses, courseId)
  })

export const selectLatestPublishedCourse = (courseId?: string) =>
  createSelector([selectCourses], (courses) => {
    if (!courseId || !courses) {
      return undefined
    }

    return findLatestCourseVersionForStatus(
      courses,
      courseId,
      CourseStatus.Published,
    )
  })

export const selectCourses = (
  state: RootState,
): CourseDescriptorDto[] | undefined => {
  if (
    state.coursesMenu.courses === "pending" ||
    state.coursesMenu.courses === "rejected"
  ) {
    return undefined
  }

  return state.coursesMenu.courses
}

export const selectLatestPublishedCoursesByIds = createSelector(
  [(_, courseIds: string[]) => courseIds, selectCourses],
  (courseIds, courses) => {
    if (!courses) {
      return []
    }

    return courseIds.msCompactMap((courseId) =>
      findLatestCourseVersionForStatus(
        courses,
        courseId,
        CourseStatus.Published,
      ),
    )
  },
)

export const selectCourseVersion = (courseId: string, version: string) =>
  createSelector([selectCourses], (courses) => {
    if (!courses) {
      return undefined
    }

    return courses.find(
      (course) => course.id === courseId && course.version === version,
    )
  })

export const selectLatestDraftCourse = (courseId?: string) =>
  createSelector([selectCourses], (courses) => {
    if (!courseId || !courses) {
      return undefined
    }

    return findLatestCourseVersionForStatus(
      courses,
      courseId,
      CourseStatus.Draft,
    )
  })

export const selectIsEditingPublishedCourse = (courseId: string) =>
  createSelector(
    [selectLatestPublishedCourse(courseId), selectLatestDraftCourse(courseId)],
    (published, draft) => {
      return published !== undefined && draft !== undefined
    },
  )

function findLatestCourseVersionForStatus(
  courses: CourseDescriptorDto[],
  courseId: string,
  status: CourseStatus,
) {
  return getLatestCourseVersion(
    courses.filter((course) => course.status === status),
    courseId,
  )
}

function getLatestCourseVersion(
  courses: CourseDescriptorDto[],
  courseId: string,
): CourseDescriptorDto | undefined {
  const coursesById = courses.filter((course) => course.id === courseId)

  if (coursesById.length === 0) {
    return undefined
  }

  return coursesById.reduce((prev, curr) =>
    (curr.version ?? "1.1") > (prev.version ?? "1.1") ? curr : prev,
  )
}

export const selectCoursesMainPageTab = (state: RootState) =>
  state.coursesMenu.tab

export const selectPublishedCourses = createSelector(
  selectCourses,
  (courses) => {
    if (!courses) {
      return undefined
    }

    return courses.filter((course) => course.status === CourseStatus.Published)
  },
)

export const selectLatestVersionsPublishedCourses = createSelector(
  selectPublishedCourses,
  (courses) => {
    if (!courses) {
      return undefined
    }

    const latestCoursesByVersion = courses.reduce((acc, curr) => {
      const course = acc[curr.id]
      if (!course || course.version < curr.version) {
        acc[curr.id] = curr
      }
      return acc
    }, {} as { [key: string]: CourseDescriptorDto })

    return Object.values(latestCoursesByVersion)
  },
)

export const selectCourseMajorRepresentatives = createSelector(
  [(_, courseId: string) => courseId, selectCourses],
  (courseId, courses) => {
    if (!courses) {
      return undefined
    }
    const allVersions = courses.filter((course) => course.id === courseId)
    const majorMap = allVersions.reduce((acc, course) => {
      const { major } = parseStringVersion(course.version)
      if (!acc[major]) {
        acc[major] = course
        return acc
      }
      if (
        acc[major].version < course.version &&
        course.status !== CourseStatus.Draft
      ) {
        acc[major] = course
        return acc
      }
      return acc
    }, {} as Record<number, CourseDescriptorDto>)
    return Object.values(majorMap)
  },
)

export const selectCourseByMajorVersion = createSelector(
  [
    (_, courseId: string, majorVersion: number) => ({ courseId, majorVersion }),
    selectCourseMajorRepresentatives,
  ],
  ({ courseId, majorVersion }, majorsCourses) => {
    if (!majorsCourses) {
      return undefined
    }
    const allCourseMajors = majorsCourses.filter(
      (course) => course.id === courseId,
    )
    return allCourseMajors.find(
      (course) => parseStringVersion(course.version).major === majorVersion,
    )
  },
)

export const selectFirstMinorVersionCourse = createSelector(
  [(_, ids: { courseId: string; version: string }) => ids, selectCourses],
  ({ courseId, version }, courses) => {
    if (!courses) {
      return undefined
    }
    const { major } = parseStringVersion(version)
    const allVersions = courses.filter((course) => course.id === courseId)
    return allVersions.reduce((acc, currentCourse) => {
      const { major: currentMajor, minor: currentMinor } = parseStringVersion(
        currentCourse.version,
      )
      const { minor: accMinor } = parseStringVersion(acc.version)
      if (currentMajor === major && currentMinor > accMinor) {
        return currentCourse
      }
      return acc
    }, allVersions[0])
  },
)

export const selectMinorVersionCourse = createSelector(
  [
    (
      _,
      identifiers: {
        courseId: string
        version?: string
        position: "First" | "Last"
      },
    ) => identifiers,
    selectCourses,
  ],
  ({ courseId, version, position }, courses) => {
    if (!courses || !version) {
      return undefined
    }
    const { major } = parseStringVersion(version)

    const relevantCourses = courses.filter((course) => {
      const { major: courseMajor } = parseStringVersion(course.version)
      return course.id === courseId && courseMajor === major
    })
    if (position === "First") {
      return relevantCourses.reduce((acc, currentCourse) => {
        if (currentCourse.version < acc.version) {
          return currentCourse
        }
        return acc
      }, relevantCourses[0])
    }
    return relevantCourses.reduce((acc, currentCourse) => {
      if (currentCourse.version > acc.version) {
        return currentCourse
      }
      return acc
    }, relevantCourses[0])
  },
)

export const selectHasChanges = (courseId?: string) =>
  createSelector(
    [selectLatestDraftCourse(courseId), selectLatestPublishedCourse(courseId)],
    (draft, published) => {
      return draft !== undefined && published !== undefined
    },
  )

export const selectCoursesFetchingStatus = (state: RootState) => {
  if (state.coursesMenu.courses === "pending") {
    return "loading"
  } else if (state.coursesMenu.courses === "rejected") {
    return "error"
  } else {
    return "idle"
  }
}

export const selectCoursesSortOption = (state: RootState) =>
  state.coursesMenu.sortOption

export const selectCoursesType = (state: RootState) => state.coursesMenu.type

export const selectCourseDisplayName = (courseId: string) =>
  createSelector([selectCourses], (courses) => {
    if (!courses) {
      return undefined
    }

    const course = courses.find((course) => course.id === courseId)

    return course?.title
  })

export const selectDependentSyllabuses = (courseId: string | undefined) =>
  createSelector([selectLastVersionSyllabuses], (syllabuses) => {
    return syllabuses
      .filter((syllabus) => {
        return syllabus.units.some((unit) =>
          unit.courseDescriptors.some((cd) => cd.courseId === courseId),
        )
      })
      .map((syllabus) => syllabus.id)
  })
