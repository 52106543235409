import { useEffect, useMemo, useState } from "react"
import {
  CalendarHubClient,
  CourseClassCalendarDto,
  MsPrivateCalendarDto,
} from "@clients/calendarHubClient"
import { useAppSelector } from "@app/hooks"
import { selectLoggedInUser } from "@features/login/loginSelectors"
import { useEventActions } from "../../main/calendar/useEventActions"
import Stack from "@mui/material/Stack"
import MsCalendar from "@masterschool/ui-calendar"
import { CalendarGroupings } from "@masterschool/ui-calendar/dist/src/types"
import { Typography } from "@mui/material"
import { mapToMsCalendarProps } from "../../main/calendar/calendarMappers"
import { FetchEventSnackbar } from "../../main/calendar/FetchEventSnackbar"
import appTheme from "../../theme/appTheme"

import { UsersClient } from "@clients/usersClient"
import { Role } from "@features/login/loginSlice"
import { selectCourseInstanceClasses } from "@features/courseInstance/courseInstanceSelectors"

const d1 = new Date("2024-08-10T10:00:00.000Z")
const d2 = new Date("2026-08-20T10:00:00.000Z")
const dateRange = { start: d1, end: d2 }

export const CourseInstanceCalendar = (props: {
  courseInstanceId: string | undefined
}) => {
  const { courseInstanceId } = props
  const user = useAppSelector(selectLoggedInUser)
  const courseClasses = useAppSelector((state) =>
    selectCourseInstanceClasses(state, courseInstanceId),
  )

  const [classCalendars, setClassCalendars] = useState<
    CourseClassCalendarDto[]
  >([])
  const [staffCalendars, setStaffCalendars] = useState<MsPrivateCalendarDto[]>(
    [],
  )

  const classCalendarIds = useMemo(
    () => classCalendars.map((c) => c.id),
    [classCalendars],
  )

  useEffect(() => {
    if (!courseClasses) {
      return
    }
    CalendarHubClient.getCourseClassesCalendar(
      courseClasses.map((c) => c.id),
    ).then((calendars) => {
      setClassCalendars(calendars)
    })
  }, [courseClasses])

  useEffect(() => {
    UsersClient.getUsersWithRoles([
      Role.Mentor,
      Role.Instructor,
      Role.ProgramManager,
      Role.CourseManager,
    ])
      .then((users) =>
        CalendarHubClient.getCalendarsByUserClientId(users.map((u) => u.id)),
      )
      .then((calendars) => {
        setStaffCalendars(calendars)
      })
  }, [])

  const { msEvents, onChange, onDelete, addCalendarIdsToFetch, loading } =
    useEventActions(classCalendarIds, dateRange)

  if (!user) {
    return null
  }
  return (
    <Stack
      sx={{
        width: "100%",
        height: "100%",
        bgColor: appTheme.palette.primary.contrast,
      }}
    >
      <Typography variant="h6" pl="24px" pb="8px">
        Calendar
      </Typography>
      {loading && <FetchEventSnackbar />}
      <Stack
        flex={1}
        overflow="auto"
        sx={{
          width: "100%",
          height: "100%",
        }}
      >
        <MsCalendar
          events={msEvents}
          calendars={buildCalendarGroupings(classCalendars, staffCalendars)}
          getCalendar={(c) => {
            return undefined
          }}
          onEventChange={onChange}
          userId={user.clientId}
          onDeleteEvent={onDelete}
          canJoinLiveSessions={false}
          onSelectedCalendarChange={(calendarIds) => {
            const calendarIdsExcludeDraft = calendarIds.filter(
              (c) => !c.includes("draft"),
            )
            addCalendarIdsToFetch(calendarIdsExcludeDraft)
          }}
          supportCreateEventTypes={["live-session", "regular"]}
        />
      </Stack>
    </Stack>
  )
}

const buildCalendarGroupings = (
  classCalendars: CourseClassCalendarDto[],
  staffCalendars: MsPrivateCalendarDto[],
): CalendarGroupings => {
  return [
    {
      title: "Classes",
      type: "single",
      groupingsType: "classes",
      groupingsAction: "all",
      calendars: classCalendars.map((c) => mapToMsCalendarProps(c)),
    },
    {
      title: "Staff",
      type: "single",
      groupingsType: "staff",
      groupingsAction: "invite",
      calendars: staffCalendars.map((c) => mapToMsCalendarProps(c)),
    },
  ]
}
