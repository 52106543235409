import { MuiColorPalette } from "@masterschool/ui-calendar/dist/src/types"
import { CampusClient } from "./campusClient"

export type MsEventDto = {
  id: string
  type:
    | "live-session"
    | "regular"
    | "tutoring-session"
    | "one-on-one"
    | "availability"
  title: string
  description: string
  start: Date
  end: Date
  presentAsAllDay: boolean
  color: MuiColorPalette | undefined
  participants: string[]
  calendarId: string
  recurringGroupID?: string
  rrule?: string
  rruleUntil?: Date
  rruleTzid?: string
  hasVc: boolean
  vcUrl: string | null
  instructorCalendarId?: string
  organizerClientId: string
  recordingLinks: string[]
}

type MsCalendarDtoBase = {
  id: string
  name: string
  description: string
  color: string
}

export type MsPrivateCalendarDto = MsCalendarDtoBase & {
  userClientId: string
  roles: string[]
}

export type MsOfferingCalendarDto = MsCalendarDtoBase & {
  offeringDetails: {
    offeringClientId: string
    name: string
    programId: string
  }
}

export type CourseClassCalendarDto = MsCalendarDtoBase & {
  id: string
  name: string
  description: string
  color: string
  courseClassDetails: {
    courseClassName: string
    courseClassId: string
    courseInstanceId: string
    courseId: string
    majorVersion: number | undefined
    termId: string
    language: string
  }
}

export type ProgramManagementCalendar = {
  programSchoolCalendars: MsOfferingCalendarDto[]
  programStaffCalendars: MsPrivateCalendarDto[]
  studentCalendars: MsPrivateCalendarDto[]
  allMasterschoolStaffCalendars: MsPrivateCalendarDto[]
}

export type CourseClassCalendarResponse = {
  courseClassCalendars: CourseClassCalendarDto[]
}

export type CustomCalendarResponse = {
  personalCalendars: MsPrivateCalendarDto[]
  schoolCalendars: MsOfferingCalendarDto[]
}

export const msEventEditableProperties = [
  "title",
  "description",
  "start",
  "end",
  "type",
  "presentAsAllDay",
  "participants",
  "rrule",
  "rruleUntil",
  "rruleTzid",
  "instructorCalendarId",
  "hasVc",
] as const

export type MsEventEditableProperties = Pick<
  MsEventDto,
  (typeof msEventEditableProperties)[number]
>

export type CreateMsEventDto = Omit<
  MsEventDto,
  "id" | "color" | "organizerClientId" | "vcUrl"
>

export class CalendarHubClient {
  static async create(event: CreateMsEventDto): Promise<string> {
    return CampusClient.post<string>(`ms-calendar-hub/create`, event)
  }

  static async getProgramCalendars() {
    return CampusClient.get<ProgramManagementCalendar>(
      "ms-calendar-hub/calendars/programs",
    )
  }

  static async getSchoolCalendars(schoolIds: string[]) {
    return CampusClient.post<{
      schoolCalendars: MsOfferingCalendarDto[]
    }>("ms-calendar-hub/calendars/school", { schoolIds }).then(
      (response) => response.schoolCalendars,
    )
  }

  static async getCourseClassesCalendar(courseClassIds: string[]) {
    return CampusClient.post<CourseClassCalendarResponse>(
      "ms-calendar-hub/calendars/course-class",
      { courseClassIds },
    ).then((response) => response.courseClassCalendars)
  }

  static getCalendarsById(ids: string[]) {
    return CampusClient.post<CustomCalendarResponse>(
      "ms-calendar-hub/calendars",
      { calendarIds: ids },
    )
  }

  static getCalendarsByUserClientId(userClientIds: string[]) {
    return CampusClient.post<MsPrivateCalendarDto[]>(
      "ms-calendar-hub/calendars/userClientId",
      { userClientIds },
    )
  }

  static async getEvents(calendarIds: string[], from: Date, to: Date) {
    return CampusClient.post<MsEventDto[]>(`ms-calendar-hub/get-events/`, {
      calendarIds: calendarIds,
      from,
      to,
    })
  }

  static async getDrafts(from: Date, to: Date) {
    return CampusClient.get<MsEventDto[]>(
      `ms-calendar-hub/get-drafts?from=${from.toISOString()}&to=${to.toISOString()}`,
    )
  }

  static async edit(
    id: string,
    properties: Partial<MsEventEditableProperties>,
  ) {
    const propertiesWithNullInsteadOfUndefined = Object.fromEntries(
      Object.entries(properties).map(([key, value]) => {
        return [key, value === undefined ? null : value]
      }),
    )
    return CampusClient.post(`ms-calendar-hub/edit/`, {
      event_id: id,
      properties: propertiesWithNullInsteadOfUndefined,
    })
  }

  static async editRecurring(
    target: {
      event_id: string
      occurrence_start_time: Date
      type: "this" | "this-and-future" | "all"
    },
    properties: Partial<MsEventEditableProperties>,
  ) {
    const propertiesWithNullInsteadOfUndefined = Object.fromEntries(
      Object.entries(properties).map(([key, value]) => [
        key,
        value === undefined ? null : value,
      ]),
    )
    return CampusClient.post(`ms-calendar-hub/edit-recurrence/`, {
      target,
      properties: propertiesWithNullInsteadOfUndefined,
    })
  }

  static delete(id: string) {
    return CampusClient.delete(`ms-calendar-hub/delete`, { event_id: id })
  }

  static deleteRecurring(
    event_id: string,
    occurrence_start_time: Date,
    recurring_group_id: string,
    type: "this" | "this-and-future" | "all",
  ) {
    return CampusClient.delete(`ms-calendar-hub/delete-recurrence/`, {
      target: { event_id, occurrence_start_time, type, recurring_group_id },
    })
  }
}
