import { selectShowTrackFeature } from "@features/login/featureFlagSelectors"

import { Box, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material"
import { useSelector } from "react-redux"

import { useState } from "react"
import { TracksContainer } from "./tracksContainer"

export const TracksMainPage = () => {
  const isTrackFeatureEnabled = useSelector(selectShowTrackFeature)

  const [mode, setMode] = useState<TableMode>("Tracks")

  if (!isTrackFeatureEnabled) {
    return null
  }
  return (
    <PageCenteredBoxWrapper>
      <HeaderPaddingWrapper>
        <Typography variant="h4">Track builder</Typography>
        <TrackTypeToggle
          value={mode}
          onValueChange={(value) => setMode(value)}
        />
      </HeaderPaddingWrapper>
      {mode === "Tracks" && <TracksContainer />}
    </PageCenteredBoxWrapper>
  )
}

type TableMode = "Tracks" | "MasterModules"

export const TrackTypeToggle = (props: {
  value: TableMode
  onValueChange: (type: TableMode) => void
}) => {
  const { value, onValueChange } = props

  return (
    <ToggleButtonGroup
      value={value}
      exclusive
      onChange={(_e, newVal) => newVal && onValueChange(newVal)}
      sx={{
        height: "32px",
      }}
    >
      <ToggleButton value={"Tracks"} sx={{ width: "160px" }}>
        <Typography variant="body2">Tracks</Typography>
      </ToggleButton>
      <ToggleButton value={"MasterModules"} sx={{ width: "160px" }} disabled>
        <Typography variant="body2">Master modules</Typography>
      </ToggleButton>
    </ToggleButtonGroup>
  )
}

const PageCenteredBoxWrapper = (props: { children: React.ReactNode }) => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
        paddingX: "24px",
        paddingBottom: "48px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          maxWidth: "1240px",
          marginX: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            pt: "80px",
            mb: "16px",
          }}
        >
          {props.children}
        </Box>
      </Box>
    </Box>
  )
}

const HeaderPaddingWrapper = (props: { children: React.ReactNode }) => {
  return (
    <Box
      display="flex"
      sx={{
        justifyContent: "space-between",
        width: "100%",
        mb: "16px",
      }}
    >
      {props.children}
    </Box>
  )
}
