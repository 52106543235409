import {
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  GridColDef,
  GridComparatorFn,
} from "@mui/x-data-grid-pro"
import {
  ClassesCell,
  DomainCell,
  ExpandCell,
  ScopeCell,
  StartDateCell,
  StatusCell,
} from "./programsMenuCells"
import { ProgramDisplayData } from "./programsMenu"
import { getActionsColumn } from "./menuActions"
import { schoolOptions } from "../../tab-views/students-tab/students-grid/columns/registration.details.columns"
import { domainToDisplayName } from "@utils/domainUtils"

const getSelectionColumn: () => GridColDef<ProgramDisplayData> = () => {
  return {
    ...GRID_CHECKBOX_SELECTION_COL_DEF,
    width: 24,
    headerClassName: "selection-header",
    cellClassName: "selection-cell",
  }
}

const getExpandColumn: () => GridColDef<ProgramDisplayData> = () => {
  return {
    ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
    headerClassName: "expand-header",
    cellClassName: "expand-cell",
    renderCell: (params) => (
      <ExpandCell
        id={params.id}
        value={params.value}
        canExpand={params.row.classesIds.length > 0}
      />
    ),
  }
}

const getDomainColumn: () => GridColDef<ProgramDisplayData> = () => {
  return {
    field: "domain",
    headerName: "School",
    headerClassName: "domain-header",
    type: "singleSelect",
    valueOptions: schoolOptions,
    width: 192,
    valueGetter: (value) => domainToDisplayName(value),
    renderCell: (params) => (
      <DomainCell program={params.row} navigateOnClick={true} />
    ),
    sortComparator: domainComparator,
  }
}

const getStartDateColumn: () => GridColDef = () => {
  return {
    field: "startDate",
    headerName: "Start Date",
    width: 198,
    renderCell: (params) => (
      <StartDateCell startDate={new Date(params.row.startDate)} />
    ),
    sortComparator: startDateComparator,
  }
}

const getDurationColumn: () => GridColDef = () => {
  return {
    field: "durationInMonths",
    headerName: "Scope",
    width: 198,
    renderCell: (params) => (
      <ScopeCell durationInMonths={params.row.durationInMonths} />
    ),
    sortComparator: durationComparator,
  }
}

const getClassesColumn: () => GridColDef<ProgramDisplayData> = () => {
  return {
    field: "classesIds",
    headerName: "Classes",
    width: 198,
    renderCell: (params) => <ClassesCell classesIds={params.row.classesIds} />,
    sortComparator: classesComparator,
  }
}

const getStatusColumn: () => GridColDef<ProgramDisplayData> = () => {
  return {
    field: "status",
    headerName: "Status",
    width: 198,
    renderCell: (params) => {
      const { durationInMonths, startDate } = params.row
      return (
        <StatusCell
          startDate={new Date(startDate)}
          durationInMonths={durationInMonths}
        />
      )
    },
    sortable: false,
  }
}

const domainComparator: GridComparatorFn<string> = (domain1, domain2) => {
  return domain1.localeCompare(domain2)
}

const startDateComparator: GridComparatorFn<string> = (
  startDate1,
  startDate2,
) => {
  return new Date(startDate1) > new Date(startDate2) ? 1 : -1
}

const durationComparator: GridComparatorFn<number> = (
  durationInMonths1,
  durationInMonths,
) => {
  return durationInMonths1 > durationInMonths ? 1 : -1
}

const classesComparator: GridComparatorFn<string[]> = (
  classIds1,
  classIds2,
) => {
  return classIds1.length > classIds2.length ? 1 : -1
}

export const getLegacyProgramsMenuColumns = () => {
  return [
    getSelectionColumn(),
    getExpandColumn(),
    getDomainColumn(),
    getStartDateColumn(),
    getDurationColumn(),
    getClassesColumn(),
    getStatusColumn(),
    getActionsColumn(),
  ]
}
