import { createAppAsyncThunk } from "@app/createAppAsyncThunk"
import {
  CourseDto,
  CourseDescriptorDto,
  CourseStatus,
  CourseType,
  UpdateTackCourseGeneralDetailsRequest,
} from "@masterschool/course-builder-api"
import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import {
  SortOption,
  SortOrder,
  SortType,
} from "../../main/sort/coursesSortHelpers"
import { CourseClient } from "@clients/courseClient"
import { publishCourse } from "../courseEditor/courseEditorSlice"
import { parseStringVersion } from "@utils/versionUtils"

export enum TabIdentifierEnumeration {
  All = "All",
  Published = "Published",
  Draft = "Draft",
  Favorites = "Favorites",
  Archived = "Archived",
}

export interface CoursesMenuState {
  courses: "pending" | "rejected" | CourseDescriptorDto[]
  sortOption: SortOption
  favoriteCourses: string[]
  tab: TabIdentifierEnumeration
  highlightedCourseId: string | undefined
  type: CourseType
}

export const coursesMenuSlice = createSlice({
  name: "coursesMenu",
  initialState: {} as CoursesMenuState, // Defined in preloadedState in store.ts
  reducers: {
    sortAlphabeticallyClicked: (state, action: PayloadAction<SortOrder>) => {
      if (state.courses === "pending" || state.courses === "rejected") {
        return
      }
      state.sortOption.type = SortType.Alphabetic
      state.sortOption.order = action.payload
    },
    sortChronologicallyClicked: (state, action: PayloadAction<SortOrder>) => {
      if (state.courses === "pending" || state.courses === "rejected") {
        return
      }
      state.sortOption.type = SortType.Chronological
      state.sortOption.order = action.payload
    },
    toggleIsCourseFavorite: (
      state,
      action: PayloadAction<{ courseId: string }>,
    ) => {
      const { courseId } = action.payload
      const favoriteCourses = state.favoriteCourses
      if (favoriteCourses.includes(courseId)) {
        state.favoriteCourses = favoriteCourses.filter((id) => id !== courseId)
      } else {
        state.favoriteCourses.push(courseId)
      }
    },
    tabSelected: (state, action: PayloadAction<TabIdentifierEnumeration>) => {
      state.tab = action.payload
    },
    clickedAwayHighlightedCourse: (state) => {
      state.highlightedCourseId = undefined
    },
    courseTypeSelected: (
      state,
      action: PayloadAction<{ courseType: CourseType }>,
    ) => {
      state.type = action.payload.courseType
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCourses.pending, (state, action) => {
        state.courses = "pending"
      })
      .addCase(fetchCourses.fulfilled, (state, action) => {
        state.courses = action.payload.sort((a, b) => {
          return a.name.localeCompare(b.name)
        })
      })
      .addCase(fetchCourses.rejected, (state, action) => {
        state.courses = "rejected"
      })
      .addCase(createLegacyCourse.fulfilled, (state, action) => {
        if (state.courses === "pending" || state.courses === "rejected") {
          return
        } else {
          state.courses.push(action.payload)
        }
      })
      .addCase(duplicateCourse.fulfilled, (state, action) => {
        if (state.courses === "pending" || state.courses === "rejected") {
          return
        }

        const updatedCourses = [...state.courses].concat(action.payload.course)

        state.courses = updatedCourses

        if (
          state.tab === TabIdentifierEnumeration.Favorites ||
          state.tab === TabIdentifierEnumeration.Published
        ) {
          state.tab = TabIdentifierEnumeration.Draft
        }

        state.highlightedCourseId = action.payload.course.id
      })
      .addCase(publishFromDescriptor.fulfilled, (state, action) => {
        if (state.courses === "pending" || state.courses === "rejected") {
          return
        }

        if (action.payload.kind === "failure") {
          return
        }

        const publishedCourse = action.payload.value

        const courseIndex = state.courses.findIndex(
          (course) => course.id === publishedCourse.id,
        )

        state.courses[courseIndex] = publishedCourse
      })
      .addCase(archiveCourse.fulfilled, (state, action) => {
        if (state.courses === "pending" || state.courses === "rejected") {
          return
        }

        state.courses = state.courses.map((course) => {
          if (course.id === action.payload.id) {
            return {
              ...course,
              status: CourseStatus.Archived,
            }
          }

          return course
        })
      })
      .addCase(deleteCourse.fulfilled, (state, action) => {
        if (state.courses === "pending" || state.courses === "rejected") {
          return
        }

        state.courses = state.courses.filter(
          (course) => course.id !== action.payload.id,
        )
      })
      .addCase(createDraftForCourse.fulfilled, (state, action) => {
        if (
          state.courses === "pending" ||
          state.courses === "rejected" ||
          !action.payload
        ) {
          return
        }
        const courseId = action.payload.id

        const previousCourse = state.courses.find(
          (course) => course.id === courseId,
        )

        if (!previousCourse) {
          return
        }

        const newCourse = {
          ...previousCourse,
          ...action.payload,
        }

        const updatedCourses = [...state.courses].concat(newCourse)

        state.courses = updatedCourses
      })
      .addCase(publishCourse.fulfilled, (state, action) => {
        if (state.courses === "pending" || state.courses === "rejected") {
          return
        }

        switch (action?.payload?.kind) {
          case "failure":
            return
          case "success":
            const publishedCourse = action.payload.value.publishedVersion
            state.courses = state.courses.map((course) => {
              if (course.id === publishedCourse.id) {
                return {
                  ...course,
                  ...publishedCourse,
                }
              }

              return course
            })

            const containsCourse = state.courses.some(
              (course) => course.id === publishedCourse.id,
            )

            if (!containsCourse) {
              state.courses.push(publishedCourse)
            }
        }
      })
      .addCase(updateTrackCourseGeneralDetails.fulfilled, (state, action) => {
        if (state.courses === "pending" || state.courses === "rejected") {
          return
        }

        const updatedCourse = action.payload as CourseDto

        state.courses = state.courses.map((course) => {
          if (course.id === updatedCourse.id) {
            return {
              ...course,
              title: updatedCourse.title,
              name: updatedCourse.name,
              domains: updatedCourse.domains,
            }
          }

          return course
        })
      })
      .addCase(discardDraft.fulfilled, (state, action) => {
        if (state.courses === "pending" || state.courses === "rejected") {
          return
        }

        const courseId = action.meta.arg.courseId
        const version = action.meta.arg.version

        state.courses = state.courses.filter(
          (course) => !(course.id === courseId && course.version === version),
        )
      })
      .addCase(duplicateLegacyCourseToTrack.fulfilled, (state, action) => {
        if (state.courses === "pending" || state.courses === "rejected") {
          return
        }

        const updatedCourse = action.payload as CourseDto

        state.courses.push(updatedCourse)
      })
      .addCase(createNewMajorVersion.fulfilled, (state, action) => {
        if (state.courses === "pending" || state.courses === "rejected") {
          return
        }
        const newCourse = action.payload
        state.courses.push(newCourse)
      })
  },
})

export const fetchCourses = createAppAsyncThunk(
  "coursesMenu/fetchCourses",
  async (_, thunkAPI) => {
    return CourseClient.listCourseDescriptors()
  },
)

export const createTrackCourse = createAppAsyncThunk<
  CourseDto,
  {
    displayName: string
    internalName: string
    domains: string[]
  },
  undefined
>("coursesMenu/createTrackCourse", async (body) => {
  return CourseClient.createTrackCourse(body)
})

export const createLegacyCourse = createAppAsyncThunk<
  CourseDto,
  void,
  undefined
>("coursesMenu/createLegacyCourse", async () => {
  return CourseClient.createLegacyCourse()
})

export const duplicateCourse = createAppAsyncThunk<
  {
    course: CourseDescriptorDto
    originalCourseId: string
  },
  { courseId: string; version: string; type?: CourseType },
  undefined
>("coursesMenu/duplicateCourse", async ({ courseId, version, type }) => {
  return CourseClient.duplicateCourse(courseId, version, type).then(
    (duplicatedCourse) => {
      return {
        course: duplicatedCourse,
        originalCourseId: courseId,
      }
    },
  )
})

export const createDraftForCourse = createAppAsyncThunk<
  CourseDto | undefined,
  { courseId: string; majorVersion: number },
  undefined
>("courseEditor/createNewCourseVersion", async ({ courseId, majorVersion }) => {
  return CourseClient.createCourseDraft(courseId, majorVersion)
})

export const publishFromDescriptor = createAppAsyncThunk(
  "coursesMenu/publishFromDescriptor",
  async ({
    courseId,
    majorVersion,
  }: {
    courseId: string
    majorVersion: number
  }) => {
    return CourseClient.publishCourse(courseId, majorVersion)
  },
)

export const archiveCourse = createAppAsyncThunk(
  "coursesMenu/archiveCourse",
  async ({
    courseId,
    majorVersion,
  }: {
    courseId: string
    majorVersion: number
  }) => {
    return CourseClient.archiveCourse(courseId, majorVersion)
  },
)

export const deleteCourse = createAppAsyncThunk(
  "coursesMenu/deleteCourse",
  async (courseId: string) => {
    return CourseClient.deleteCourse(courseId).then(() => {
      return {
        id: courseId,
      }
    })
  },
)

export const updateTrackCourseGeneralDetails = createAppAsyncThunk(
  "coursesMenu/updateTrackCourseGeneralDetails",
  async (payload: {
    details: UpdateTackCourseGeneralDetailsRequest
    id: string
  }) => {
    return CourseClient.updateTrackCourseGeneralDetails(
      payload.details,
      payload.id,
    )
  },
)

export const discardDraft = createAppAsyncThunk(
  "coursesMenu/discardDraft",
  async (payload: { courseId: string; version: string }) => {
    const { major } = parseStringVersion(payload.version)
    return CourseClient.discardDraft(payload.courseId, major)
  },
)

export const duplicateLegacyCourseToTrack = createAppAsyncThunk(
  "coursesMenu/duplicateLegacyCourseToTrack",
  async ({ courseId, version }: { courseId: string; version: string }) => {
    return CourseClient.duplicateLegacyCourseToTrack(courseId, version)
  },
)

export const createNewMajorVersion = createAppAsyncThunk(
  "coursesMenu/createNewMajorVersion",
  async (courseId: string) => {
    return CourseClient.createNewMajorVersion(courseId)
  },
)

export const {
  toggleIsCourseFavorite,
  sortAlphabeticallyClicked,
  sortChronologicallyClicked,
  tabSelected,
  clickedAwayHighlightedCourse,
  courseTypeSelected,
} = coursesMenuSlice.actions

export default coursesMenuSlice.reducer
