import { useAppDispatch, useAppSelector } from "@app/hooks"
import GenericTable from "@cmp/genericTable"
import SearchTextField from "@cmp/searchTextField"
import { studentsSearchChanged } from "@features/program/programSlice"
import { selectStudents } from "@features/program/programSliceSelectors"
import { ProgramDto } from "@masterschool/course-builder-api"
import { Box, Typography } from "@mui/material"
import appIcons from "@utils/appIcons"
import { useParams } from "react-router-dom"
import { SlackIdCell } from "../../../account-management/accountManagement"
import appTheme from "../../../theme/appTheme"
import EmptyStateContainer from "../components/emptyStateContainer"
import OnboardStudentsButton from "./onboardStudentsButton"
import { StudentActionsMenu } from "./studentActionsMenu"
import { LanguageChip } from "../components/languageChip"

function StudentsPage(props: { program: ProgramDto }) {
  const { classId } = useParams()
  const searchText = useAppSelector(
    (state) => state.program.studentsPage.search,
  )
  const students = useAppSelector(selectStudents(props.program.id, classId))
  const totalStudents = students.length

  const tableModel = students
    .map((s) => ({
      name: s.firstName + " " + s.lastName,
      email: s.email,
      studentId: s.id,
      slackId: s.slackId,
      className: s.className,
      classId: s.classId,
      language: s.programLanguage,
      mentorName:
        s.mentor !== undefined
          ? s.mentor.firstName + " " + s.mentor.lastName
          : "",
      careerSuccessAdvisorName: s.careerSuccessAdvisor
        ? s.careerSuccessAdvisor.firstName +
          " " +
          s.careerSuccessAdvisor.lastName
        : "",
    }))
    .filter(
      (s) =>
        s.name.toLowerCase().includes(searchText.toLowerCase()) ||
        s.email.toLowerCase().includes(searchText.toLowerCase()),
    )

  return totalStudents === 0 ? (
    <Empty programId={props.program.id} />
  ) : (
    <NotEmpty
      searchText={searchText}
      tableModel={tableModel}
      totalStudents={totalStudents}
      programId={props.program.id}
      programType={
        props.program.cohortType === "legacy" ? "legacy" : "next-gen"
      }
    />
  )
}

function Empty(props: { programId: string }) {
  const { classId } = useParams()
  const { programId } = props

  return (
    <EmptyStateContainer
      icon={appIcons.users02}
      title="No students yet"
      subtitle="Add and manage students for this program"
      action={
        <OnboardStudentsButton
          classId={classId}
          programId={programId}
          buttonText="Onboard students"
          variant="contained"
          size="large"
          icon="chevron"
        />
      }
    />
  )
}

type StudentsTableRow = {
  name: string
  email: string
  studentId: string
  slackId: string | undefined
  className: string
  classId: string
  mentorName: string | undefined
  careerSuccessAdvisorName: string | undefined
  language?: string
}

const LEGACY_ONLY_KEYS: (keyof StudentsTableRow)[] = [
  "slackId",
  "className",
  "careerSuccessAdvisorName",
]

const NEXT_GEN_ONLY_KEYS = ["language"]

function NotEmpty(props: {
  searchText: string
  tableModel: StudentsTableRow[]
  totalStudents: number
  programId: string
  programType: "legacy" | "next-gen"
}) {
  const { classId } = useParams()
  const { searchText, tableModel, totalStudents, programId, programType } =
    props
  const dispatch = useAppDispatch()
  const studentsNumberPresentationTitle =
    tableModel.length === totalStudents
      ? ` (${totalStudents})`
      : ` (${tableModel.length} / ${totalStudents})`

  const keys: (keyof StudentsTableRow)[] = [
    "name",
    "email",
    "className",
    "mentorName",
    "careerSuccessAdvisorName",
    "slackId",
    "language",
    "studentId",
  ].filter(
    (key) =>
      (programType === "legacy" &&
        !NEXT_GEN_ONLY_KEYS.includes(key as keyof StudentsTableRow)) ||
      (programType === "next-gen" &&
        !LEGACY_ONLY_KEYS.includes(key as keyof StudentsTableRow)),
  ) as (keyof StudentsTableRow)[]

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        overflow: "auto",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h5">
          Students
          <span
            style={{
              color: appTheme.palette.text.disabled,
            }}
          >
            {studentsNumberPresentationTitle}
          </span>
        </Typography>
        <OnboardStudentsButton
          classId={classId}
          programId={programId}
          buttonText="Onboard students"
          variant="contained"
          size="small"
          icon="chevron"
        />
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          pb: "16px",
        }}
      >
        <SearchTextField
          searchText={searchText}
          onSearchTextChange={(text) => dispatch(studentsSearchChanged(text))}
        />
        <GenericTable
          data={tableModel}
          keys={keys}
          sx={{
            borderRadius: "4px",
            overflow: "hidden",
          }}
          renderHeader={{
            studentId: () => undefined,
            slackId: () => <Typography variant="body2_sb">Slack</Typography>,
            mentorName: () => (
              <Typography variant="body2_sb">
                {programType === "legacy" ? "Mentor" : "ASM"}
              </Typography>
            ),
            careerSuccessAdvisorName: () => (
              <Typography variant="body2_sb">CSA</Typography>
            ),
          }}
          render={{
            studentId: (studentId, { name, classId: studentCurrentClass }) => (
              <StudentActionsMenu
                studentName={name}
                studentId={studentId}
                programId={programId}
                studentCurrentClass={studentCurrentClass}
                classId={classId}
                programType={programType}
              />
            ),
            slackId: (slackId) => <SlackIdCell slackId={slackId} />,
            mentorName: (mentorName) => (
              <Typography
                variant="body2"
                textOverflow={"ellipsis"}
                maxWidth="200px"
                noWrap
              >
                {mentorName}
              </Typography>
            ),
            careerSuccessAdvisorName: (advisorName) => (
              <Typography
                variant="body2"
                textOverflow={"ellipsis"}
                maxWidth="200px"
                noWrap
              >
                {advisorName}
              </Typography>
            ),
            className: (className) => (
              <Typography
                variant="body2"
                textOverflow={"ellipsis"}
                maxWidth="200px"
                noWrap
              >
                {className}
              </Typography>
            ),
            language: (language) =>
              language ? <LanguageChip language={language} /> : null,
          }}
        />
        {tableModel.length === 0 && (
          <Typography textAlign="center">No students match search</Typography>
        )}
      </Box>
    </Box>
  )
}

export default StudentsPage
