import { useAppDispatch } from "@app/hooks"
import { onboardStaffFormChanged } from "@features/account-management/accountManagementSlice"
import { Role } from "@features/login/loginSlice"
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material"
import { useEffect, useState } from "react"

export function OnboardStaffRoleSelection(props: { isAdmin: boolean }) {
  const { isAdmin } = props
  const dispatch = useAppDispatch()
  const [checkedState, setCheckedState] = useState({
    instructor: false,
    mentor: false,
    programOwner: false,
    accountManager: false,
    careerSuccessAdvisor: false,
    professionalMentor: false,
    tutor: false,
    academicSupportAdvisor: false,
    courseManager: false,
    careerConsultant: false,
  })
  const handleChange = (event: {
    target: { name: string; checked: boolean }
  }) => {
    setCheckedState({
      ...checkedState,
      [event.target.name]: event.target.checked,
    })
  }

  const handleTutorCheckboxChange = (event: {
    target: { name: string; checked: boolean }
  }) => {
    handleChange(event)
    if (!event.target.checked) {
      dispatch(
        onboardStaffFormChanged({ key: "tutorProperties", value: undefined }),
      )
    }
  }

  useEffect(() => {
    const roles: Role[] = []
    if (checkedState.instructor) roles.push(Role.Instructor)
    if (checkedState.mentor) roles.push(Role.Mentor)
    if (checkedState.programOwner) roles.push(Role.ProgramManager)
    if (checkedState.accountManager) roles.push(Role.AccountManager)
    if (checkedState.careerSuccessAdvisor) roles.push(Role.CareerSuccessAdvisor)
    if (checkedState.professionalMentor) roles.push(Role.ProfessionalMentor)
    if (checkedState.tutor) roles.push(Role.Tutor)
    if (checkedState.academicSupportAdvisor)
      roles.push(Role.AcademicSupportAdvisor)
    if (checkedState.courseManager) roles.push(Role.CourseManager)
    if (checkedState.careerConsultant) roles.push(Role.CareerConsultant)
    dispatch(
      onboardStaffFormChanged({
        key: "additionalRoles",
        value: roles,
      }),
    )
  }, [checkedState, dispatch])

  const { column1Roles, column2Roles } = getRoleColumns(
    checkedState,
    handleChange,
    handleTutorCheckboxChange,
    isAdmin,
  )

  return (
    <FormGroup>
      <Grid container>
        <RoleColumn roles={column1Roles} />
        <RoleColumn roles={column2Roles} />
      </Grid>
    </FormGroup>
  )
}

function RoleColumn({ roles }: { roles: Array<RoleProps> }) {
  return (
    <Grid item xs={6}>
      <Grid container direction="column">
        {roles.map((role) => (
          <Grid item key={role.name}>
            <RoleCheckbox {...role} />
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}

function RoleCheckbox({ name, label, tooltip, checked, onChange }: RoleProps) {
  const checkbox = (
    <FormControlLabel
      control={<Checkbox checked={checked} onChange={onChange} name={name} />}
      label={label}
    />
  )

  return tooltip ? (
    <Tooltip
      enterDelay={750}
      title={
        <Typography
          variant="body2"
          sx={{
            padding: "8px 16px",
            color: "white",
          }}
        >
          {tooltip}
        </Typography>
      }
    >
      {checkbox}
    </Tooltip>
  ) : (
    checkbox
  )
}

type RoleProps = {
  name: string
  label: string
  tooltip?: string
  checked: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

function getRoleColumns(
  checkedState: { [key: string]: boolean },
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  handleTutorCheckboxChange: (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => void,
  isAdmin: boolean,
): { column1Roles: RoleProps[]; column2Roles: RoleProps[] } {
  const column1Roles = [
    {
      name: "instructor",
      label: "Instructor",
      tooltip: "Instructor will be registered in the campus as an instructor.",
      checked: checkedState.instructor,
      onChange: handleChange,
    },
    {
      name: "tutor",
      label: "Tutor",
      checked: checkedState.tutor,
      onChange: handleTutorCheckboxChange,
    },
    {
      name: "professionalMentor",
      label: "Professional mentor",
      checked: checkedState.professionalMentor,
      onChange: handleChange,
    },
    isAdmin && {
      name: "accountManager",
      label: "Account manager",
      tooltip:
        "Account Manager will have access to the account management section in the LMS",
      checked: checkedState.accountManager,
      onChange: handleChange,
    },
    {
      name: "careerConsultant",
      label: "Career consultant",
      checked: checkedState.careerConsultant,
      onChange: handleChange,
    },
  ].filter(Boolean) as RoleProps[]

  const column2Roles = [
    {
      name: "mentor",
      label: "Mentor",
      checked: checkedState.mentor,
      onChange: handleChange,
    },
    {
      name: "careerSuccessAdvisor",
      label: "Career success advisor",
      checked: checkedState.careerSuccessAdvisor,
      onChange: handleChange,
    },
    {
      name: "academicSupportAdvisor",
      label: "Academic support advisor",
      checked: checkedState.academicSupportAdvisor,
      onChange: handleChange,
    },
    {
      name: "programOwner",
      label: "Program owner",
      tooltip:
        "Program owner will have access to the programs dashboard in the LMS",
      checked: checkedState.programOwner,
      onChange: handleChange,
    },
    {
      name: "courseManager",
      label: "Course manager",
      checked: checkedState.courseManager,
      onChange: handleChange,
    },
  ]

  return { column1Roles, column2Roles }
}
