import { GenericDialog } from "@masterschool/ui"
import appIcons from "@utils/appIcons"
import { useState } from "react"

export const PublishTrackVersionPopup = (props: {
  onClose: () => void
  onSubmitted: () => Promise<void>
}) => {
  const { onClose, onSubmitted } = props
  const [loading, setLoading] = useState(false)
  return (
    <GenericDialog
      onClose={onClose}
      open={true}
      closeIcon={appIcons.xClose}
      size="xs"
      title="Publish this version?"
      subtitle="The version will be visible to all users in the track repository."
      buttons={[
        {
          type: "secondary",
          text: "Cancel",
          onClick: onClose,
        },
        {
          type: "primary",
          text: "Publish",
          onClick: async () => {
            setLoading(true)
            await onSubmitted()
            setLoading(false)
          },
          loading: loading,
        },
      ]}
    />
  )
}
