import { RadioGroup, Radio, FormControl, FormControlLabel } from "@mui/material"
import { useAppDispatch, useAppSelector } from "@app/hooks"
import { editRegistrationLanguagePopupClosed } from "@features/program/programSlice"
import appIcons from "@utils/appIcons"
import { useState } from "react"
import { GenericDialog } from "@masterschool/ui"
import { CampusClient } from "@clients/campusClient"
import { showErrorSnackbar, showSuccessSnackbar } from "@features/ui/uiSlice"
import { fetchClasses } from "@features/program/programSliceThunks"

export const EditRegistrationLanguagePopup = () => {
  const model = useAppSelector(
    (state) => state.program.studentsPage.editRegistrationLanguagePopup,
  )
  const dispatch = useAppDispatch()
  const [selectedLanguage, setSelectedLanguage] = useState<string | null>(null)
  const onSubmit = () => {
    if (!model?.studentId || !model?.classId || !selectedLanguage) {
      return
    }
    CampusClient.put("user/school/update-language", {
      userId: model.studentId,
      classId: model.classId,
      language: selectedLanguage,
    })
      .then((success) => {
        if (success) {
          dispatch(editRegistrationLanguagePopupClosed())
          dispatch(fetchClasses({ programId: model.programId }))
          dispatch(
            showSuccessSnackbar(
              "Language updated successfully to " + selectedLanguage,
            ),
          )
        } else {
          dispatch(showErrorSnackbar("Failed to update language"))
        }
      })
      .catch((e) => {
        dispatch(showErrorSnackbar("Failed to update language: " + e.message))
      })
  }

  const onClose = () => {
    setSelectedLanguage(null)
    dispatch(editRegistrationLanguagePopupClosed())
  }

  if (!model) return null

  return (
    <GenericDialog
      open={model !== undefined}
      size="sm"
      closeIcon={appIcons.xClose}
      title={`Change language for ${model?.studentName}?`}
      subtitle="This will affect future courses registrations and program homepage."
      content={
        <FormControl>
          <RadioGroup
            row
            value={selectedLanguage}
            onChange={(e) => {
              setSelectedLanguage(e.target.value)
            }}
            sx={{ gap: "150px" }}
          >
            <FormControlLabel
              value="english"
              control={<Radio />}
              label="🇬🇧 English"
            />
            <FormControlLabel
              value="german"
              control={<Radio />}
              label="🇩🇪 German"
            />
          </RadioGroup>
        </FormControl>
      }
      onClose={onClose}
      buttons={[
        {
          text: "Change language",
          type: "primary",
          onClick: onSubmit,
        },
        {
          text: "Cancel",
          type: "secondary",
          onClick: onClose,
        },
      ]}
    />
  )
}
