import GenericDialog from "@cmp/genericDialog"

function CampusPreviewPopup(props: {
  id: string
  name: string
  idType: "course" | "syllabus"
  onClose: () => void
  version?: string | number
  programId?: string
}) {
  const { id, name, idType, version, programId, onClose } = props
  const masterschoolCampusUrl = import.meta.env.VITE_CAMPUS_URL
  const iframeURL = new URL(`${masterschoolCampusUrl}/learn/${idType}/${id}`)
  if (version) {
    iframeURL.searchParams.append("version", version.toString())
  }
  if (programId) {
    iframeURL.searchParams.append("programId", programId)
  }

  return (
    <GenericDialog
      open
      onClose={onClose}
      size="lg"
      fullHeight
      disableContentPadding
      floatingCloseButton
      content={
        <iframe
          title={name}
          width="100%"
          height="100%"
          src={iframeURL.toString()}
          style={{ border: "none" }}
        />
      }
    />
  )
}

export default CampusPreviewPopup
