import { CampusClient } from "./campusClient"

export enum CourseLanguage {
  English = "English",
  German = "German",
}

export type CourseInstanceDto = {
  id: string
  courseId: string
  majorVersion: number | undefined
  termId: string
}

export type CourseClassDto = {
  name: string
  id: string
  language: CourseLanguage
  courseInstanceId: string
  courseId: string
  majorVersion: number | undefined
  startDate: Date
  registeredStudentIds: string[]
}

export class CourseInstanceClient {
  static async get(courseInstanceId: string): Promise<CourseInstanceDto> {
    return CampusClient.get<CourseInstanceDto>(
      `courseInstance/${courseInstanceId}`,
    )
  }

  static getByTerm(
    courseId: string,
    termId: string,
  ): Promise<CourseInstanceDto[]> {
    return CampusClient.get<CourseInstanceDto[]>(
      `courseInstance/termId/${termId}/courseId/${courseId}`,
    )
  }

  static async listCoursesHavingInstances(): Promise<string[]> {
    return CampusClient.get<string[]>(
      "courseInstance/list-courses-having-instances",
    )
  }

  static async listAllCourseInstances(): Promise<CourseInstanceDto[]> {
    return CampusClient.get<CourseInstanceDto[]>(
      "courseInstance/list-all-instances",
    )
  }

  static async listAllCourseClasses(): Promise<CourseClassDto[]> {
    return CampusClient.get<CourseClassDto[]>("courseInstance/list-all-classes")
  }
}
