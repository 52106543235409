import { CampusClient } from "./campusClient"

export type TermDto = {
  endDate: string
  id: string
  startDate: string
}

export class TermClient {
  static listTerms(filter: {
    startDate: Date
    endDate?: Date
    limit?: number
  }): Promise<TermDto[]> {
    const { startDate, endDate, limit } = filter
    const params = new URLSearchParams()
    params.append("startDate", startDate.toISOString())
    if (endDate) {
      params.append("endDate", endDate.toISOString())
    }
    if (limit) {
      params.append("limit", limit.toString())
    }
    return CampusClient.get<TermDto[]>(`term/list?${params.toString()}`).then(
      (terms) =>
        terms.map((term) => ({
          id: term.id,
          startDate: term.startDate,
          endDate: term.endDate,
        })),
    )
  }
}
