import OptionsButton2 from "@cmp/buttons/optionsButton2"
import {
  CourseDescriptorDto,
  CourseStatus,
  ProgramDomain,
} from "@masterschool/course-builder-api"
import {
  Box,
  Chip,
  IconButton,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material"
import appIcons from "@utils/appIcons"
import { domainToColor, domainToDisplayName } from "@utils/domainUtils"
import { capitalizeFirstLetter, uuidToShortId } from "../utils"
import AppIcon from "@utils/appIcon"
import { IconType } from "@utils/iconType"
import { useAppDispatch, useAppSelector } from "@app/hooks"
import { toggleIsCourseFavorite } from "@features/coursesMenu/coursesMenuSlice"
import useCoursesTableMenuActions from "./useCoursesTableMenu"

export function NameCell({ course }: { course: CourseDescriptorDto }) {
  return (
    <Stack sx={{ height: "100%", gap: "4px", justifyContent: "center" }}>
      <Typography variant="body2">{course.title}</Typography>
      <Typography variant="body3" color={"text.secondary"}>
        {course.name}
      </Typography>
    </Stack>
  )
}

export function CourseIdCell({ courseId }: { courseId: string }) {
  const shortId = uuidToShortId(courseId)
  return (
    <Stack sx={{ height: "100%", justifyContent: "center" }}>
      <Typography variant="body2">{shortId}</Typography>
    </Stack>
  )
}

export function SchoolCell({ course }: { course: CourseDescriptorDto }) {
  return (
    <Stack
      direction="row"
      sx={{
        height: "100%",
        gap: "8px",
        alignItems: "center",
      }}
    >
      {course.domains.map((domain) => (
        <Chip
          key={domain}
          label={domainToDisplayName(domain)}
          size="small"
          sx={{
            backgroundColor: domainToColor(domain as ProgramDomain),
          }}
        />
      ))}
    </Stack>
  )
}

export function StatusCell({ status }: { status: CourseStatus }) {
  return (
    <Chip
      label={capitalizeFirstLetter(status)}
      size="small"
      variant="pastel"
      color={statusToColor(status)}
    />
  )
}

export function MiscCell({ course }: { course: CourseDescriptorDto }) {
  return (
    <Stack
      direction="row"
      sx={{
        "&:hover .favorite-cell": {
          visibility: "visible",
        },
      }}
    >
      <FavoriteCell courseId={course.id} />
      <ActionsCell course={course} />
    </Stack>
  )
}

function FavoriteCell({ courseId }: { courseId: string }) {
  const dispatch = useAppDispatch()
  const isFavorite = useAppSelector(
    (state) => state.coursesMenu.favoriteCourses,
  ).includes(courseId)

  const onFavoriteClicked = () => {
    dispatch(
      toggleIsCourseFavorite({
        courseId,
      }),
    )
  }

  return (
    <Box
      className="favorite-cell"
      sx={{
        visibility: isFavorite ? "visible" : "hidden",
      }}
    >
      <IconButton
        sx={{ padding: "6px", borderRadius: "50%" }}
        onClick={(e) => {
          e.stopPropagation()
          onFavoriteClicked()
        }}
      >
        <SvgIcon
          sx={{
            color: "icon.default",
            width: 20,
            height: 20,
            fill: isFavorite ? "#FFB400" : "eTypes.sand100",
          }}
          stroke={isFavorite ? "none" : "currentColor"}
          inheritViewBox
          component={appIcons.star01}
        />
      </IconButton>
    </Box>
  )
}

function ActionsCell({ course }: { course: CourseDescriptorDto }) {
  const {
    onOpenClicked,
    onEditCourseDetailsClicked,
    onDuplicateClicked,
    onArchiveClicked,
  } = useCoursesTableMenuActions(course)
  return (
    <OptionsButton2
      buttonModel={{
        type: "icon",
        props: {
          sx: {
            marginLeft: "auto",
          },
          size: "small",
          children: <AppIcon icon={appIcons.dotsPretty} />,
        },
      }}
      items={[
        {
          type: "menu-item",
          props: {
            key: "open",
            children: <IconTextMenuItem text="Open" icon={appIcons.logIn02} />,
            onClick: onOpenClicked,
          },
        },
        {
          type: "menu-item",
          props: {
            key: "edit-course",
            children: (
              <IconTextMenuItem text="Edit course" icon={appIcons.pencilLine} />
            ),
            onClick: () => onEditCourseDetailsClicked(),
          },
        },
        {
          type: "menu-item",
          props: {
            key: "duplicate-course",
            children: (
              <IconTextMenuItem text="Duplicate" icon={appIcons.copy04} />
            ),
            onClick: onDuplicateClicked,
          },
        },
        {
          type: "menu-item",
          props: {
            key: "archive-course",
            children: (
              <IconTextMenuItem text="Archive" icon={appIcons.trash03} />
            ),
            onClick: onArchiveClicked,
            disabled: course.status === CourseStatus.Archived,
          },
        },
      ]}
    />
  )
}

function statusToColor(status: CourseStatus) {
  switch (status) {
    case CourseStatus.Archived:
      return "error"
    case CourseStatus.Draft:
      return "default"
    case CourseStatus.Published:
      return "success"
  }
}

export function IconTextMenuItem(props: { text: string; icon: IconType }) {
  return (
    <Stack direction="row" gap="8px" alignItems="center">
      <AppIcon icon={props.icon} />
      <Typography variant="body2">{props.text}</Typography>
    </Stack>
  )
}
