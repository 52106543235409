import { useAppDispatch, useAppSelector } from "@app/hooks"
import GenericDialog from "@cmp/genericDialog"
import {
  programTransferStudentPopupClosed,
  programTransferStudentUpdated,
} from "@features/program/programSlice"
import {
  selectClassesForProgram,
  selectProgramsWithTracks,
} from "@features/program/programSliceSelectors"
import {
  changeStudentProgram,
  fetchClasses,
} from "@features/program/programSliceThunks"
import { ChangeProgramClassRequestChangeTypeEnum } from "@masterschool/course-builder-api"
import { Alert, Stack, TextField, Typography } from "@mui/material"
import { formatDateAsYearAndLongMonth } from "@utils/date"
import useFetchProgramData from "../../../useFetchProgramData"
import { SingleItemSelection } from "./transferStudentCommon"
import { mapToChangeType } from "./transferStudentUtils"
import { domainToDisplayName } from "@utils/domainUtils"

function ProgramTransferStudentPopup() {
  const dispatch = useAppDispatch()
  const model = useAppSelector(
    (state) => state.program.studentsPage.programTransferStudentPopup,
  )

  useFetchProgramData(model?.form.selectedProgramId)
  const programs = useAppSelector(selectProgramsWithTracks)
  const sortedPrograms = [...programs].sort(
    (a, b) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime(),
  )

  const classes = useAppSelector(
    selectClassesForProgram(model?.form.selectedProgramId),
  )

  const onSubmit = () => {
    if (
      !model ||
      !model.form.reason ||
      !model.form.selectedProgramId ||
      !model.form.selectedClassId
    ) {
      return
    }
    const selectedProgramId = model.form.selectedProgramId
    dispatch(changeStudentProgram())
      .then(() => {
        dispatch(fetchClasses({ programId: selectedProgramId }))
        dispatch(fetchClasses({ programId: model.programId }))
      })
      .finally(() => dispatch(programTransferStudentPopupClosed()))
  }

  const content = (
    <Stack gap={3}>
      {model?.loadingState === "rejected" && (
        <Alert
          severity="error"
          sx={{
            alignItems: "center",
            ".MuiAlert-icon": {
              display: "flex",
              alignItems: "center",
            },
          }}
        >
          <Typography variant="body2">
            An error occurred while trying to transfer the student.
          </Typography>
        </Alert>
      )}
      <SingleItemSelection
        onChange={(selectedProgramId: string) =>
          dispatch(
            programTransferStudentUpdated({
              key: "selectedProgramId",
              value: selectedProgramId,
            }),
          )
        }
        transformToValue={(value) => value}
        selectionLabel="Program"
        initialValue={model?.form.selectedProgramId ?? ""}
        getIsOptionDisabled={(value) => value === model?.programId}
        items={sortedPrograms.map((p) => ({
          value: p.id,
          label:
            `${formatDateAsYearAndLongMonth(
              p.startDate,
            )}, ${domainToDisplayName(p.domain)}` +
            `${p.track !== undefined ? `: ${p.track.internalName}` : ""}` +
            `${p.id === model?.programId ? " (current student program)" : ""}`,
        }))}
      />
      <SingleItemSelection
        onChange={(selectedClassId: string) =>
          dispatch(
            programTransferStudentUpdated({
              key: "selectedClassId",
              value: selectedClassId,
            }),
          )
        }
        transformToValue={(value) => value}
        selectionLabel="Class"
        initialValue={model?.form.selectedClassId ?? ""}
        disabled={!model?.form.selectedProgramId}
        items={classes.map((c) => ({
          value: c.id,
          label: `${c.name}`,
        }))}
      />
      <SingleItemSelection
        onChange={(reason: ChangeProgramClassRequestChangeTypeEnum) =>
          dispatch(
            programTransferStudentUpdated({ key: "reason", value: reason }),
          )
        }
        transformToValue={mapToChangeType}
        selectionLabel="Reason"
        initialValue={model?.form.reason}
        items={[
          {
            value: ChangeProgramClassRequestChangeTypeEnum.StudentAsked,
            label: "Student asked",
          },
          {
            value: ChangeProgramClassRequestChangeTypeEnum.StaffDecision,
            label: "Staff decision",
          },
          {
            value: ChangeProgramClassRequestChangeTypeEnum.Graduate,
            label: "Graduated",
          },
        ]}
      />
      <TextField
        defaultValue={model?.form.summary ?? ""}
        onChange={(event) => {
          dispatch(
            programTransferStudentUpdated({
              key: "summary",
              value: event.target.value,
            }),
          )
        }}
        variant="outlined"
        rows={3}
        multiline
        fullWidth
        label="Summary"
        InputLabelProps={{
          style: { fontSize: "17px" },
        }}
      />
      <Alert
        severity="info"
        sx={{
          alignItems: "center",
          ".MuiAlert-icon": {
            display: "flex",
            alignItems: "center",
          },
        }}
      >
        <Typography variant="body2">
          This student will be taken out of all relevant previous Slack channels
          and added to all Slack channels associated with the new program.
        </Typography>
      </Alert>
    </Stack>
  )

  return (
    <GenericDialog
      open={model !== undefined}
      size="sm"
      title={`Transfer ${model?.studentName} to a different program`}
      content={content}
      onClose={() => {
        dispatch(programTransferStudentPopupClosed())
      }}
      buttons={[
        {
          text: "Transfer student",
          type: "primary",

          onClick: onSubmit,

          disabled:
            !model?.form.selectedClassId ||
            !model?.form.reason ||
            !model?.form.selectedProgramId,
          loading: model?.loadingState === "loading",
        },
      ]}
    />
  )
}

export default ProgramTransferStudentPopup
