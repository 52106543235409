import CoursePreview from "../../editor/coursePreview"
import { useEffect, useState } from "react"
import { CourseClient } from "@clients/courseClient"
import { CourseDto } from "@masterschool/course-builder-api"
import { useAppSelector } from "@app/hooks"
import { selectLatestPublishedCourse } from "@features/coursesMenu/coursesSelectors"
import { Stack } from "@mui/material"
import { parseStringVersion } from "@utils/versionUtils"
import { LoadingComponent } from "@cmp/loaders/loadingComponent"
import { selectCourseInstance } from "@features/courseInstance/courseInstanceSelectors"

export const CourseInstanceContent = (props: {
  courseInstanceId: string | undefined
}) => {
  const [courseDto, setCourseDto] = useState<CourseDto | undefined>(undefined)
  const courseInstance = useAppSelector((state) =>
    selectCourseInstance(state, props.courseInstanceId),
  )
  const courseId = courseInstance?.courseId || ""
  const courseLatestPublishedMajor = useAppSelector(
    selectLatestPublishedCourse(courseId),
  )
  const shouldTakeLatestMajor = courseInstance && !courseInstance?.majorVersion
  const latestMajorForCourse = courseLatestPublishedMajor
    ? parseStringVersion(courseLatestPublishedMajor.version).major
    : undefined

  const courseMajorVersion = shouldTakeLatestMajor
    ? latestMajorForCourse
    : courseInstance?.majorVersion

  useEffect(() => {
    if (!courseId || !courseMajorVersion) {
      return
    }
    CourseClient.getLastPublishedVersion(courseId, courseMajorVersion).then(
      (course) => {
        setCourseDto(course)
      },
    )
  }, [courseId, courseMajorVersion])

  if (!courseDto) return <LoadingComponent loadedComponents="content" />

  return (
    <Stack sx={{ width: "720px", height: "100%" }}>
      <CoursePreview course={courseDto} />
    </Stack>
  )
}
