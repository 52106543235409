import {
  TermDto,
  TermDtoLockMethodEnum,
  TrackDtoStatusEnum,
  UnitCourseDescriptor,
} from "@masterschool/course-builder-api"
import { useAutoSaveTrack } from "./useAutoSaveTrack"
import { publishTrack } from "@features/tracks/trackSlice"
import { useAppDispatch } from "@app/hooks"

export const useEditTrack = (trackId: string | undefined) => {
  const { currentTrack, onTrackChange, onSaveTrack } = useAutoSaveTrack(trackId)
  const dispatch = useAppDispatch()

  if (!currentTrack) {
    return {
      currentTrack: null,
      onAddTerm: () => {},
      onRemoveTerm: () => {},
      onReorderTerm: () => {},
      onChangeTermCourses: () => {},
      onPublish: () => {},
      canPublish: false,
    }
  }

  const onAddTerm = () => {
    const emptyTerm: TermDto = {
      id: window.crypto.randomUUID(),
      courseDescriptors: [],
      name: "",
      lockMethod: TermDtoLockMethodEnum.ByDate,
    }
    const newTermsList = [...currentTrack.terms, emptyTerm]
    onTrackChange({
      ...currentTrack,
      terms: newTermsList,
    })
  }

  const onRemoveTerm = (termId: string) => {
    const newTerms = currentTrack.terms.filter((term) => term.id !== termId)
    const newTrack = { ...currentTrack, terms: newTerms }
    onTrackChange(newTrack)
  }

  const onReorderTerm = (fromIndex: number, toIndex: number) => {
    const newTerms = [...currentTrack.terms]
    const [removedTerm] = newTerms.splice(fromIndex, 1)
    newTerms.splice(toIndex, 0, removedTerm)
    const newTrack = { ...currentTrack, terms: newTerms }
    onTrackChange(newTrack)
  }

  const onChangeTermCourses = (
    termId: string,
    courseDescriptors: UnitCourseDescriptor[],
  ) => {
    const newTerms = currentTrack.terms.map((term) => {
      if (term.id === termId) {
        return { ...term, courseDescriptors }
      }
      return term
    })
    const newTrack = { ...currentTrack, terms: newTerms }
    onTrackChange(newTrack)
  }

  const onPublish = async () => {
    await onSaveTrack(currentTrack)
    await dispatch(publishTrack(currentTrack.id))
  }
  const canPublish = currentTrack.status === TrackDtoStatusEnum.Draft

  return {
    currentTrack,
    onAddTerm,
    onRemoveTerm,
    onReorderTerm,
    onChangeTermCourses,
    onPublish,
    canPublish,
  }
}
