import { useAppSelector } from "@app/hooks"
import {
  selectCourseInstanceByIdentifiers,
  selectInstancesByCourseId,
} from "@features/courseInstance/courseInstanceSelectors"
import useSpecificSearchParam from "@utils/hooks/useSpecificSearchParams"
import { useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"

export const CourseInstanceRedirect = () => {
  const { courseId } = useParams()
  const [termIdFromParam] = useSpecificSearchParam("termId")
  const navigate = useNavigate()
  const courseInstances = useAppSelector((state) =>
    selectInstancesByCourseId(state, courseId),
  )
  const selectedInstance = useAppSelector((state) =>
    selectCourseInstanceByIdentifiers(
      state,
      courseId,
      termIdFromParam,
      undefined,
    ),
  )

  useEffect(() => {
    if (!courseId) {
      return
    }
    if (selectedInstance) {
      navigate(`/course-manager/course-instance/${selectedInstance.id}`)
      return
    } else if (courseInstances.length > 0) {
      const instance = courseInstances[0]
      navigate(`/course-manager/course-instance/${instance.id}`)

      return
    }
  }, [courseId, navigate, selectedInstance, courseInstances])

  return null
}
