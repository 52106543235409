import { CampusClient } from "./campusClient"

export class ProgramManualUnlockClient {
  static async unlockNextSprint(
    userClientId: string,
    forceUnlock = true,
  ): Promise<void> {
    const url = `/manual-unlock/user/${userClientId}/sprint/next`
    const query = forceUnlock ? "?forceUnlock=true" : ""
    return CampusClient.put<void>(`${url}${query}`, {})
  }

  static unlockCourseNextWeek(
    userClientId: string,
    courseInstanceId: string,
  ): Promise<void> {
    const url = `/manual-unlock/user/${userClientId}/course/next`
    const query = `?courseInstanceId=${courseInstanceId}`
    return CampusClient.put<void>(`${url}${query}`, {})
  }

  static unlockNextWeekForAllRunningCourses(
    userClientId: string,
  ): Promise<void> {
    const url = `/manual-unlock/user/${userClientId}/running-courses/next`
    return CampusClient.put<void>(url, {})
  }
}
