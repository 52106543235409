import { useAppDispatch } from "@app/hooks"
import { CourseStatus } from "@masterschool/course-builder-api"
import { useNavigate } from "react-router-dom"
import { createDraftForCourse } from "@features/coursesMenu/coursesMenuSlice"

export function useEditCourseClicked(syllabusId?: string) {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const pathPrefix = syllabusId
    ? `/syllabus-edit/${syllabusId}`
    : "/course-edit"

  return (
    courseId: string,
    majorVersion: number,
    courseStatus: CourseStatus,
  ) => {
    if (courseStatus === CourseStatus.Draft) {
      navigate(`${pathPrefix}/${courseId}`)
    } else if (courseStatus === CourseStatus.Published) {
      dispatch(createDraftForCourse({ courseId, majorVersion })).then(() => {
        navigate(`${pathPrefix}/${courseId}`)
      })
    }
  }
}
