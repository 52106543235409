import { GridColDef } from "@mui/x-data-grid-pro"
import { dateStrComparator } from "../../tab-views/students-tab/students-grid/columns/column.sort.comparators"
import {
  CELLS_TYPOGRAPHY_STYLE,
  StartDateCell,
  StatusCell,
} from "./programsMenuCells"
import { NextGenProgramData } from "./nextGenProgramsMenuTable"
import { schoolOptions } from "../../tab-views/students-tab/students-grid/columns/registration.details.columns"
import { domainToDisplayName } from "@utils/domainUtils"
import { IconTextMenuItem } from "../../main/courses-table/course-table/coursesCells"
import appIcons from "@utils/appIcons"
import { Stack, SvgIcon, Typography } from "@mui/material"
import OptionsButton2 from "@cmp/buttons/optionsButton2"
import { useNavigate } from "react-router-dom"
import { DomainChip } from "@utils/domainChip"
import { statusForProgram } from "../program/components/programStatusChip"

export const getNextGenProgramsMenuColumns = () => {
  return [
    getTrackColumn(),
    getCohortColumn(),
    getSchoolColumn(),
    getDurationColumn(),
    getStatusColumn(),
    getStudentsCount(),
    getActionsColumn(),
  ]
}

const getSchoolColumn: () => GridColDef<NextGenProgramData> = () => {
  return {
    field: "domain",
    headerName: "School",
    headerClassName: "domain-header",
    type: "singleSelect",
    valueOptions: schoolOptions,
    width: 192,
    valueGetter: (value) => domainToDisplayName(value),
    renderCell: (params) => (
      <Stack direction="row" sx={CELLS_TYPOGRAPHY_STYLE}>
        <DomainChip domain={params.row.domain} />
      </Stack>
    ),
  }
}

const getCohortColumn: () => GridColDef<NextGenProgramData> = () => {
  return {
    field: "startDate",
    headerName: "Cohort",
    width: 198,
    type: "singleSelect",
    valueOptions: cohortOptions(),
    valueGetter: (value) => {
      if (!value) return null
      return new Date(value).toLocaleString("en-US", {
        month: "long",
        year: "numeric",
      })
    },
    getSortComparator(sortDirection) {
      return (v1, v2) => dateStrComparator(v1, v2, sortDirection)
    },
    renderCell: (params) => (
      <StartDateCell startDate={new Date(params.row.startDate)} />
    ),
  }
}

const getTrackColumn: () => GridColDef<NextGenProgramData> = () => {
  return {
    field: "track",
    headerName: "Track",
    width: 198,
    valueGetter: (value: NextGenProgramData["track"]) => {
      return value ? value.externalName : null
    },
    renderCell: (params) => (
      <Stack direction="row" sx={CELLS_TYPOGRAPHY_STYLE}>
        <Typography variant="body2">
          {params.row.track?.externalName}
        </Typography>
      </Stack>
    ),
  }
}

const getDurationColumn: () => GridColDef<NextGenProgramData> = () => {
  return {
    field: "durationInMonths",
    headerName: "Duration",
    width: 198,
    type: "singleSelect",
    valueOptions: [1, 8, 14],
    renderCell: (params) => (
      <Stack direction="row" sx={CELLS_TYPOGRAPHY_STYLE}>
        <Typography variant="body2">
          {params.row.durationInMonths} terms
        </Typography>
      </Stack>
    ),
  }
}

const getStudentsCount: () => GridColDef<NextGenProgramData> = () => {
  return {
    field: "studentsCount",
    headerName: "Students",
    width: 172,
    type: "number",
    headerAlign: "left",
    renderCell: (params) => (
      <Stack direction="row" sx={CELLS_TYPOGRAPHY_STYLE}>
        <Typography variant="body2">{params.row.studentsCount}</Typography>
      </Stack>
    ),
  }
}

const getStatusColumn: () => GridColDef<NextGenProgramData> = () => {
  return {
    field: "status",
    headerName: "Status",
    width: 198,
    type: "singleSelect",
    valueOptions: ["Active", "Upcoming", "Ended"],
    valueGetter: (_, row) =>
      statusForProgram(new Date(row.startDate), row.durationInMonths),
    renderCell: (params) => {
      const { durationInMonths, startDate } = params.row
      return (
        <StatusCell
          startDate={new Date(startDate)}
          durationInMonths={durationInMonths}
        />
      )
    },
    notSortable: true,
  }
}

export const getActionsColumn: () => GridColDef<NextGenProgramData> = () => {
  return {
    field: "actions",
    headerName: "",
    type: "actions",
    width: 48,
    sortable: false,
    renderCell: (params) => (
      <NextGenProgramActionsCell programId={params.row.id} />
    ),
  }
}

function cohortOptions() {
  const firstEverNextGenCohort = new Date("2025-01-01")
  const next6Months = Array.from({ length: 6 }, (_, i) => {
    const date = new Date(firstEverNextGenCohort)
    date.setMonth(date.getMonth() + i)
    return date
  })
  return next6Months.map((date) =>
    date.toLocaleString("en-US", {
      month: "long",
      year: "numeric",
    }),
  )
}

function NextGenProgramActionsCell({ programId }: { programId: string }) {
  const navigate = useNavigate()
  return (
    <Stack direction="row" sx={CELLS_TYPOGRAPHY_STYLE}>
      <OptionsButton2
        buttonModel={{
          type: "icon",
          props: {
            sx: {
              marginLeft: "auto",
              display: "flex",
            },
            size: "small",
            children: (
              <SvgIcon
                component={appIcons.dotsHorizontal}
                inheritViewBox
                sx={{ color: "primary.main" }}
              />
            ),
          },
        }}
        items={[
          {
            type: "menu-item",
            props: {
              key: "open-program",
              children: (
                <IconTextMenuItem text="Open" icon={appIcons.logIn02} />
              ),
              onClick: () => navigate(`/program/${programId}`),
            },
          },
          {
            type: "menu-item",
            props: {
              key: "preview-on-campus",
              children: (
                <IconTextMenuItem
                  text="Preview on campus"
                  icon={appIcons.eye}
                />
              ),
              disabled: true,
            },
          },
        ]}
      />
    </Stack>
  )
}
