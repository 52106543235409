import {
  CourseDto,
  CourseSyllabusElement,
} from "@masterschool/course-builder-api"

export const areCourseDtosEqual = (dto1: CourseDto, dto2: CourseDto) => {
  return (
    JSON.stringify(extractCourseDtoFieldsToCompare(dto1)) ===
    JSON.stringify(extractCourseDtoFieldsToCompare(dto2))
  )
}

const extractCourseDtoFieldsToCompare = (course: CourseDto) => {
  return {
    id: course.id,
    name: course.name,
    title: course.title,
    shortTitle: course.shortTitle,
    description: course.description,
    syllabus: course.syllabus,
    domains: course.domains,
    createdBy: course.createdBy,
  }
}

function isItemWithDuration<T extends object>(
  element: T,
): element is T & { estimatedDuration: number } {
  return (element as any)?.estimatedDuration !== undefined
}

function isItemWithTasks<T extends object>(
  element: T,
): element is T & { tasks: any[] } {
  return (element as any)?.tasks !== undefined
}

export function calculateElementsTotalDuration(
  elements: CourseSyllabusElement[],
) {
  const durations = elements.map((element) => {
    if (isItemWithDuration(element.item)) {
      return element.item.estimatedDuration
    }
    if (isItemWithTasks(element.item)) {
      const tasksDurations = element.item.tasks.map((task) => {
        return isItemWithDuration(task) ? task.estimatedDuration : 0
      })
      return tasksDurations.msSum()
    }
    return 0
  })
  return durations.msSum()
}
