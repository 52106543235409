import { useAppDispatch, useAppSelector } from "@app/hooks"
import GenericDialog from "@cmp/genericDialog"
import {
  createProgramPopupClosed,
  createProgramPopupFormUpdated,
} from "@features/program/programSlice"
import { createLegacyProgram } from "@features/program/programSliceThunks"
import ProgramDetailsForm from "../program/components/programDetailsForm"

function CreateProgramPopup() {
  const model = useAppSelector((state) => state.program.createProgramPopup)
  const dispatch = useAppDispatch()
  const isActionDisabled =
    !model ||
    !model.form ||
    !model.form.domains ||
    !model.form.type ||
    !model.form.language ||
    !model.form.startDate ||
    !model.form.durationInMonths ||
    model.state === "pending"

  return (
    <GenericDialog
      open={model !== undefined}
      size="xs"
      onClose={() => dispatch(createProgramPopupClosed())}
      title="Add program(s)"
      content={
        model && (
          <ProgramDetailsForm
            {...model.form}
            onChange={(payload) =>
              dispatch(createProgramPopupFormUpdated(payload))
            }
            allowMultipleDomains
          />
        )
      }
      buttons={[
        {
          type: "secondary",
          text: "Cancel",
          onClick: () => dispatch(createProgramPopupClosed()),
        },
        {
          type: "primary",
          text: "Add program",
          onClick: () => dispatch(createLegacyProgram()),
          disabled: isActionDisabled,
          loading: model?.state === "pending",
        },
      ]}
    />
  )
}

export default CreateProgramPopup
