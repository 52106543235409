import { Button, ButtonProps, SvgIcon } from "@mui/material"
import appIcons from "@utils/appIcons"
import appTheme from "../../theme/appTheme"

export function AddItemButton(props: ButtonProps) {
  return (
    <Button
      variant="contained"
      style={{
        width: "fit-content",
        height: "fit-content",
      }}
      size="small"
      startIcon={
        <SvgIcon
          component={appIcons.plus}
          sx={{
            stroke: appTheme.palette.primary.contrastText,
            fill: "inherit",
            width: "20px",
            height: "20px",
          }}
          inheritViewBox
        />
      }
      {...props}
    >
      {props.children}
    </Button>
  )
}
