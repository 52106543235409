import { useAppDispatch, useAppSelector } from "@app/hooks"
import { AddItemButton } from "@cmp/buttons/addItemButton"
import { SortItemsButton } from "@cmp/buttons/sortItemsButton"
import { selectMainPageSyllabuses } from "@features/search/searchSlice"
import {
  selectSyllabusesMainPageTab,
  selectSyllabusesSortOption,
} from "@features/syllabus/syllabusSelectors"
import {
  SyllabusIdentifierEnumeration,
  create,
  sortAlphabeticallyClicked,
  sortChronologicallyClicked,
  tabSelected,
} from "@features/syllabus/syllabusesMenuSlice"
import { TabContext, TabList } from "@mui/lab"
import { Box, Tab, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { SyllabusSearchComponent } from "../descriptor/syllabus/syllabusSearchComponent"
import { RenameSyllabusPopup } from "../popups/renameSyllabusPopup"
import SyllabusesTable from "./syllabusesTable"
import { LoadingComponent } from "@cmp/loaders/loadingComponent"
import { ErrorComponent } from "@cmp/loaders/errorComponent"

export function SyllabusMainPage() {
  const dispatch = useAppDispatch()
  const syllabusesToDisplay = useAppSelector(selectMainPageSyllabuses)
  const tab = useAppSelector(selectSyllabusesMainPageTab)
  const sortOption = useAppSelector(selectSyllabusesSortOption)

  const syllabusLoadingState = useAppSelector(
    (state) => state.syllabusesMenu.loadingState,
  )
  const programLoadingState = useAppSelector(
    (state) => state.program.loadingState,
  )
  const isLoading =
    syllabusLoadingState === "loading" || programLoadingState === "loading"
  const isError =
    syllabusLoadingState === "failed" || programLoadingState === "failed"

  const navigate = useNavigate()

  const createSyllabus = () => {
    dispatch(create())
      .then((createdSyllabus) => createdSyllabus.payload)
      .then((payload) => {
        if (payload) {
          navigate(`/syllabus-edit/${payload.id}`)
        }
      })
  }

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
        paddingX: "24px",
        paddingBottom: "48px",
      }}
    >
      <RenameSyllabusPopup />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          maxWidth: "1240px",
          marginX: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            pt: "80px",
            mb: "16px",
          }}
        >
          <Box
            display="flex"
            sx={{
              justifyContent: "space-between",
              width: "100%",
              mb: "16px",
            }}
          >
            <Typography variant="h4">Syllabuses</Typography>
            {isLoading && (
              <LoadingComponent loadedComponents="syllabuses and programs" />
            )}
            {isError && (
              <ErrorComponent loadedComponents="syllabuses and programs" />
            )}
          </Box>
          <SyllabusSearchComponent />
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              marginTop: "32px",
            }}
          >
            <TabContext value={tab}>
              <TabList
                onChange={(event, value) => {
                  dispatch(tabSelected(value))
                }}
              >
                <Tab label={SyllabusIdentifierEnumeration.All} value="All" />
                <Tab
                  label={SyllabusIdentifierEnumeration.Active}
                  value={SyllabusIdentifierEnumeration.Active}
                />
                <Tab
                  label={SyllabusIdentifierEnumeration.Published}
                  value={SyllabusIdentifierEnumeration.Published}
                />
                <Tab
                  label="Drafts"
                  value={SyllabusIdentifierEnumeration.Draft}
                />
                <Tab
                  label={SyllabusIdentifierEnumeration.Archived}
                  value={SyllabusIdentifierEnumeration.Archived}
                />
              </TabList>
            </TabContext>
            <Box display="flex" gap="8px" alignItems="center">
              <SortItemsButton
                sortOption={sortOption}
                chronologicalClicked={(sortOrder) =>
                  dispatch(sortChronologicallyClicked(sortOrder))
                }
                alphabeticClicked={(sortOrder) =>
                  dispatch(sortAlphabeticallyClicked(sortOrder))
                }
              />
              <AddItemButton onClick={createSyllabus}>
                {"Add Syllabus"}
              </AddItemButton>
            </Box>
          </Box>
          <Box
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              marginTop: "16px",
              paddingBottom: "16px",
            }}
          >
            <SyllabusesTable syllabuses={syllabusesToDisplay} />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
