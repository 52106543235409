import { formatDate } from "@masterschool/foundations"

export function formatDateAsMonthAndDay(date: string | Date): string {
  return formatDate(date, {
    month: "short",
    day: "numeric",
  })
}

export function formatDateAsYearAndLongMonth(date: string | Date): string {
  return formatDate(date, {
    month: "long",
    year: "numeric",
  })
}

export function formatDateAsShortNumeric(date: string | Date): string {
  return formatDate(date, {
    day: "numeric",
    month: "numeric",
    year: "numeric",
  })
}

export function formatDateAsDayLongMonthAndYear(date: string | Date): string {
  return formatDate(date, {
    day: "numeric",
    month: "long",
    year: "numeric",
  })
}

export function subMonths(date: Date, months: number) {
  return new Date(date.setMonth(date.getMonth() - months))
}

export class ComparableDate {
  private date: Date

  constructor(date: Date) {
    this.date = date
  }

  beforeOrEqual(otherDate: Date): boolean {
    if (this.date.getFullYear() !== otherDate.getFullYear()) {
      return this.date.getFullYear() < otherDate.getFullYear()
    }
    if (this.date.getMonth() !== otherDate.getMonth()) {
      return this.date.getMonth() < otherDate.getMonth()
    }
    return this.date.getDate() <= otherDate.getDate()
  }
  afterOrEqual(otherDate: Date): boolean {
    if (this.date.getFullYear() !== otherDate.getFullYear()) {
      return this.date.getFullYear() > otherDate.getFullYear()
    }
    if (this.date.getMonth() !== otherDate.getMonth()) {
      return this.date.getMonth() > otherDate.getMonth()
    }
    return this.date.getDate() >= otherDate.getDate()
  }
}

export type DateRange = {
  start: Date
  end: Date
}

export function defaultDateRange(): DateRange {
  const start = new Date()
  start.setMonth(start.getMonth() - 6)
  const end = new Date()
  end.setMonth(end.getMonth() + 6)
  return { start, end }
}
