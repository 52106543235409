import { Chip, Typography } from "@mui/material"
import { flagForLanguage } from "@utils/language"

export const LanguageChip = (props: { language: string }) => {
  const { language } = props

  const label = (
    <Typography variant="body3">
      <span style={{ fontSize: "13px" }}>{flagForLanguage(language)}</span>
      {" " + language.slice(0, 2).toUpperCase()}
    </Typography>
  )

  return <Chip label={label} size="small" variant="filled" />
}
