import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { ProgramDomain } from "@masterschool/course-builder-api"
import { createAppAsyncThunk } from "@app/createAppAsyncThunk"
import { CampusClient } from "@clients/campusClient"
import { RegistrationDetails } from "../../tab-views/students-tab/studentAcademicStatus.dto"
import PendingDataState from "@utils/pendingDataState"

export type MultiProgramChangeDomainPopup = {
  newDomain: ProgramDomain | undefined
}

export type MultiProgramChangeScopePopup = {
  durationInMonths: number | undefined
}

export type MultiProgramChangeStartDatePopup = {
  startDate: string | undefined
}

export enum MultiProgramsPopupType {
  MultiProgramChangeDomain = "MultiProgramChangeDomain",
  MultiProgramChangeScope = "MultiProgramChangeScope",
  MultiProgramChangeStartDate = "MultiProgramChangeStartDate",
  MultiProgramDelete = "MultiProgramDelete",
}

export type PopupDataMap = {
  [MultiProgramsPopupType.MultiProgramChangeDomain]: MultiProgramChangeDomainPopup
  [MultiProgramsPopupType.MultiProgramChangeScope]: MultiProgramChangeScopePopup
  [MultiProgramsPopupType.MultiProgramChangeStartDate]: MultiProgramChangeStartDatePopup
  [MultiProgramsPopupType.MultiProgramDelete]: undefined
}

export type PresentedPopup =
  | {
      type: MultiProgramsPopupType.MultiProgramChangeDomain
      data: MultiProgramChangeDomainPopup
    }
  | {
      type: MultiProgramsPopupType.MultiProgramChangeScope
      data: MultiProgramChangeScopePopup
    }
  | {
      type: MultiProgramsPopupType.MultiProgramChangeStartDate
      data: MultiProgramChangeStartDatePopup
    }
  | {
      type: MultiProgramsPopupType.MultiProgramDelete
    }

export type ProgramModelType = "legacy" | "next-gen"

export interface ProgramsMenuState {
  selectedProgramIds: string[]
  presentedPopup: PresentedPopup | undefined
  type: ProgramModelType
  studentRegistrations: PendingDataState<Record<string, number>>
}

const getInitialState: () => ProgramsMenuState = () => {
  const type =
    (localStorage.getItem("programsMenuType") as ProgramModelType) ?? "legacy"
  return {
    selectedProgramIds: [],
    presentedPopup: undefined,
    type,
    studentRegistrations: { status: "idle" },
  }
}

const programsMenuSlice = createSlice({
  name: "programsMenu",
  initialState: getInitialState(),
  reducers: {
    selectedProgramsUpdated: (state, action: PayloadAction<string[]>) => {
      state.selectedProgramIds = action.payload
    },
    popupStateChanged: (
      state,
      action: PayloadAction<PresentedPopup | undefined>,
    ) => {
      state.presentedPopup = action.payload
    },
    selectedProgramType: (state, action: PayloadAction<ProgramModelType>) => {
      state.type = action.payload
      state.selectedProgramIds = []
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchStudentRegistrations.pending, (state) => {
      state.studentRegistrations = { status: "pending" }
    })
    builder.addCase(
      fetchStudentRegistrations.fulfilled,
      (state, action: PayloadAction<Record<string, number>>) => {
        state.studentRegistrations = { status: "success", data: action.payload }
      },
    )
    builder.addCase(fetchStudentRegistrations.rejected, (state) => {
      state.studentRegistrations = { status: "error" }
    })
  },
})

export const fetchStudentRegistrations = createAppAsyncThunk(
  "programsMenu/fetchStudentsRegistrations",
  async () => {
    return CampusClient.get<Record<string, RegistrationDetails>>(
      "student-academic-status-dashboard/registrations",
    ).then((res) => {
      const programToCount: Record<string, number> = {}
      for (const student of Object.keys(res)) {
        const programId = res[student].programId
        programToCount[programId] = (programToCount[programId] || 0) + 1
      }
      return programToCount
    })
  },
)

export const {
  selectedProgramsUpdated,
  popupStateChanged,
  selectedProgramType,
} = programsMenuSlice.actions

export default programsMenuSlice.reducer
