import { store } from "@app/store"
import {
  Configuration,
  CourseDto,
  CourseItemValidation,
  CourseDescriptorDto,
  CourseProvisionsApi,
  CoursesApi,
  UpdateCourseRequest,
  CourseType,
  UpdateTackCourseGeneralDetailsRequest,
} from "@masterschool/course-builder-api"
import { selectAccessToken } from "@features/login/loginSelectors"
import { parseStringVersion } from "@utils/versionUtils"

export type ClientResponse<S, F> =
  | {
      kind: "success"
      value: S
    }
  | {
      kind: "failure"
      value: F
    }

export class CourseClient {
  private static courseClient = new CoursesApi(
    new Configuration({
      basePath: `${import.meta.env.VITE_BASE_URL ?? ""}/api`,
    }),
  )

  private static courseProvisionsClient = new CourseProvisionsApi(
    new Configuration({
      basePath: `${import.meta.env.VITE_BASE_URL ?? ""}/api`,
    }),
  )

  private static makeHeaders() {
    return {
      headers: {
        Authorization: `Bearer ${selectAccessToken(store.getState())}`,
      },
    }
  }

  static createTrackCourse(body: {
    displayName: string
    internalName: string
    domains: string[]
  }): Promise<CourseDto> {
    return CourseClient.courseClient
      .courseControllerCreateTrackCourse(
        { type: CourseType.Track, ...body },
        CourseClient.makeHeaders(),
      )
      .then((response) => {
        return response.data
      })
  }

  static createLegacyCourse(): Promise<CourseDto> {
    return CourseClient.courseClient
      .courseControllerCreateLegacyCourse(CourseClient.makeHeaders())
      .then((response) => {
        return response.data
      })
  }

  static listCourseDescriptors(): Promise<CourseDescriptorDto[]> {
    return CourseClient.courseClient
      .courseControllerListDescriptors(CourseClient.makeHeaders())
      .then((response) => {
        return response.data
      })
  }

  static createCourseDraft(
    id: string,
    majorVersion: number,
  ): Promise<CourseDto> {
    return CourseClient.courseProvisionsClient
      .courseProvisionsControllerDraft(
        id,
        majorVersion,
        CourseClient.makeHeaders(),
      )
      .then((response) => {
        return response.data
      })
  }

  static createNewMajorVersion(id: string): Promise<CourseDto> {
    return CourseClient.courseClient
      .courseControllerCreateMajorVersion(id, CourseClient.makeHeaders())
      .then((response) => {
        return response.data
      })
  }

  static duplicateCourse(
    courseId: string,
    version: string,
    type: CourseType = CourseType.Legacy,
  ): Promise<CourseDto> {
    return CourseClient.courseClient
      .courseControllerDuplicate(
        { courseId, version, type },
        CourseClient.makeHeaders(),
      )
      .then((response) => {
        return response.data
      })
  }

  static listCourses(): Promise<CourseDto[]> {
    return CourseClient.courseClient
      .courseControllerList(CourseClient.makeHeaders())
      .then((response) => {
        return response.data
      })
  }

  static getCourse(id: string, majorVersion: number): Promise<CourseDto> {
    return CourseClient.courseClient
      .courseControllerGet(id, majorVersion, CourseClient.makeHeaders())
      .then((response) => {
        return response.data
      })
  }

  static getCourseByVersion(id: string, version: string): Promise<CourseDto> {
    return CourseClient.courseProvisionsClient
      .courseProvisionsControllerGet(id, version, CourseClient.makeHeaders())
      .then((response) => {
        return response.data
      })
  }

  static getLastPublishedVersion(
    id: string,
    majorVersion: number,
  ): Promise<CourseDto | undefined> {
    return CourseClient.courseProvisionsClient
      .courseProvisionsControllerGetPublishedCourseVersion(
        id,
        majorVersion,
        CourseClient.makeHeaders(),
      )
      .then((response) => {
        return response.data.course
      })
  }

  static updateCourse(
    request: UpdateCourseRequest,
    majorVersion: number,
    id: string,
  ): Promise<CourseDto> {
    return CourseClient.courseClient
      .courseControllerSave(
        request,
        majorVersion,
        id,
        CourseClient.makeHeaders(),
      )
      .then((response) => {
        return response.data
      })
  }

  static updateTrackCourseGeneralDetails(
    request: UpdateTackCourseGeneralDetailsRequest,
    id: string,
  ): Promise<CourseDto> {
    return CourseClient.courseClient
      .courseControllerSaveTrackCourseGeneralDetails(
        request,
        id,
        CourseClient.makeHeaders(),
      )
      .then((response) => {
        return response.data
      })
  }

  static removeCourse(id: string): Promise<void> {
    return CourseClient.courseClient
      .courseControllerRemove(id, CourseClient.makeHeaders())
      .then((response) => {
        return response.data
      })
  }

  static discardDraft(id: string, majorVersion: number): Promise<void> {
    return CourseClient.courseClient
      .courseControllerDiscardDraft(
        id,
        majorVersion,
        CourseClient.makeHeaders(),
      )
      .then((response) => {
        return response.data
      })
  }

  static publishCourse(
    id: string,
    majorVersion: number,
  ): Promise<ClientResponse<CourseDto, CourseItemValidation>> {
    return CourseClient.courseProvisionsClient
      .courseProvisionsControllerPublish(
        id,
        majorVersion,
        CourseClient.makeHeaders(),
      )
      .then((response) => {
        return {
          kind: "success" as const,
          value: response.data,
        }
      })
      .catch((e) => {
        const validations = e.response.data.message
          .errors as CourseItemValidation
        if (
          e.response.data.message.message === "Invalid publish course request"
        ) {
          return {
            kind: "failure" as const,
            value: validations,
          }
        }

        throw e
      })
  }

  static archiveCourse(id: string, majorVersion: number): Promise<CourseDto> {
    return CourseClient.courseProvisionsClient
      .courseProvisionsControllerArchive(
        id,
        majorVersion,
        CourseClient.makeHeaders(),
      )
      .then((response) => {
        return response.data
      })
  }

  static listCourseMinorVersions(
    id: string,
    majorVersion: number,
  ): Promise<CourseDescriptorDto[]> {
    return CourseClient.courseProvisionsClient
      .courseProvisionsControllerListMinors(
        id,
        majorVersion,
        CourseClient.makeHeaders(),
      )
      .then((response) => {
        return response.data
      })
  }

  static deleteCourse(id: string): Promise<void> {
    return CourseClient.courseClient
      .courseControllerRemove(id, CourseClient.makeHeaders())
      .then((response) => {
        return response.data
      })
  }

  static getValidations(
    id: string,
    majorVersion: number,
  ): Promise<CourseItemValidation> {
    return CourseClient.courseProvisionsClient
      .courseProvisionsControllerGetPublishValidations(
        id,
        majorVersion,
        CourseClient.makeHeaders(),
      )
      .then((response) => {
        return response.data
      })
  }

  static revertToVersion(id: string, version: string): Promise<CourseDto> {
    return CourseClient.getCourseByVersion(id, version).then((course) => {
      if (!course) {
        throw new Error("Course not found")
      }
      const { major } = parseStringVersion(course.version)

      return CourseClient.updateCourse(
        {
          ...course,
          status: undefined,
        },
        major,
        id,
      )
    })
  }

  static duplicateLegacyCourseToTrack(
    id: string,
    version: string,
  ): Promise<CourseDto> {
    return CourseClient.courseClient
      .courseControllerConvertCourseLegacyToTrack(
        id,
        version,
        CourseClient.makeHeaders(),
      )
      .then((response) => {
        return response.data
      })
  }
}
